@import 'select2/dist/css/select2.css';
@font-face {
  font-family: "Proxima-Regular";
  src: url("../../../fonts/ProximaNova-Bold.eot");
  src: url("../../../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../../../fonts/ProximaNova-Bold.woff") format("woff"), url("../../../fonts/ProximaNova-Bold.ttf") format("truetype"), url("../../../fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima-Semi";
  src: url("../../../fonts/ProximaNova-Semibold.eot");
  src: url("../../../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/ProximaNova-Semibold.woff2") format("woff2"), url("../../../fonts/ProximaNova-Semibold.woff") format("woff"), url("../../../fonts/ProximaNova-Semibold.ttf") format("truetype"), url("../../../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima-Regular";
  src: url("../../../fonts/ProximaNova-Regular.eot");
  src: url("../../../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../../../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../../../fonts/ProximaNova-Regular.woff") format("woff"), url("../../../fonts/ProximaNova-Regular.ttf") format("truetype"), url("../../../fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --black: #000000;
  --white: #FFFFFF;
  --black-light: #1B1B1B;
  --red: #F30337;
  --gray: #A6A8AA;
  --gray-light: #EDEDED;
  --gray-lighter: #F4F5F6;
  --gray-dark: #6F6F6F;
  --red-error: #ff0000;
  --font-default: "Proxima-Regular";
  --font-secondary: "Proxima-Semi";
  --spacing-sides: max(10px, 5.9895833333vw);
  --container-gutter: max(20px, 1.5625vw);
}
@media (max-width: 580px) {
  :root {
    --spacing-sides: 10px;
  }
}

.title__H1, .page-404__title, .homepage .product-block__header--title, h1 {
  font-family: var(--font-default), sans-serif;
  font-size: max(32px, 3.3854166667vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(38.98px, 4.1234375vw);
}
.title__H2, .banner-block__content--title, h2 {
  font-family: var(--font-default), sans-serif;
  font-size: max(24px, 2.6041666667vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(29.23px, 3.171875vw);
}
.title__H3, .contact-container .contact-title, .account-content fieldset legend, .event__heading, .product__heading, h3 {
  font-family: var(--font-default), sans-serif;
  font-size: max(21px, 1.5625vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(25.58px, 1.903125vw);
}

.text__big-bold, .page-404__subtitle, .page-404__content, .page-404__prompt, .contact-container .contact-box-container .contact-box-title, .account-titles, .account-block-title, .account-title, .document-header nav > ul li a span {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 1.1458333333vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(21.92px, 1.3958333333vw);
}
.text__big-semi, .select2-container--default .select2-results__option, .document-footer__content, .document-footer__title {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(21.92px, 1.1416666667vw);
}
.text__paragraph, body {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.0416666667vw);
  font-weight: normal;
  font-style: normal;
  line-height: max(21px, 1.7708333333vw);
}
.text__small-titles-semi, .product-profile__content--title, .search-bar__content .filters-industries--title, .account-texts, .account-text, .cta__default, .event__date, .product__cta, .product__tags, .info__block--content {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 600;
  font-style: normal;
  line-height: max(19.49px, 1.0151041667vw);
}
.text__normal, .search-bar__content .form input::placeholder, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__advantages ul li, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__advantages p, .input__default, input:not([type=radio]):not([type=submit]), .select2-container--default .select2-selection--single, .banner-block__content--list li, .product__content {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: normal;
  font-style: normal;
  line-height: max(21px, 1.25vw);
}
.text--breadcrumbs, .js-select2-custom-dropdown .select2-results__option, .js-select2-custom.select2-container--default .select2-selection--single, .text__small, .product-profile__content--tags, .radio-input:not(#guest):not(#register) + span, input[type=radio]:not(#guest):not(#register) + span, .banner-block__content--title-pre {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.7291666667vw);
  font-weight: normal;
  font-style: normal;
  line-height: max(15.83px, 0.8880208333vw);
}
.text__view-all, .cta__view-all {
  font-family: var(--font-secondary), sans-serif;
  font-size: max(14px, 1.0416666667vw);
  font-weight: 600;
  font-style: normal;
  line-height: max(22px, 1.26875vw);
}

.details__copyright {
  font-family: var(--font-default), sans-serif;
  font-size: max(11px, 0.5729166667vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
}
.details__copyright--medium {
  font-weight: 600;
}
.details__copyright--bold {
  font-weight: 700;
}

*,
*::before,
*::after {
  position: relative;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

*:visited,
*:focus,
*:active {
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

::-moz-selection {
  color: var(--white);
  background: var(--black-light);
}

::selection {
  color: var(--white);
  background: var(--black-light);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html,
body {
  height: auto;
  width: 100%;
}

body {
  background-color: var(--white);
  color: var(--black-light);
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
}

h1 {
  margin: unset;
}

h2 {
  margin: unset;
}

h3 {
  margin: unset;
}

h4 {
  margin: unset;
}

h5 {
  margin: unset;
}

h6 {
  margin: unset;
}

p {
  margin: unset;
  margin-bottom: 15px;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
a:visited, a:hover, a:active, a:focus {
  outline: none;
  text-decoration: none;
}
a:hover {
  color: inherit;
}

b,
strong {
  font-weight: bold;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}

img,
picture {
  border: none;
  height: auto;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
}

svg {
  display: inline-block;
}

.js-show {
  opacity: 1 !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.default_top_slider--height, .default__slider__item--image img, .default__slider__item--image, .default__slider__wrap {
  height: calc(100vh - var(--header-height));
}

.default__slider__wrap {
  display: flex;
}
.default__slider__slider {
  width: 100%;
}
.default__slider__item--image img {
  object-fit: cover;
}
.default__slider__item__display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.default__slider__item__display--title, .default__slider__item__display--content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
  max-width: calc(575px + var(--spacing-sides) * 2);
  text-align: center;
}
.default__slider__item__display--title {
  color: #ff0000;
  margin-bottom: max(30px, 1.9791666667vw);
}
.default__slider__item__display--content {
  color: var(--white);
}
.default__slider__item__display--icon {
  width: 100%;
  max-width: max(273px, 27.8645833333vw);
  margin-bottom: max(30px, 1.9791666667vw);
}
.default__slider__item__display--icon-logo {
  width: 100%;
  max-width: max(178px, 14.4791666667vw);
  margin-bottom: 70px;
  margin-top: -170px;
}
.default__slider__pagination {
  z-index: 1;
  position: absolute;
  width: unset !important;
  left: unset !important;
  bottom: unset !important;
  right: max(15px, 6.5104166667vw);
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .default__slider__pagination {
    display: none;
  }
}
.default__slider__pagination .swiper-pagination-bullet {
  display: inline-flex;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: unset;
  opacity: 0.5;
  cursor: pointer;
  border: 1px solid var(--black-light);
}
.default__slider__pagination .swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 15px;
}
.default__slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background-color: var(--black-light);
}
.default__slider__abstract {
  position: absolute;
  z-index: 2222;
  width: max(52px, 4.1666666667vw);
  bottom: 7px;
  left: 0;
  right: 0;
  margin: auto;
}

.tiles-marketing__slider,
.tiles-magazines__slider,
.tiles-newsletters__slider,
.tiles-bundles__slider,
.tiles__slider {
  width: 100%;
}
.tiles-marketing__sliderwrap,
.tiles-magazines__sliderwrap,
.tiles-newsletters__sliderwrap,
.tiles-bundles__sliderwrap,
.tiles__sliderwrap {
  display: flex;
}
.tiles-marketing__item:not(:last-child),
.tiles-magazines__item:not(:last-child),
.tiles-newsletters__item:not(:last-child),
.tiles-bundles__item:not(:last-child),
.tiles__item:not(:last-child) {
  margin-right: max(10px, 1.5625vw);
}
.tiles-marketing__pagination,
.tiles-magazines__pagination,
.tiles-newsletters__pagination,
.tiles-bundles__pagination,
.tiles__pagination {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: max(41px, 4.2708333333vw);
}
.tiles-marketing__pagination .swiper-pagination-bullet,
.tiles-magazines__pagination .swiper-pagination-bullet,
.tiles-newsletters__pagination .swiper-pagination-bullet,
.tiles-bundles__pagination .swiper-pagination-bullet,
.tiles__pagination .swiper-pagination-bullet {
  display: inline-flex;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: unset;
  opacity: 0.5;
  cursor: pointer;
  border: 1px solid var(--black-light);
}
@media screen and (max-width: 767px) {
  .tiles-marketing__pagination .swiper-pagination-bullet,
  .tiles-magazines__pagination .swiper-pagination-bullet,
  .tiles-newsletters__pagination .swiper-pagination-bullet,
  .tiles-bundles__pagination .swiper-pagination-bullet,
  .tiles__pagination .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    border-color: #D9D9D9;
    background-color: #D9D9D9;
  }
}
.tiles-marketing__pagination .swiper-pagination-bullet:not(:last-child),
.tiles-magazines__pagination .swiper-pagination-bullet:not(:last-child),
.tiles-newsletters__pagination .swiper-pagination-bullet:not(:last-child),
.tiles-bundles__pagination .swiper-pagination-bullet:not(:last-child),
.tiles__pagination .swiper-pagination-bullet:not(:last-child) {
  margin-right: 12px;
}
@media screen and (max-width: 767px) {
  .tiles-marketing__pagination .swiper-pagination-bullet:not(:last-child),
  .tiles-magazines__pagination .swiper-pagination-bullet:not(:last-child),
  .tiles-newsletters__pagination .swiper-pagination-bullet:not(:last-child),
  .tiles-bundles__pagination .swiper-pagination-bullet:not(:last-child),
  .tiles__pagination .swiper-pagination-bullet:not(:last-child) {
    margin-right: 6px;
  }
}
.tiles-marketing__pagination .swiper-pagination-bullet-active,
.tiles-magazines__pagination .swiper-pagination-bullet-active,
.tiles-newsletters__pagination .swiper-pagination-bullet-active,
.tiles-bundles__pagination .swiper-pagination-bullet-active,
.tiles__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background-color: var(--black-light);
}
@media screen and (max-width: 767px) {
  .tiles-marketing__pagination .swiper-pagination-bullet-active,
  .tiles-magazines__pagination .swiper-pagination-bullet-active,
  .tiles-newsletters__pagination .swiper-pagination-bullet-active,
  .tiles-bundles__pagination .swiper-pagination-bullet-active,
  .tiles__pagination .swiper-pagination-bullet-active {
    background-color: var(--gray-dark);
  }
}

@media screen and (max-width: 580px) {
  .tiles-magazines__pagination,
  .tiles-newsletters__pagination,
  .tiles-bundles__pagination {
    display: none;
  }
}

.tile-navigation {
  display: flex;
  align-items: center;
  margin: auto;
  z-index: 1;
  padding-top: max(41px, 4.2708333333vw);
}
.tile-navigation .swiper-button-disabled {
  opacity: 0.5;
}
@media screen and (min-width: 581px) {
  .tile-navigation {
    display: none;
  }
}
.tile-navigation > * {
  cursor: pointer;
  pointer-events: all;
  padding: 15px 20px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 46px;
  height: 46px;
}
.tile-navigation > *:not(:last-child) {
  margin-right: 24px;
}
.tile-navigation > *:first-child {
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M25.756 28.696c.159-.187.248-.44.248-.704 0-.264-.09-.517-.248-.704L22.09 23l3.659-4.279c.16-.19.25-.446.25-.713 0-.267-.09-.523-.25-.713a.81.81 0 0 0-.61-.292.81.81 0 0 0-.61.292l-4.276 4.992c-.162.19-.253.449-.253.718 0 .269.09.527.253.718l4.276 5.001c.168.183.39.282.618.276a.814.814 0 0 0 .61-.304Z' fill='%231B1B1B'/%3E%3C/g%3E%3Crect x='.5' y='.5' width='45' height='45' rx='3.5' stroke='%231B1B1B'/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(90 4.5 21.5)' d='M0 0h12v6H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
.tile-navigation > *:last-child {
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M20.244 17.304c-.159.187-.248.44-.248.704 0 .264.09.517.248.704L23.91 23l-3.659 4.279c-.16.19-.25.446-.25.713 0 .267.09.523.25.713a.81.81 0 0 0 .61.292.81.81 0 0 0 .61-.292l4.276-4.992c.162-.19.253-.449.253-.718 0-.269-.09-.527-.253-.718l-4.276-5.001a.811.811 0 0 0-.618-.276.814.814 0 0 0-.61.304Z' fill='%231B1B1B'/%3E%3C/g%3E%3Crect x='45.5' y='45.5' width='45' height='45' rx='3.5' transform='rotate(-180 45.5 45.5)' stroke='%231B1B1B'/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(-90 24.5 4.5)' d='M0 0h12v6H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.swiper-pagination-lock {
  display: none;
}

.js-fade__in-delayed,
.js-fade__in {
  opacity: 0;
}

.js-fade__children-delayed > *,
.js-fade__children > * {
  opacity: 0;
}

.section__default {
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}

.breadcrumbs-wrap {
  padding-top: max(30px, 2.6041666667vw);
  padding-left: max(15px, 3.125vw);
  padding-right: max(15px, 3.125vw);
  padding-bottom: max(10px, 1.5625vw);
}
@media screen and (max-width: 767px) {
  .breadcrumbs-wrap {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
  border: none;
  background-color: transparent;
}
.breadcrumb li {
  padding: 0;
}
.breadcrumb li a {
  display: inline-block;
  color: var(--gray);
}
.breadcrumb li a::after {
  display: inline-block;
  content: "";
  background-image: url("../../../assets/icons/arrow-right-gray.svg");
  width: 8px;
  height: 8px;
  background-repeat: no-repeat;
  margin: 0 7px 0 10px;
}
.breadcrumb li:last-child a {
  color: var(--black);
}
.breadcrumb li:last-child a::after {
  display: none;
}
.breadcrumb li + li:before {
  display: none;
}

.info__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: max(220px, 20.5729166667vw);
  max-width: max(220px, 20.5729166667vw);
}
.info__block:not(:last-child) {
  margin-right: max(10px, 1.5625vw);
}
.info__block--image {
  margin-bottom: 17px;
}
.info__block--content {
  text-align: center;
  max-width: 213px;
}

.product {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: max(340px, 20.5729166667vw);
  max-width: max(340px, 20.5729166667vw);
}
.product__image {
  margin-bottom: 17px;
}
.product__heading {
  display: inline-flex;
}
.product__heading:hover, .product__heading:focus, .product__heading:active {
  color: var(--black-light);
}
.product__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.product__tags > * {
  padding: 6px;
  margin-right: 10px;
}
.product__tags > *:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--black-light);
}
.product__tags > * a:hover, .product__tags > * a:focus, .product__tags > * a:visited {
  color: var(--black-light);
}
.product__content {
  margin-bottom: 10px;
  min-height: max(63px, 3.75vw);
  height: 3em;
  overflow: hidden;
}
.event {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.event__image {
  display: inline-flex;
}
.event__heading:hover, .event__heading:focus, .event__heading:active {
  color: var(--black-light);
}
.event__date {
  margin: 15px 0 25px;
}
.event__date:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--black-light);
}

.banner-block__wrap {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, #d7d7d7 0%, #b9a1a1 99.2%);
  max-width: 65.1041666667vw;
  margin: auto;
  border-radius: 8px;
  margin-bottom: 6.3020833333vw;
}
@media screen and (max-width: 991px) {
  .banner-block__wrap {
    flex-direction: column;
    align-items: center;
    max-width: 360px;
  }
}
@media screen and (max-width: 580px) {
  .banner-block__wrap {
    max-width: 100%;
    border-radius: unset;
    margin-bottom: unset;
  }
}
.banner-block__content {
  padding-top: 2.7083333333vw;
  padding-bottom: 2.65625vw;
  padding-left: max(33px, 4.6875vw);
}
@media screen and (max-width: 991px) {
  .banner-block__content {
    order: 2;
    padding: unset;
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 420px) {
  .banner-block__content {
    padding-left: 33px;
    padding-right: 33px;
    align-self: flex-start;
  }
}
.banner-block__content--title {
  margin-bottom: 12px;
}
.banner-block__content--list {
  margin-bottom: 28px;
}
.banner-block__content--list li {
  margin-left: 22px;
  margin-bottom: 4px;
}
.banner-block__content--list li:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.19369 7.62838L4.41361 7.41436L10.1486 1.88277C10.3366 1.66708 10.6037 1.53124 10.893 1.50412C11.0874 1.48618 11.2828 1.52716 11.4524 1.62147C11.622 1.71578 11.7575 1.85879 11.8403 2.03093C11.9443 2.17882 12 2.35385 12 2.53306C12 2.71227 11.9443 2.88729 11.8403 3.03518C11.7819 3.13381 11.7076 3.22273 11.6204 3.29859L5.07339 9.57102C4.97827 9.70348 4.8517 9.81164 4.70444 9.88632C4.55719 9.961 4.39358 10 4.22752 10C4.06147 10 3.89786 9.961 3.7506 9.88632C3.60334 9.81164 3.47678 9.70348 3.38166 9.57102C2.36662 8.5997 1.35158 7.62838 0.353452 6.64059C0.179679 6.49982 0.0611967 6.30497 0.0180406 6.08898C-0.0251154 5.873 0.00970355 5.64913 0.116609 5.45525C0.18771 5.30384 0.297608 5.17275 0.435756 5.07456C0.573905 4.97637 0.735666 4.91439 0.905517 4.89456C1.07537 4.87472 1.24761 4.8977 1.4057 4.96129C1.5638 5.02488 1.70245 5.12695 1.80834 5.2577L3.99068 7.43082C4.06706 7.48761 4.13533 7.55405 4.19369 7.62838V7.62838Z' fill='%231B1B1B'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-flex;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: -22px;
  transform: translateY(50%);
}
@media screen and (max-width: 580px) {
  .banner-block__content--cta .cta__default {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--black-light) !important;
    color: var(--white) !important;
    border-color: var(--black-light) !important;
  }
  .banner-block__content--cta .cta__default:hover {
    background-color: var(--white) !important;
    color: var(--black-light) !important;
    border-color: var(--white) !important;
  }
}
.banner-block__image {
  max-width: max(360px, 35.8854166667vw);
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .banner-block__image {
    order: 1;
    margin-top: 70px;
    margin-bottom: 60px;
  }
}

.icon--marketing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  font-size: 35px;
}
.icon--marketing:before {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  border-radius: 100px;
}

.industry--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 34px;
  height: 34px;
  margin-right: 13px;
}
@media screen and (max-width: 580px) {
  .industry--icon {
    margin-right: unset;
    margin-bottom: 8px;
  }
}
.industry--icon:before {
  content: "";
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--gray-light);
  border-radius: 34px;
}
.industry--icon svg path {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.modal .modal-title {
  position: static;
}

.kTabs__wrap {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 15px);
  background: var(--white);
  display: flex;
  z-index: 2;
  min-width: 956px;
  box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 1024px) {
  .kTabs__wrap {
    min-width: 100%;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    left: 0;
    transform: unset;
    height: var(--screen-height);
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 580px) {
  .kTabs__wrap {
    padding: unset;
  }
}
.kTabs__wrap .close--filters {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  cursor: pointer;
  z-index: 2;
}
.kTabs__wrap .close--filters:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23A6A8AA' d='M12.786.37.586 12.571a.524.524 0 1 0 .74.741l12.2-12.2a.524.524 0 0 0-.74-.742Z'/%3E%3Cpath fill='%23A6A8AA' d='M13.527 12.571 1.326.371a.524.524 0 1 0-.74.74l12.2 12.201a.524.524 0 1 0 .74-.74Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 14px;
  width: 14px;
  height: 14px;
  display: flex;
}
@media screen and (max-width: 580px) {
  .kTabs__wrap .close--filters:before {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%231B1B1B' d='M16.439.476.752 16.163a.674.674 0 0 0 .953.953L17.39 1.429a.673.673 0 0 0-.952-.953Z' /%3E%3Cpath fill='%231B1B1B' d='M17.391 16.163 1.705.476a.673.673 0 1 0-.953.953L16.44 17.115a.673.673 0 0 0 .952-.952Z' /%3E%3C/svg%3E");
  }
}
.kTabs__wrap.non-visible {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.kTabs__side-a {
  margin-left: 37px;
  margin-right: 28px;
  margin-top: 49px;
  margin-bottom: 42px;
  width: 100%;
  max-width: 305px;
}
@media screen and (max-width: 1024px) {
  .kTabs__side-a {
    margin: unset;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    overflow-y: auto;
    direction: rtl;
  }
}
@media screen and (max-width: 580px) {
  .kTabs__side-a {
    max-width: 100%;
    padding: unset;
  }
}
.kTabs__side-b {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 49px;
  margin-bottom: 42px;
  padding-top: 12px;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .kTabs__side-b {
    margin: unset;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    overflow-y: auto;
  }
}
@media screen and (max-width: 580px) {
  .kTabs__side-b {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: unset;
    position: absolute;
    z-index: 3;
    left: 100%;
    background: var(--white);
  }
  .kTabs__side-b.show-second-level {
    left: 0;
  }
}
.kTabs__placeholder {
  display: none;
}
.kTabs__trigger--wrap {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .kTabs__trigger--wrap {
    direction: ltr;
  }
}
@media screen and (max-width: 580px) {
  .kTabs__trigger--wrap {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: unset;
  }
}
.kTabs__trigger--item {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.kTabs__trigger--item:not(:last-child) {
  margin-bottom: 11px;
}
@media screen and (max-width: 580px) {
  .kTabs__trigger--item:not(:last-child) {
    margin-bottom: unset;
  }
}
@media screen and (max-width: 580px) {
  .kTabs__trigger--item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-height: 180px;
    border-bottom: 1px solid var(--gray-light);
  }
  .kTabs__trigger--item:nth-child(odd) {
    border-right: 1px solid var(--gray-light);
  }
}
.kTabs__trigger--item .industry--name {
  flex: auto;
  max-width: 215px;
  margin-right: 34px;
  color: var(--black);
}
@media screen and (max-width: 580px) {
  .kTabs__trigger--item .industry--name {
    margin-right: unset;
    max-width: 125px;
    text-align: center;
    flex: unset;
  }
}
.kTabs__trigger--item .industry--name:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin-right: auto;
  width: 0%;
  height: 1px;
  background-color: var(--black-light);
  opacity: 0;
}
@media screen and (min-width: 581px) {
  .kTabs__trigger--item:after {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.437012 1.73743C0.437012 2.0054 0.544499 2.2618 0.734893 2.45063L5.57638 7.23588L0.736018 12.02L0.735588 12.0204C0.641652 12.1126 0.56691 12.2225 0.51582 12.3437C0.464648 12.4651 0.43826 12.5954 0.43826 12.7271C0.43826 12.8588 0.464648 12.9891 0.51582 13.1105C0.566278 13.2302 0.639807 13.3388 0.732111 13.4303C0.822638 13.5255 0.931887 13.601 1.05293 13.6523C1.17527 13.7041 1.30708 13.7301 1.43995 13.7286C1.70807 13.7277 1.96552 13.6217 2.15575 13.4331L7.71897 7.86211C7.90491 7.67864 8.01256 7.43064 8.01845 7.16965L8.0188 7.15403L8.01736 7.13848C7.99545 6.90267 7.88823 6.68324 7.71632 6.52023L2.1562 1.02468L2.15578 1.02426C2.06249 0.931415 1.95173 0.85788 1.82995 0.80773C1.70798 0.757505 1.57726 0.731689 1.44532 0.731689C1.31338 0.731689 1.18266 0.757505 1.06069 0.80773C0.938918 0.857875 0.828169 0.931401 0.734894 1.02423C0.544499 1.21307 0.437012 1.46947 0.437012 1.73743ZM0.707845 1.73743C0.707845 1.74964 0.708151 1.76181 0.708758 1.77394L0.707845 1.73743ZM0.708758 1.77394C0.708151 1.76181 0.707845 1.74964 0.707845 1.73743L0.708758 1.77394Z' fill='%231B1B1B'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    max-width: 9px;
    height: 14px;
    display: inline-flex;
    opacity: 0.5;
  }
  .kTabs__trigger--item:hover .industry--icon svg path, .kTabs__trigger--item--current .industry--icon svg path {
    fill: var(--black);
  }
  .kTabs__trigger--item:hover .industry--icon i, .kTabs__trigger--item--current .industry--icon i {
    font-weight: 300;
  }
  .kTabs__trigger--item:hover .industry--name, .kTabs__trigger--item--current .industry--name {
    font-family: var(--font-default), sans-serif;
    font-size: max(16px, 0.8333333333vw);
    font-weight: 600;
    font-style: normal;
    line-height: 1;
    color: var(--black-light);
  }
  .kTabs__trigger--item:hover .industry--name:before, .kTabs__trigger--item--current .industry--name:before {
    opacity: 1;
    width: 100%;
  }
  .kTabs__trigger--item:hover:after, .kTabs__trigger--item--current:after {
    opacity: 1;
  }
  .kTabs__trigger--item--current {
    pointer-events: none;
  }
}
.kTabs__content--item:not(:first-child) {
  display: none;
}

@media screen and (max-width: 1024px) {
  body.kTabs-visible {
    overflow-y: hidden;
  }
}

.second-level--handle {
  position: absolute;
  top: 25px;
  left: 40px;
  z-index: 4;
  display: none;
}
.second-level--handle.active {
  display: block;
}
@media screen and (min-width: 581px) {
  .second-level--handle {
    display: none;
  }
}

.close-filters {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.close-filters.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.select2-container {
  display: block;
}
.select2-container--default .select2-selection--single {
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  padding: 20px max(20px, 1.4583333333vw);
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: max(21px, 1.25vw);
  border-color: var(--black-light);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: unset;
  background-color: transparent;
  color: var(--black);
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 0;
  right: 20px;
  width: 11px;
  height: 100%;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: unset;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m10.4.4-5 5.9-5-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  border: unset;
  width: 11px;
  height: 11px;
  transform: rotate(-90deg) translatex(5px) scale(0.9);
  opacity: 0.5;
}
.select2-container--default .select2-selection__rendered {
  color: var(--gray-dark) !important;
  padding: 0 !important;
  line-height: inherit !important;
}
.select2-container--default .select2-results__option {
  margin-left: 0;
  margin-right: 0;
  padding: 15px 18px;
}
.select2-container--default .select2-results__option:hover, .select2-container--default .select2-results__option--selected {
  background-color: var(--gray) !important;
  color: var(--black-light) !important;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  padding: 15px 18px;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--gray) !important;
  color: var(--black-light) !important;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  padding: 15px 18px;
}
.select2-container--default.select2-container--open .select2-selection--single {
  border-radius: 0;
  background-color: var(--white) !important;
  border: 1px solid var(--gray-light);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m.4 6.2 5-5.9 5 6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  border: unset;
  width: 11px;
  height: 11px;
  transform: rotate(-180deg) translatey(7px) scale(0.9);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__rendered {
  color: var(--gray-dark) !important;
}
.select2-container--default.select2-container--open .select2-dropdown {
  background-color: var(--gray-light);
}
.select2-container--default.select2-container--open .select2-dropdown--below {
  border-color: var(--gray-light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
}
.select2-container--default.select2-container--open .select2-dropdown--above {
  border-color: var(--gray-light);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}

.js-select2-custom.select2-container {
  width: 150px !important;
}
.js-select2-custom.select2-container--default .select2-selection--single {
  padding: 0;
  border: unset;
}
.js-select2-custom-dropdown {
  width: 200px;
  border: none;
  box-shadow: 10px 10px 15px -10px #000;
  margin-left: -20px;
}
.js-select2-custom-dropdown .select2-results__option {
  margin: 10px 0;
  padding: 0;
  margin-left: 28px !important;
}
.js-select2-custom-dropdown .select2-results__option:hover, .js-select2-custom-dropdown .select2-results__option--selected {
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--black) !important;
  margin-left: unset;
  margin-right: unset;
  border-radius: unset;
  padding: unset;
}
.js-select2-custom-dropdown .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--black) !important;
  margin-left: unset;
  margin-right: unset;
  border-radius: unset;
  padding: unset;
}

.select2-results__options {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: scroll;
}
.select2-results__options::-webkit-scrollbar {
  width: 6px;
  position: absolute;
}
.select2-results__options::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  -moz-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
}
.select2-results__options::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--red);
  -moz-box-shadow: inset 0 0 6px var(--red);
  box-shadow: inset 0 0 6px var(--red);
  background-color: var(--red);
  border-radius: 4px;
}

.input__default, input:not([type=radio]):not([type=submit]) {
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  padding: 20px max(20px, 1.4583333333vw);
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: var(--gray);
  border-color: var(--black-light);
}
.input__default::placeholder, input:not([type=radio]):not([type=submit])::placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray);
  opacity: 1;
}
.input__default:-ms-input-placeholder, input:-ms-input-placeholder:not([type=radio]):not([type=submit]) {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray);
}
.input__default::-ms-input-placeholder, input:not([type=radio]):not([type=submit])::-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray);
}
.input__default:hover, input:hover:not([type=radio]):not([type=submit]), .input__default:active, input:active:not([type=radio]):not([type=submit]) {
  color: var(--gray-dark);
  border-color: var(--gray-dark);
}
.input__default:hover::placeholder, input:hover:not([type=radio]):not([type=submit])::placeholder, .input__default:active::placeholder, input:active:not([type=radio]):not([type=submit])::placeholder {
  color: var(--gray-dark);
  opacity: 1;
}
.input__default:hover:-ms-input-placeholder, input:hover:-ms-input-placeholder:not([type=radio]):not([type=submit]), .input__default:active:-ms-input-placeholder, input:active:-ms-input-placeholder:not([type=radio]):not([type=submit]) {
  color: var(--gray-dark);
}
.input__default:hover::-ms-input-placeholder, input:hover:not([type=radio]):not([type=submit])::-ms-input-placeholder, .input__default:active::-ms-input-placeholder, input:active:not([type=radio]):not([type=submit])::-ms-input-placeholder {
  color: var(--gray-dark);
}
.input__default:focus::placeholder, input:focus:not([type=radio]):not([type=submit])::placeholder {
  color: transparent;
  opacity: 1;
}
.input__default:focus:-ms-input-placeholder, input:focus:-ms-input-placeholder:not([type=radio]):not([type=submit]) {
  color: transparent;
}
.input__default:focus::-ms-input-placeholder, input:focus:not([type=radio]):not([type=submit])::-ms-input-placeholder {
  color: transparent;
}
.input__default.wpcf7-not-valid, input.wpcf7-not-valid:not([type=radio]):not([type=submit]), .input__default.has-error, input.has-error:not([type=radio]):not([type=submit]) {
  color: var(--red-error);
  border-color: var(--red-error);
}
.input__default.wpcf7-not-valid::placeholder, input.wpcf7-not-valid:not([type=radio]):not([type=submit])::placeholder, .input__default.has-error::placeholder, input.has-error:not([type=radio]):not([type=submit])::placeholder {
  color: var(--red-error);
  opacity: 1;
}
.input__default.wpcf7-not-valid:-ms-input-placeholder, input.wpcf7-not-valid:-ms-input-placeholder:not([type=radio]):not([type=submit]), .input__default.has-error:-ms-input-placeholder, input.has-error:-ms-input-placeholder:not([type=radio]):not([type=submit]) {
  color: var(--red-error);
}
.input__default.wpcf7-not-valid::-ms-input-placeholder, input.wpcf7-not-valid:not([type=radio]):not([type=submit])::-ms-input-placeholder, .input__default.has-error::-ms-input-placeholder, input.has-error:not([type=radio]):not([type=submit])::-ms-input-placeholder {
  color: var(--red-error);
}
.input__default--bottom-line {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
}
.input__default--bottom-line:hover {
  border-color: inherit;
}
.input__default::-webkit-input-placeholder, input:not([type=radio]):not([type=submit])::-webkit-input-placeholder {
  font-style: italic;
}
.input__default.checkbox-input, input.input__default[type=checkbox], input.checkbox-input:not([type=radio]):not([type=submit]), input[type=checkbox]:not([type=radio]):not([type=submit]) {
  display: inline-flex;
}
.input__default.checkbox-input, input.input__default[type=checkbox], input.checkbox-input:not([type=radio]):not([type=submit]), input[type=checkbox]:not([type=radio]):not([type=submit]), .input__default.checkbox-wrap, input.checkbox-wrap:not([type=radio]):not([type=submit]) {
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0 5px -3px 0;
  width: 17px;
  height: 17px !important;
  border: 1px solid var(--black-light);
  position: relative;
  padding: 4px;
  border-radius: 0;
}
.input__default.checkbox-input:before, input.input__default[type=checkbox]:before, input.checkbox-input:not([type=radio]):not([type=submit]):before, input[type=checkbox]:not([type=radio]):not([type=submit]):before, .input__default.checkbox-wrap:before, input.checkbox-wrap:not([type=radio]):not([type=submit]):before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: scale(0) translate(-50%, -50%);
  background-color: var(--white);
  transform-origin: bottom left;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.input__default.checkbox-input:checked, input.input__default[type=checkbox]:checked, input.checkbox-input:checked:not([type=radio]):not([type=submit]), input[type=checkbox]:checked:not([type=radio]):not([type=submit]), .input__default.checkbox-wrap:checked, input.checkbox-wrap:checked:not([type=radio]):not([type=submit]) {
  background-color: var(--red);
  border-color: var(--red);
}
.input__default.checkbox-input:checked:before, input.input__default[type=checkbox]:checked:before, input.checkbox-input:checked:not([type=radio]):not([type=submit]):before, input[type=checkbox]:checked:not([type=radio]):not([type=submit]):before, .input__default.checkbox-wrap:checked:before, input.checkbox-wrap:checked:not([type=radio]):not([type=submit]):before {
  transform: scale(1) translate(-50%, -50%);
}
.input__default.checkbox-input.error-checkbox, input.input__default.error-checkbox[type=checkbox], input.checkbox-input.error-checkbox:not([type=radio]):not([type=submit]), input.error-checkbox[type=checkbox]:not([type=radio]):not([type=submit]), .input__default.checkbox-wrap.error-checkbox, input.checkbox-wrap.error-checkbox:not([type=radio]):not([type=submit]) {
  border-color: var(--red);
}
.input__default.checkbox-input.error-checkbox:checked, input.input__default.error-checkbox[type=checkbox]:checked, input.checkbox-input.error-checkbox:checked:not([type=radio]):not([type=submit]), input.error-checkbox[type=checkbox]:checked:not([type=radio]):not([type=submit]), .input__default.checkbox-wrap.error-checkbox:checked, input.checkbox-wrap.error-checkbox:checked:not([type=radio]):not([type=submit]) {
  border-color: var(--gray-dark);
}
.input__default.checkbox-input:disabled, input.input__default[type=checkbox]:disabled, input.checkbox-input:disabled:not([type=radio]):not([type=submit]), input[type=checkbox]:disabled:not([type=radio]):not([type=submit]), .input__default.checkbox-wrap:disabled, input.checkbox-wrap:disabled:not([type=radio]):not([type=submit]) {
  border-color: var(--gray-dark);
  cursor: not-allowed;
}

/* RADIO STRACTURE */
/* RADIO */
.radio-input:not(#guest):not(#register), input[type=radio]:not(#guest):not(#register) {
  position: relative !important;
  display: inline-grid;
  place-content: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid var(--black-light);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  margin-right: 9px;
  border-radius: 50%;
}
.radio-input:not(#guest):not(#register):before, input[type=radio]:not(#guest):not(#register):before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--red);
}
.radio-input:not(#guest):not(#register):checked:before, input[type=radio]:not(#guest):not(#register):checked:before {
  transform: scale(1);
}
.checkbox-label {
  display: inline-flex;
  align-content: flex-start;
  align-items: flex-start;
}
.checkbox-label span {
  flex: 1;
  font-size: 1rem;
}
.checkbox-label span a {
  font-weight: bold;
  text-decoration: underline;
}
.checkbox-label span a:hover, .checkbox-label span a:focus {
  color: var(--black);
}

.eye-pass {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-36px, -50%);
  cursor: pointer;
  color: var(--red);
}

.required-label label:after, .required .account-form--field label:after, .account-form--field.required label:after,
.field__wrap--input.required label:after {
  content: " *";
  color: var(--red);
}

input[type=checkbox] + span, input[type=checkbox] + label {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: normal;
  font-style: italic;
  line-height: 1;
  text-transform: none;
  display: inline;
  line-height: 1.5;
}
input[type=checkbox] + span .terms-link-checkout, input[type=checkbox] + label .terms-link-checkout {
  color: #ff0035;
}

.cta__default {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 31px;
  padding-right: 31px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
}
.cta__default:hover {
  background-color: var(--white);
  color: var(--black-light);
  border-color: var(--white);
}
.cta__default.theme--white {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--white);
  color: var(--black-light);
  border-color: var(--white);
}
.cta__default.theme--white:hover {
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
}
.cta__default.theme--red {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--red);
  color: var(--white);
  border-color: var(--red);
}
.cta__default.theme--red:hover {
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
}
.cta__default.theme--white-border {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--white);
  color: var(--black-light);
  border-color: var(--white);
  border: 1px solid var(--black-light);
}
.cta__default.theme--white-border:hover {
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
}
.cta__default-lg {
  padding-bottom: 20px;
  padding-top: 20px;
  line-height: max(21px, 1.25vw);
}
.cta__price {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: unset;
  border-width: 2px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
}
.cta__price:hover {
  color: var(--white);
}
.cta__price:before, .cta__price:after {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: unset;
  border-width: 2px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
  border-left-color: #3D3D3D;
  position: absolute;
  top: -2px;
  right: 0;
  height: calc(100% + 4px);
  transform: translateX(100%);
}
.cta__price:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L8 8L0 16V0Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center;
}
.cta__price:after {
  content: attr(data-content);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.cta__price[data-content]:not([data-content=""]):hover:before {
  background-image: unset;
}
.cta__price[data-content]:not([data-content=""]):hover:after {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.cta__view-all:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='10' viewBox='0 0 30 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 4.76898L25.231 0L25.2487 3.76172H0V5.64257H25.2575L25.2756 9.49341L30 4.76898Z' fill='%231B1B1B'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;
  width: max(20px, 1.5625vw);
  height: max(6.33px, 0.5208333333vw);
  margin-left: max(5px, 1.09375vw);
}
.cta--account, .subscription-edit__recipients--listing-add {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(24px, 1.328125vw);
  padding-bottom: max(24px, 1.328125vw);
  padding-left: max(24px, 1.328125vw);
  padding-right: max(24px, 1.328125vw);
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--black-light);
  color: var(--white) !important;
  border-color: var(--black-light);
  display: flex;
  width: 100%;
  max-width: 570px;
  margin: auto;
  text-decoration: none !important;
}
.cta--account:hover, .subscription-edit__recipients--listing-add:hover {
  background-color: transparent;
  color: var(--black-light) !important;
  border-color: var(--black-light) !important;
}
.cta--account-small {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(12px, 0.625vw);
  padding-bottom: max(12px, 0.625vw);
  padding-left: max(12px, 0.625vw);
  padding-right: max(12px, 0.625vw);
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--white);
  color: var(--black);
  border-color: var(--white);
  width: 100%;
}
.cta--account-small:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}

.site-container {
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(var(--container-gutter) / 2);
  padding-left: calc(var(--container-gutter) / 2);
}
@media (min-width: 1200px) {
  .site-container {
    width: calc(87.5% + var(--container-gutter));
  }
}

.site-container__inner {
  margin-right: calc(-1 * var(--container-gutter) / 2);
  margin-left: calc(-1 * var(--container-gutter) / 2);
}

.site-container__inner > *, .category-list__search-input, .category-list__product {
  padding-right: calc(var(--container-gutter) / 2);
  padding-left: calc(var(--container-gutter) / 2);
}

/*========================================================*/
/* Header style_m */
/*========================================================*/
.header .header-account-link svg, .header__logo svg {
  height: auto;
  display: block;
}

.header {
  z-index: 10;
  border-bottom: 1px solid var(--gray);
  padding: max(13px, 1.25vw) 0;
  background: #fff;
}
.menu-is-open .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.header .site-container__inner {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .header .site-container__inner {
    align-items: center;
  }
}
.header__logo, .header__menu--secondary {
  z-index: 4;
}
@media (min-width: 1024px) {
  .header__logo, .header__menu--secondary {
    width: 33.333%;
  }
}
@media (min-width: 1440px) {
  .header__logo, .header__menu--secondary {
    width: 25%;
  }
}
.header__menu--main {
  z-index: 3;
  order: 3;
}
@media screen and (min-width: 1024px) {
  .header__menu--main {
    order: unset;
    flex: 1;
  }
}
.header__logo a {
  float: left;
}
.header__logo svg {
  width: max(169px, 13.8020833333vw);
  max-height: max(39px, 3.1770833333vw);
}
.header__menu {
  color: var(--black-light);
}
.header__menu a {
  text-decoration: none;
}
.header__menu > ul {
  display: flex;
  align-items: center;
}
.header__menu--main {
  font-size: max(18px, 5vw);
  line-height: 1.23;
}
@media screen and (min-width: 1024px) {
  .header__menu--main {
    font-size: max(18px, 1.1458333333vw);
  }
}
.header__menu--secondary {
  margin-left: auto;
  font-size: max(16px, 0.8333333333vw);
  line-height: 1.4375;
}
.header__menu--secondary > ul {
  justify-content: flex-end;
}
.header__menu--secondary > ul > li:not(:last-child) {
  margin-right: 1.375em;
}
.header .header-account-link a {
  float: left;
}
.header .header-account-link svg {
  width: 24px;
}
@media screen and (min-width: 1024px) {
  .header .header-account-link svg {
    width: max(22px, 1.1458333333vw);
  }
}
.header a:hover {
  color: inherit;
}

.page_checkout .upcoming-subscriptions,
.page_order_subscriptions .upcoming-subscriptions {
  display: none;
}

.footer-container {
  background-color: var(--black);
  color: var(--white);
  padding-left: 15px;
  padding-right: 15px;
}
.footer-container .logo-container {
  display: flex;
  width: 100%;
  max-width: 200px;
  padding: max(50px, 5.9375vw) 0 max(65px, 5.5729166667vw);
  margin: 0 auto;
}
.footer-container .logo-container svg {
  padding: 5px;
}
.footer-container .newsletter-container {
  border: 1px solid #fff;
  width: 100%;
  max-width: 780px;
  margin: 0 auto max(65px, 5.2083333333vw);
}
@media screen and (max-width: 991px) {
  .footer-container .newsletter-container {
    border: none;
    margin-bottom: 65px;
  }
}
.footer-container .newsletter-container h3 {
  text-align: center;
  margin-top: max(0px, 2.0833333333vw);
}
@media screen and (max-width: 991px) {
  .footer-container .newsletter-container h3 {
    margin-top: 0;
  }
}
.footer-container .newsletter-container .newsletter-input-container {
  display: flex;
  width: 100%;
  max-width: 650px;
  justify-content: center;
  margin: 40px auto;
}
@media screen and (max-width: 767px) {
  .footer-container .newsletter-container .newsletter-input-container {
    display: block;
    margin: 30px auto 65px;
  }
}
.footer-container .newsletter-container .newsletter-input-container input {
  background-color: #fff;
  color: #D0D0D0;
  padding: 20px max(15px, 2.0833333333vw);
  width: 460px;
  border-radius: 0;
  border: 2px solid #fff;
  font-size: max(14px, 1.0416666667vw);
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .footer-container .newsletter-container .newsletter-input-container input {
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 15px;
    font-size: 14px;
  }
}
.footer-container .newsletter-container .newsletter-input-container ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #D0D0D0;
}
.footer-container .newsletter-container .newsletter-input-container ::-moz-placeholder { /* Firefox 19+ */
  color: #D0D0D0;
}
.footer-container .newsletter-container .newsletter-input-container :-ms-input-placeholder { /* IE 10+ */
  color: #D0D0D0;
}
.footer-container .newsletter-container .newsletter-input-container :-moz-placeholder { /* Firefox 18- */
  color: #D0D0D0;
}
.footer-container .newsletter-container .newsletter-input-container button {
  width: 185px;
  border: 2px solid var(--white);
  background: var(--black);
  color: var(--white);
  font-size: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.footer-container .newsletter-container .newsletter-input-container button:hover {
  background-color: var(--white);
  color: var(--black);
  border-color: var(--black);
}
@media screen and (max-width: 767px) {
  .footer-container .newsletter-container .newsletter-input-container button {
    width: 100%;
    padding: 20px 0;
    font-size: 18px;
  }
}
.footer-container .footer-cols-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto 90px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer-container .footer-cols-container {
    flex-direction: column;
    padding: 0px 5px;
    text-align: center;
  }
}
.footer-container .footer-cols-container .footer-col-1 {
  max-width: 15.9895833333vw;
}
.footer-container .footer-cols-container .footer-col-1 .footer-col-title:last-of-type {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .footer-container .footer-cols-container .footer-col {
    max-width: unset;
    margin: 0 0 40px 0;
  }
}
.footer-container .footer-cols-container .footer-col .footer-col-title {
  color: #A6A8AA;
  text-transform: uppercase;
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 700;
  font-style: normal;
  line-height: 21.92px;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .footer-container .footer-cols-container .footer-col .footer-col-title {
    margin-bottom: 25px;
  }
}
.footer-container .footer-cols-container .footer-col p {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 700;
  font-style: normal;
  line-height: 21.92px;
}
@media screen and (max-width: 767px) {
  .footer-container .footer-cols-container .footer-col p {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1505px) {
  .footer-container .footer-cols-container .footer-col p .grey-letter-wrapper {
    display: block;
  }
}
.footer-container .footer-cols-container .footer-col p .grey-letter-wrapper .grey-letter {
  color: #A6A8AA;
  text-transform: uppercase;
}
.footer-container .footer-cols-container .footer-col p a {
  display: inline-block;
}
.footer-container .footer-cols-container .footer-col p a:hover, .footer-container .footer-cols-container .footer-col p a:visited, .footer-container .footer-cols-container .footer-col p a:focus {
  color: unset;
}
.footer-container .footer-copyrights {
  padding: 0 max(0px, 15.8854166667vw) max(40px, 2.8645833333vw);
  color: #A6A8AA;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .footer-container .footer-copyrights {
    padding: 0 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .footer-container .footer-copyrights p {
    text-align: center;
  }
}
.footer-container .footer-social-icons {
  display: inline-block;
}
.footer-container .footer-social-icons:not(:last-child) {
  margin-right: 10px;
}

#subscribe_result {
  text-align: center;
  margin: 0 auto 20px;
}

#subscribe_result span {
  text-align: center;
  padding: 5px;
}

/*========================================================*/
/* Homepage style_m */
/*========================================================*/
/*home categories*/
.home-categories-container {
  margin: 75px auto;
}

.categories-wrapper.flex-row {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.categories-wrapper .category-item {
  position: relative;
}

.categories-wrapper .category-item a {
  color: #000;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  padding-bottom: 70px;
}

.categories-wrapper .category-item:first-child {
  margin-right: 15px;
}

.categories-wrapper .category-item:last-child {
  margin-left: 15px;
}

.categories-wrapper .category-item .category-info {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 30px 25px 25px;
  background-color: rgb(255, 255, 255);
  width: calc(100% - 8.333vw);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.categories-wrapper .category-item a:hover .category-info {
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.categories-wrapper .category-item .title {
  justify-content: space-between;
  align-items: center;
}

.categories-wrapper .category-item .title h3 {
  max-width: calc(100% - 120px);
}

.categories-wrapper .category-item .title .category-btn {
  font-size: 1.4rem;
  font-weight: 600;
}

.categories-wrapper .category-item .title .category-btn:after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #000;
  position: relative;
  bottom: -3px;
}

/*home carousel tabs*/
.carousel-container {
  margin: 100px auto;
  padding: 0 30px;
}

.carousel-container .nav-tabs {
  text-align: center;
  border-bottom: none;
  margin-bottom: 30px;
}

.carousel-container .nav-tabs > li {
  float: unset;
  display: inline-block;
}

.carousel-container .nav-tabs > li a {
  font-size: 1.8rem;
  line-height: 1.5;
  color: #000;
  border: none;
  padding: 10px 25px;
  margin: 0;
}

.carousel-container .nav-tabs > li.active a {
  font-size: 2.4rem;
  line-height: 2;
  font-weight: 600;
  color: #000;
  border: none;
}

.carousel-container .nav-tabs > li a:focus,
.carousel-container .nav-tabs > li a:hover {
  background-color: transparent;
}

.carousel-container .nav-tabs > li.active a:after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #000;
  position: relative;
  bottom: -3px;
}

/*carousel slick dots*/
.carousel-container .tab-content .tab-pane {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
}

.carousel-container .tab-content .tab-pane.active {
  height: auto;
}

.carousel-container .tab-content .tab-pane .carousel-wrapper {
  position: relative;
  padding-bottom: 55px;
}

.carousel-container .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.carousel-container .slick-dots li {
  display: inline-block;
  margin: 0 10px;
}

.carousel-container .slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  display: inline-block;
  padding: 0;
  background-color: #c8c8c9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
}

.carousel-container .slick-dots li.slick-active button {
  background-color: #000;
}

/*carousel slick arrows */
.carousel-arrows {
  display: none;
}

.show-slider-nav + .carousel-arrows {
  display: block;
}

.carousel-container .carousel-arrows {
  position: absolute;
  left: 50%;
  bottom: 15px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 190px;
  width: 100%;
}

.carousel-container .carousel-arrows .arrow,
.carousel-container .carousel-arrows .arrow svg {
  display: block;
  width: 30px;
  height: 9px;
  cursor: pointer;
}

.carousel-container .carousel-arrows .arrow {
  position: absolute;
}

.carousel-container .carousel-arrows .arrow.next {
  right: 0;
}

/*home more categories*/
.more-category-wrapper.flex-row {
  justify-content: center;
}

.home-more-categories {
  padding: 0 15px;
  padding-bottom: 40px;
}

.home-more-categories h2 {
  margin-bottom: 50px;
}

.home-more-categories .category-item:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.more-category-wrapper .category-item {
  position: relative;
  margin: 0 15px;
}

.more-category-wrapper .category-info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 25px 30px;
  width: 100%;
  z-index: 1;
}

.more-category-btn {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  padding: 15px 25px;
  position: relative;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.category-item a:hover .more-category-btn {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.more-category-wrapper .category-item img {
  align-self: center;
}

@media (max-width: 1366px) {
  /*homepage*/
  .more-category-wrapper .category-info {
    padding: 20px;
  }
  .more-category-wrapper .category-info h3 {
    font-size: 2.5rem;
  }
  .more-category-btn {
    padding: 10px 20px;
  }
  .carousel-container .nav-tabs > li.active a {
    font-size: 2rem;
  }
}
@media (max-width: 1024px) {
  /*homepage*/
  .home-categories-container {
    margin: 25px auto 75px;
  }
  .categories-wrapper .category-item .category-info {
    width: calc(100% - 3.333vw);
    padding: 20px;
  }
  .more-category-wrapper.flex-row {
    flex-wrap: wrap;
  }
  .more-category-wrapper .category-item {
    margin-bottom: 30px;
    width: calc(50% - 30px);
  }
  .more-category-wrapper .category-item a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  /*homepage*/
  .home-categories-container {
    margin-bottom: 55px;
  }
  .categories-wrapper .category-item .title h3 {
    font-size: 1.5rem;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  /*homepage*/
  .categories-wrapper.flex-row {
    flex-direction: column;
    padding: 0 15px;
  }
  .home-categories-container {
    margin-bottom: 35px;
  }
  .categories-wrapper .category-item:first-child,
  .categories-wrapper .category-item:last-child {
    margin: 0 auto;
  }
  .categories-wrapper .category-item + .category-item {
    margin-top: 15px;
  }
  .categories-wrapper .category-item .category-info {
    width: calc(100% - 15vw);
    padding: 10px;
    text-align: center;
  }
  .categories-wrapper .category-item .title h3 {
    margin: 5px auto;
  }
  .categories-wrapper .category-item .title {
    flex-direction: column;
  }
  .home-more-categories {
    padding-bottom: 0;
  }
  .home-more-categories h2 {
    font-size: 2rem;
  }
  .more-category-wrapper .category-item {
    width: calc(50% - 30px);
    margin: 0 auto 15px;
  }
  .carousel-container .nav-tabs > li.active a {
    font-size: 1.6rem;
    padding: 15px;
  }
}
@media (max-width: 580px) {
  /*homepage*/
  .carousel-container {
    margin-top: 20px;
    padding: 0 15px;
  }
  .carousel-container .nav-tabs {
    margin-bottom: 20px;
  }
  .carousel-container .nav-tabs > li a {
    font-size: 1.5rem;
    padding: 10px;
  }
  .carousel-container .nav-tabs > li.active a {
    font-size: 1.6rem;
  }
}
label.disabled {
  opacity: 0.4 !important;
}

.has-page-message, .has-page-message-error, .has-page-message-success {
  margin: auto;
  max-width: 600px;
  text-align: center;
  border: 1px solid;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 80%;
  max-width: 570px;
}
.has-page-message-wrap {
  margin-left: 15px;
  margin-right: 15px;
}
.has-page-message-error {
  color: var(--red);
}
.has-page-message-success {
  color: var(--black-light);
}

.account-table--head, .account-table thead > tr > td {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 600;
  font-style: normal;
  line-height: 1;
}
.account-table--body, .account-table tbody > tr > td {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
}
.account-page-height {
  min-height: calc(var(--screen-height) - var(--header-height));
  padding-bottom: max(30px, 3.125vw);
}
.account-max--small {
  max-width: 600px;
}
.account-max--big {
  max-width: 1000px;
}
.account-title {
  margin-bottom: max(20px, 2.0833333333vw);
  color: var(--black-light);
  text-transform: uppercase;
}
.account-text {
  margin-bottom: max(20px, 2.0833333333vw);
  color: var(--black-light);
}
.account-cta {
  margin-top: auto;
}
.account-cta--forgot {
  text-align: right;
  font-size: 80%;
}
.account-cta--forgot a {
  background: transparent;
  border: none;
  color: inherit;
  text-decoration: underline;
  padding: 0;
  position: absolute;
  right: 0;
}
.account-cta--forgot a:hover {
  background-color: unset;
  color: unset;
  border-color: unset;
}
.account-page-heading {
  margin-bottom: max(20px, 2.0833333333vw);
  color: var(--black-light);
  display: flex;
  flex-direction: column;
}
.account-page-heading h2 {
  font-family: var(--font-default), sans-serif;
  font-size: max(22px, 1.25vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-align: center;
  color: inherit;
  margin-bottom: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.account-page-heading > span {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  margin-bottom: 0;
  display: inline;
  text-align: center;
  max-width: 375px;
  margin: auto;
}
.account-page-heading > span.expand-width {
  max-width: unset;
}
.account-block {
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-block-title {
  margin-bottom: 20px;
  text-transform: uppercase;
}
.account-content .js-has-error {
  font-size: 12px;
  color: var(--red);
  margin-left: 5px;
}
.account-content .js-custom-field {
  display: none;
}
.account-content .if-no-socials {
  margin-bottom: max(20px, 2.0833333333vw);
}
@media screen and (max-width: 767px) {
  .account-content .table-responsive {
    border: unset;
  }
}
.account-content .input-group button {
  background-color: transparent !important;
  border: unset !important;
}
.account-content .input-group button:active {
  box-shadow: unset !important;
}
.account-content a:hover {
  color: inherit;
  text-decoration: underline;
}
.account-content p a {
  text-decoration: underline;
}
.account-content select {
  border-color: var(--black-light);
  color: var(--black-light);
}
.account-content input:-webkit-autofill, .account-content input:-webkit-autofill:hover, .account-content input:-webkit-autofill:focus {
  box-shadow: unset !important;
}
.account-content .inline-option,
.account-content ._newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}
.account-content .inline-option > *,
.account-content ._newsletter > * {
  text-align: center;
}
.account-content .inline-option--not-centered,
.account-content ._newsletter--not-centered {
  align-items: initial;
}
.account-content .inline-option--not-centered > *,
.account-content ._newsletter--not-centered > * {
  text-align: left;
}
.account-content .inline-option label,
.account-content ._newsletter label {
  left: 0;
  top: 0;
}
.account-content .inline-option label.radio-inline,
.account-content ._newsletter label.radio-inline {
  padding: 5px;
}
@media screen and (min-width: 992px) {
  .account-content .inline-option label.radio-inline,
  .account-content ._newsletter label.radio-inline {
    font-size: 75%;
  }
}
.account-content #d_social_login {
  font-size: 12px;
}
.account-content .order-pag {
  font-size: 14px;
}
.account-content thead tr td {
  color: var(--black-light);
}
.account-content tfoot {
  font-size: 14px;
}
.account-content tfoot tr:last-of-type {
  text-transform: uppercase;
}
.account-content fieldset {
  border: 1px solid var(--black-light);
  padding: max(20px, 1.4583333333vw);
  margin-bottom: 19px;
}
.account-content fieldset legend {
  display: inline-block;
  width: auto;
  padding: 0 19px;
  margin-bottom: unset;
  border: unset;
}
.account-dual-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: max(20px, 4.6875vw);
  padding-top: max(20px, 4.6875vw);
  border-bottom: 1px solid var(--black-light);
}
@media screen and (max-width: 991px) {
  .account-dual-block {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
    padding-bottom: 42px;
  }
}
.account-dual-block--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  max-width: 512px;
}
@media screen and (max-width: 991px) {
  .account-dual-block--item {
    width: 100%;
    text-align: center;
  }
  .account-dual-block--item:first-child {
    margin-top: 25px;
    margin-bottom: 64px;
  }
}
@media screen and (min-width: 992px) {
  .account-dual-block--item:first-child {
    margin-right: 58px;
  }
  .account-dual-block--item:last-child {
    margin-left: 58px;
  }
}
@media screen and (min-width: 992px) {
  .account-dual-block--item p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991px) {
  .account-dual-block--item p {
    text-align: center !important;
  }
}
.account-single-block--logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  margin-bottom: 50px;
}
.account-form--field:not(.account-cta) {
  margin-bottom: 19px;
}
.account-form--field > div > label:last-child {
  margin-left: 10px;
}
.account-form--field.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-table-themed {
  border: 1px solid var(--black-light);
  border-radius: 100px;
  padding: max(20px, 2.0833333333vw);
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-table-themed table > tbody > tr > td,
.account-table-themed table > tbody > tr > th,
.account-table-themed table > tfoot > tr > td,
.account-table-themed table > tfoot > tr > th,
.account-table-themed table > thead > tr > td,
.account-table-themed table > thead > tr > th {
  border-bottom: unset !important;
}
.account-table {
  width: 100%;
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-table .nowrap {
  white-space: nowrap;
}
.account-table > tbody > tr > td,
.account-table > tbody > tr > th,
.account-table > tfoot > tr > td,
.account-table > tfoot > tr > th,
.account-table > thead > tr > td,
.account-table > thead > tr > th {
  border: none;
  border-bottom: 1px solid var(--gray-light);
  padding: 20px 15px;
}
.account-table td.text-left a {
  color: #000;
  text-decoration: none;
}
.account-table td.text-right > a {
  text-decoration: none;
}
.account-table .add-to-cart-icon svg,
.account-table .add-to-cart-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.account-table .remove-item-icon svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.account-table .remove-item-icon svg,
.account-table .remove-item-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.account-table thead > tr > td {
  color: #000;
}
.account-table tbody > tr > td {
  color: #000;
}
.account-table .table-hover > tbody > tr {
  opacity: 0.7;
  background-color: transparent;
}
.account-table .table-hover > tbody > tr:hover {
  opacity: 1;
}
.account-table td.text-right > a.edit-address-btn,
.account-table td.text-right > a.remove-address-btn,
.account-table td.text-right > a.btn-view-item {
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #000;
  border: none;
  border-radius: 0;
  background-image: none;
  text-transform: uppercase;
  text-decoration: underline;
}

#d_social_login {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: max(20px, 4.6875vw) auto max(20px, 1.5625vw) auto;
}
#d_social_login .d-social-login__label {
  height: auto;
  margin: auto;
}
#d_social_login .d-social-login__button {
  display: block;
  width: 100%;
  color: var(--white);
  text-decoration: none;
  border-radius: unset;
  margin-top: unset;
}
#d_social_login .d-social-login__button:not(:last-child) {
  margin-bottom: 19px;
}
#d_social_login .d-social-login__button__icon {
  max-width: 100%;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 45px;
}
#d_social_login .d-social-login__button__icon img {
  max-width: 30px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
#d_social_login .d-social-login__button--facebook {
  background: linear-gradient(150deg, #4864b4 0%, var(--black-light) 30%);
}
#d_social_login .d-social-login__button--facebook img {
  max-width: 10px;
}
#d_social_login .d-social-login__button--google {
  background: linear-gradient(150deg, #dd4b39 0%, var(--black-light) 30%);
}
#d_social_login .d-social-login__button--google img {
  max-width: 25px;
}

.grid-placing--2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 19px;
}
.grid-placing--3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 19px;
}
@media screen and (max-width: 767px) {
  .grid-placing--2, .grid-placing--3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.subscription-listing {
  flex: auto;
}
.subscription-listing__wrap {
  overflow: auto;
}
.subscription-listing__wrap::-webkit-scrollbar {
  width: 12px;
}
.subscription-listing__wrap::-webkit-scrollbar-track {
  background: var(--white);
}
.subscription-listing__wrap::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 20px;
  border-style: solid;
  border-color: var(--white);
  border-width: 5px;
}
.subscription-listing__table {
  display: inline-flex;
  flex-direction: column;
  min-width: 400px;
  min-width: 100%;
}
.subscription-listing__table--heading {
  display: flex;
  border-bottom: 1px solid var(--black-light);
}
.subscription-listing__table--heading, .subscription-listing__table--item {
  display: inline-flex;
  line-height: 1;
  font-size: 80%;
  width: 100%;
}
.subscription-listing__table--heading span, .subscription-listing__table--item span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  min-width: max(85px, 7.03125vw);
  padding: 15px 5px;
  text-align: center;
}
.subscription-listing__table--heading span:not(:last-child), .subscription-listing__table--item span:not(:last-child) {
  margin-right: 5px;
}
.subscription-listing__table--heading span:first-child, .subscription-listing__table--item span:first-child {
  justify-content: flex-start;
}
.subscription-listing__table--heading span.column--small, .subscription-listing__table--item span.column--small {
  min-width: max(50px, 4.4270833333vw);
}
.subscription-listing__table--heading span.column--large, .subscription-listing__table--item span.column--large {
  min-width: max(125px, 9.1145833333vw);
}
.subscription-listing__table--item {
  background-color: var(--gray-light);
  border-radius: 5px;
  margin-top: 15px;
}
.subscription-edit__details {
  padding-left: max(15px, 1.0416666667vw) !important;
  padding-right: max(15px, 1.0416666667vw) !important;
}
.subscription-edit__details .subscription-edit__block--column {
  width: 48%;
}
.subscription-edit__block {
  display: flex;
  justify-content: space-between;
}
.subscription-edit__block--column {
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-size: 80%;
}
.subscription-edit__block--column-heading {
  display: inline-flex;
  align-self: flex-start;
  padding: 5px;
  font-size: 80%;
  background-color: var(--gray-light);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.subscription-edit__block--column span:not(:last-child) {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--black-light);
}
.subscription-edit__addresses {
  padding: unset;
}
.subscription-edit__addresses .subscription-edit__block {
  justify-content: space-between;
}
@media screen and (max-width: 991px) {
  .subscription-edit__addresses .subscription-edit__block {
    flex-direction: column;
    justify-content: unset;
  }
}
.subscription-edit__addresses .subscription-edit__block--column {
  flex: auto;
}
@media screen and (min-width: 992px) {
  .subscription-edit__addresses .subscription-edit__block--column:not(:first-child) {
    margin-left: 15px;
  }
}
.subscription-edit__addresses address {
  background-color: var(--gray-light);
  padding: 15px;
  border-radius: 5px;
  border-top-left-radius: unset;
}
.subscription-edit__recipients--listing-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--gray-light);
  border-radius: 5px;
  padding: 15px;
  font-size: 80%;
  line-height: 1;
  margin-bottom: 15px;
}
.subscription-edit__recipients--listing-add {
  max-width: unset;
}
.subscription__actions {
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  background-color: var(--gray-light);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px -5px #000000;
}
.subscription__actions > * {
  cursor: pointer;
}
.subscription__actions > *:not(:first-child) {
  margin-left: 10px;
}

.cta--duplicate {
  color: darkblue;
}

.account-content .fieldset__title-only {
  padding: unset;
  border: unset;
  border-top: 1px solid var(--black-light);
}
.account-content .fieldset__title-only legend {
  margin-left: auto;
  margin-right: auto;
}

.recipients {
  margin-bottom: 2em;
}
.recipients .js-has-error {
  margin-top: 0.5em;
  line-height: 1.2;
}
.recipients .remove-row {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='%231B1B1B' d='M352 128c0 70.7-57.3 128-128 128S96 198.7 96 128 153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4c98.5 0 178.3 79.8 178.3 178.3 0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM472 200h144c13.3 0 24 10.7 24 24s-10.7 24-24 24H472c-13.3 0-24-10.7-24-24s10.7-24 24-24z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 16px;
  position: absolute;
  top: 0;
  transform: translateY(75%);
  right: -17px;
  width: 35px;
  height: 30px;
  background-color: var(--gray-light);
  padding: 5px;
  border-radius: 5px;
  box-shadow: -2px 2px 10px -5px #000000;
  border: unset;
  opacity: 0;
  pointer-events: none;
}
.recipients .repeater-field__rows {
  margin-bottom: 1em;
}
.recipients .repeater-field__row:hover .remove-row {
  opacity: 1;
  pointer-events: auto;
}
.recipients .repeater-field__row:not(:first-child) .repeater-field__row-header {
  border-top: 0 none;
}
.recipients .repeater-field__row-header {
  font-size: 1em;
  padding: 1em 1.5em;
  border: 1px solid #000;
  cursor: pointer;
}
.recipients .repeater-field__row-content {
  font-size: 1em;
  padding: 1em 1.5em;
  border: 1px solid #000;
  border-top: 0 none;
}
.recipients .repeater-field__row:nth-child(even) .repeater-field__row-header {
  background: #ededed;
}

.cta--cancelation {
  color: var(--red);
}

.controlled_by_same_addresses_for_all--false {
  display: none !important;
}

.loading-overlay {
  display: none;
  background: rgba(26, 26, 26, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
}

.loading-overlay-image-container {
  display: none;
  position: fixed;
  z-index: 7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-overlay-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  #modalUpdateAddressInfo .modal-dialog {
    width: unset;
  }
}
@media screen and (min-width: 1200px) {
  #modalUpdateAddressInfo .modal-dialog {
    width: 1000px;
  }
}
#checkout-page {
  margin-top: max(20px, 3.6458333333vw);
  margin-bottom: max(80px, 10.15625vw);
}
#checkout-page .js-has-error {
  color: var(--red);
  font-size: 0.75em;
}

.checkout {
  height: 100%;
}
.checkout__roadmap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 1.25em;
  margin-bottom: 1em;
  line-height: 2;
  background-color: #ddd;
  counter-reset: checkout-steps;
}
@media screen and (min-width: 540px) {
  .checkout__roadmap {
    flex-wrap: nowrap;
    height: 2em;
  }
}
.checkout__roadmap li {
  position: relative;
  flex-grow: 1;
  height: 100%;
  padding: 0 3em;
  color: #666;
  white-space: nowrap;
  width: 100%;
  counter-increment: checkout-steps;
}
.checkout__roadmap li[data-complete] {
  background: green;
  color: white;
  cursor: not-allowed;
}
.checkout__roadmap li[data-complete]::after {
  cursor: not-allowed;
}
@media screen and (min-width: 540px) {
  .checkout__roadmap li[data-complete]::after {
    background: green;
  }
}
.checkout__roadmap li[data-complete][data-editable] {
  cursor: pointer;
}
.checkout__roadmap li::after {
  content: counter(checkout-steps);
  position: absolute;
  z-index: 1;
  width: 1.5em;
  height: 1.5em;
  right: auto;
  left: 0.5em;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  top: 0.25em;
  line-height: 1.5;
  color: #666;
}
@media screen and (min-width: 540px) {
  .checkout__roadmap li::after {
    content: "";
    top: auto;
    right: -1em;
    left: auto;
    border-radius: 0;
    width: 2em;
    height: 2em;
    background: transparent;
    transform: rotate(45deg);
    box-shadow: 0.25em -0.25em white;
  }
}
.checkout__steps {
  height: calc(100% - 3.75em);
  margin-right: calc(-1 * var(--container-gutter) / 2);
  margin-left: calc(-1 * var(--container-gutter) / 2);
}
.checkout__step {
  height: 100%;
  overflow: auto;
  padding-right: calc(var(--container-gutter) / 2);
  padding-left: calc(var(--container-gutter) / 2);
}
.checkout .tabs__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  border-bottom: max(1px, 0.0625rem) solid var(--black-light);
  border-left: max(1px, 0.0625rem) solid var(--black-light);
  margin-bottom: 1em;
  font-size: 1.25em;
  font-weight: 700;
}
.checkout .tab__header {
  padding: 0.75em 1.25em;
  border-top: max(1px, 0.0625rem) solid var(--black-light);
  border-right: max(1px, 0.0625rem) solid var(--black-light);
  cursor: pointer;
}
.checkout .tab__header.active {
  color: #fff;
  background: var(--black-light);
}
.checkout .tab__content {
  display: none;
}
.checkout .tab__content.active {
  display: block;
}
.checkout .recipients {
  margin-bottom: 2em;
}
.checkout .recipients .remove-row {
  position: absolute;
  right: -1em;
  top: -1em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 1px solid #000;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}
.checkout .recipients .remove-row::before, .checkout .recipients .remove-row::after {
  content: "";
  width: 60%;
  height: max(1px, 0.0520833333vw);
  background: var(--black-light);
  position: absolute;
  top: 50%;
  left: 50%;
}
.checkout .recipients .remove-row::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.checkout .recipients .remove-row::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.checkout .recipients .remove-row:hover {
  background: var(--black-light);
}
.checkout .recipients .remove-row:hover::before, .checkout .recipients .remove-row:hover::after {
  background: #fff;
}
.checkout .recipients .repeater-field__rows {
  margin-bottom: 1em;
}
.checkout .recipients .repeater-field__row:hover .remove-row {
  opacity: 1;
  pointer-events: auto;
}
.checkout .recipients .repeater-field__row:not(:first-child) .repeater-field__row-header {
  border-top: 0 none;
}
.checkout .recipients .repeater-field__row-header {
  font-size: 1em;
  padding: 1em 1.5em;
  border: 1px solid #000;
  cursor: pointer;
}
.checkout .recipients .repeater-field__row-content {
  font-size: 1em;
  padding: 1em 1.5em;
  border: 1px solid #000;
  border-top: 0 none;
}
.checkout .recipients .repeater-field__row:nth-child(even) .repeater-field__row-header {
  background: #ededed;
}
.checkout__product-quantity-lbl {
  flex: 1;
}
.checkout__product-quantity {
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0;
  background: var(--gray-lighter);
  padding: 1em;
}
.checkout__product-quantity-input-group .quantity {
  display: flex;
}
.checkout__product-quantity-input-group .quantity__button {
  border: 1px solid #000;
  cursor: pointer;
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: normal;
  font-style: normal;
  line-height: max(21px, 1.25vw);
  color: var(--black-light);
  padding: 10px max(10px, 0.9375vw);
  user-select: none;
}
.checkout__product-quantity-input-group .quantity__button--down {
  border-right: 0 none;
}
.checkout__product-quantity-input-group .quantity__button--up {
  border-left: 0 none;
}
.checkout__product-quantity-input-group .quantity__input {
  text-align: center;
}
body .checkout__product-quantity-input-group .quantity__input {
  padding: 10px max(10px, 0.9375vw);
  width: calc(3em + 20px);
}
.checkout__product-quantity-input-group .quantity__input::-webkit-inner-spin-button, .checkout__product-quantity-input-group .quantity__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.checkout .cart-payment__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * var(--container-gutter) / 2);
  margin-left: calc(-1 * var(--container-gutter) / 2);
}
.checkout .cart-payment__col {
  padding-right: calc(var(--container-gutter) / 2);
  padding-left: calc(var(--container-gutter) / 2);
  width: 100%;
}
@media screen and (min-width: 992px) {
  .checkout .cart-payment__col {
    width: 50%;
  }
}
.checkout .cart-payment__header {
  border-bottom: max(1px, 0.0625rem) solid var(--black-light);
  padding-bottom: 0.25em;
  margin-bottom: 1em;
}
.checkout .cart-product {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
}
.checkout .cart-product__img-cnt {
  width: max(100px, 5.2083333333vw);
}
.checkout .cart-product__info {
  flex: 1;
  padding-left: 1em;
  display: flex;
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(22px, 1.1458333333vw);
}
.checkout .cart-product__name {
  width: 70%;
}
.checkout .cart-product__qnt, .checkout .cart-product__price {
  text-align: right;
  flex: 1;
}
.checkout .cart-product__price {
  padding-left: 1em;
}
.checkout .cart-product + .cart__price {
  margin-top: 2em;
}
.checkout .cart__price {
  display: flex;
  padding: 1em 0;
  border-top: 1px solid var(--black-light);
}
.checkout .cart__price--total {
  font-weight: 700;
}
.checkout .cart__price-lbl {
  flex: 1;
  padding-right: 1em;
}
.checkout .shipping_details {
  width: 50%;
}
.checkout .shipping_details__row {
  display: flex;
}
.checkout .shipping_details__row--header {
  border-bottom: 1px solid var(--black-light);
  font-weight: 700;
}
.checkout .shipping_details__lbl {
  padding-right: 0.5em;
  font-weight: 700;
}
.checkout .has-error .input__default, .checkout .has-error input:not([type=radio]):not([type=submit]),
.checkout .has-error .select2-selection {
  border-color: var(--red);
}

.loading {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 99;
}

.redeem {
  margin-bottom: 0;
}
.redeem__row {
  display: flex;
  align-items: flex-start;
}
.redeem__input {
  flex: 1;
  width: 100%;
}
.redeem__buttons {
  margin-left: 1em;
}

/* cart page */
#checkout-cart .mini-cart-total {
  font-size: 1.4rem;
  text-align: right;
}
#checkout-cart .mini-cart-checkout {
  background-color: transparent;
  justify-content: flex-end;
}
#checkout-cart .mini-cart-checkout a {
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  align-self: flex-end;
  max-width: 250px;
  border: 1px solid;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 580px) {
  #checkout-cart .mini-cart-checkout a {
    max-width: 100%;
  }
}
#checkout-cart .mini-cart-checkout a:hover {
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#checkout-cart .cart-page .mini-cart-total {
  display: flex;
  flex-basis: 100%;
  margin-top: 30px;
  justify-content: flex-end;
}
#checkout-cart .cart-page .mini-cart-total .col-total {
  width: 100%;
  max-width: 250px;
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.022em;
  background-color: #e4e4e4;
  text-align: center;
  text-transform: uppercase;
  padding: 25px;
  margin-bottom: 15px;
}
@media (max-width: 580px) {
  #checkout-cart .cart-page .mini-cart-total .col-total {
    max-width: 100%;
  }
}
#checkout-cart .cart-page .mini-cart-total .cart-breakdown {
  width: 100%;
  margin-bottom: 10px;
}
#checkout-cart .cart-page ul li {
  border-bottom: 1px solid #ddd;
}
#checkout-cart .cart-page ul li + li {
  margin-top: 10px;
}
#checkout-cart .cart-page ul li .mini-cart-products {
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img {
  flex-basis: 15%;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-img {
    flex-basis: 25%;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img a {
  display: inline-block;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img a .img-thumbnail {
  border: none;
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item {
  flex-basis: 55%;
}
@media (max-width: 580px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item {
    flex-basis: 70%;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item a {
  display: inline-block;
  margin-bottom: 0;
}
@media (max-width: 580px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item a {
    font-size: 1.4rem;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item .prd-description, #checkout-cart .cart-page ul li .mini-cart-products .prd-item .prd-price {
  margin-bottom: 0;
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item .product-quantity.mobile-view {
  display: none;
}
@media (max-width: 580px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item .product-quantity.mobile-view {
    display: block;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove {
  align-self: start;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item {
  background: transparent;
  border: none;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
  display: block;
  width: 17px;
  height: 17px;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
    width: 12px;
    height: 12px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
  display: block;
  width: 17px;
  height: 17px;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
    width: 12px;
    height: 12px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg .st0 {
  fill: none;
  stroke: #8c8c8c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity {
  flex-basis: 20%;
  margin-right: 15px;
}
@media (max-width: 580px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity.desktop-view {
    display: none;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field {
  display: block;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
  display: flex;
  justify-content: space-between;
  flex-basis: 60%;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
    flex-basis: 100%;
  }
}
@media (max-width: 580px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
    max-width: 165px;
    margin-top: 20px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity button.btn.btn-primary {
  background: no-repeat;
  border: 0;
  text-shadow: none;
  box-shadow: none;
  padding: 0;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  margin-left: 15px;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper {
  position: relative;
  width: 100%;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper input.form-control {
  border: 0;
  box-shadow: none;
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-align: center;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper .quantity-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper .quantity-nav .quantity-button {
  cursor: pointer;
  padding: 8px 20px;
}
#checkout-cart .product-grid, #checkout-cart .choosegift, #checkout-cart .giftavailable {
  display: none;
}

/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.category-list__header {
  margin-top: max(14px, 2.5vw);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (min-width: 767px) {
  .category-list__header::after {
    content: "";
    display: block;
    width: 100%;
    margin-top: max(17px, 1.0416666667vw);
    border-bottom: 1px solid var(--black-light);
  }
}
.category-list__title {
  width: 100%;
  text-align: center;
}
.category-list__title::after {
  content: "";
  display: block;
  width: 100%;
  margin: max(17px, 1.0416666667vw) 0;
  border-bottom: 1px solid var(--black-light);
}
@media screen and (min-width: 767px) {
  .category-list__title {
    text-align: left;
    width: auto;
  }
  .category-list__title::after {
    display: none;
  }
}
.category-list__filters-header {
  margin-left: auto;
  width: 100%;
}
@media screen and (min-width: 767px) {
  .category-list__filters-header {
    width: auto;
  }
}
.category-list__products {
  display: flex;
  flex-wrap: wrap;
  margin-top: max(20px, 3.6458333333vw);
  margin-bottom: max(80px, 10.15625vw);
  padding-right: 0;
  padding-left: 0;
}
.category-list__product {
  margin-top: max(50px, 3.125vw);
  width: 100%;
}
.category-list__product .product {
  min-width: auto;
  max-width: none;
}
.category-list__product:first-child {
  margin-top: 0;
}
@media screen and (min-width: 580px) {
  .category-list__product {
    width: 50%;
  }
  .category-list__product:nth-child(2) {
    margin-top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .category-list__product {
    width: 33.333%;
  }
  .category-list__product:nth-child(3) {
    margin-top: 0;
  }
}
@media screen and (min-width: 1440px) {
  .category-list__product {
    width: 25%;
  }
  .category-list__product:nth-child(4) {
    margin-top: 0;
  }
}
.category-list__filters-header .close-filters,
.category-list__filters-header .bf-btn-show,
.category-list__filters-header .box-heading,
.category-list__filters-header .bf-attr-header {
  display: none !important;
}
.category-list__filters-header .bf-form {
  display: flex;
}
.category-list__filters-header .bf-form .bf-attr-block {
  width: 100%;
}
.category-list__filters-header .bf-form .bf-buttonclear-box {
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 1.5em;
  margin: -0.75em 0 0 -0.75em;
  padding: 0;
  overflow: hidden;
  border: 0.075em solid var(--gray-dark);
  border-radius: 50%;
  background: #fff;
}
.category-list__filters-header .bf-form .bf-buttonclear-box::before, .category-list__filters-header .bf-form .bf-buttonclear-box::after {
  content: "";
  display: block;
  width: 60%;
  height: 0.075em;
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--gray-dark);
  pointer-events: none;
}
.category-list__filters-header .bf-form .bf-buttonclear-box::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.category-list__filters-header .bf-form .bf-buttonclear-box::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.category-list__filters-header .bf-form .bf-buttonclear-box .bf-buttonclear {
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  opacity: 0;
}
.category-list__filters-header .bf-check-position {
  border: 0 none;
  border-radius: 0;
  margin-bottom: 0;
}
.category-list__filters-header .bf-attr-block-cont {
  padding: 0;
}
.category-list__filters-header .bf-cell {
  border-bottom: 0 none !important;
  display: block;
  padding: 0;
  height: auto;
}
.category-list__filters-header select {
  display: none;
}

.category-filters.select2-container {
  width: 100%;
}
@media screen and (min-width: 767px) {
  .category-filters.select2-container {
    width: auto;
  }
}
.category-filters.select2-container .select2-selection {
  min-width: 100%;
  border: 1px solid var(--gray-light);
  border-radius: 2px;
  height: auto;
  padding: 20px calc(max(20px, 1.0416666667vw) + 33px) 20px max(20px, 1.0416666667vw);
}
@media screen and (min-width: 767px) {
  .category-filters.select2-container .select2-selection {
    min-width: max(188px, 9.7916666667vw);
  }
}
.category-filters.select2-container .select2-selection__rendered {
  color: var(--black-light);
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 600;
  font-style: normal;
  line-height: max(19.49px, 1.0151041667vw);
}
.category-filters.select2-container .select2-selection__placeholder {
  color: var(--black-light);
}
.category-filters.select2-container--open {
  right: 0;
  left: unset !important;
}
.category-filters.select2-container .select2-dropdown {
  left: unset;
  right: 0;
  min-width: max(320px, 16.6666666667vw) !important;
}

/*========================================================*/
/*** BRANDS ***/
/*========================================================*/
#product-manufacturer h1 {
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
}

ul.brand-anchor {
  padding-bottom: 30px;
}

ul.brand-anchor li {
  max-width: 30px;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 7px 7px 0;
  background-color: #f0f0f0;
}

ul.brand-anchor li > a {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  display: block;
  width: 100%;
  padding: 7px 0;
}

ul.brand-anchor li:hover {
  background-color: #000;
}

ul.brand-anchor li:hover a {
  color: #fff;
}

.brand-letter {
  display: inline-block;
  width: 100%;
  padding: 0 0 20px;
  margin: 20px 0;
  position: relative;
  border-bottom: 1px solid #eee;
}

.letter_only {
  display: inline-block;
  float: left;
  width: 10%;
}

.brands_per_letter {
  display: inline-block;
  float: left;
  width: 90%;
}

#product-manufacturer .brand-name {
  margin: 0 0 20px 0;
  font-weight: 800;
}

.brands_per_letter a {
  color: #000;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container .product-wrapper {
  padding: 50px max(10px, 5.9895833333vw) max(40px, 12.5vw);
}
@media screen and (max-width: 1140px) {
  .product-container .product-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 580px) {
  .product-container .product-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.product-container .product-wrapper .product-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1465px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .product-container .product-wrapper .product-row {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.product-container .product-wrapper .product-row .left-col {
  width: 100%;
  max-width: 825px;
  margin-right: 3.90625vw;
}
@media screen and (max-width: 1140px) {
  .product-container .product-wrapper .product-row .left-col {
    margin-right: 35px;
  }
}
@media screen and (max-width: 991px) {
  .product-container .product-wrapper .product-row .left-col {
    margin-right: 0;
    max-width: 100%;
    margin-top: 40px;
  }
}
@media screen and (max-width: 580px) {
  .product-container .product-wrapper .product-row .left-col {
    margin-top: 15px;
  }
}
.product-container .product-wrapper .product-row .left-col .product-description {
  border: 1px solid #D9D9D9;
  padding: 35px 55px 50px;
  text-align: justify;
  background-color: var(--white);
}
@media screen and (max-width: 580px) {
  .product-container .product-wrapper .product-row .left-col .product-description {
    padding: 15px;
    border: unset;
  }
}
.product-container .product-wrapper .product-row .left-col .product-description h3 {
  margin-bottom: 23px;
}
.product-container .product-wrapper .product-row .left-col .product-description .description {
  margin-bottom: 35px;
}
.product-container .product-wrapper .product-row .left-col .product-description .description div * {
  font-family: var(--font-default), sans-serif !important;
  font-size: max(14px, 1.0416666667vw) !important;
  line-height: max(21px, 1.7708333333vw) !important;
  list-style-type: disc;
}
.product-container .product-wrapper .product-row .left-col .product-description .industry {
  margin-bottom: 25px;
}
.product-container .product-wrapper .product-row .left-col ul.topics {
  display: flex;
  flex-wrap: wrap;
}
.product-container .product-wrapper .product-row .left-col ul.topics li:not(:last-child):after {
  display: inline-block;
  content: ",";
  margin: 0 10px 0 1px;
}
.product-container .product-wrapper .product-row .left-col ul.topics li a {
  display: inline-block;
  position: relative;
}
.product-container .product-wrapper .product-row .left-col ul.topics li a:hover, .product-container .product-wrapper .product-row .left-col ul.topics li a:focus, .product-container .product-wrapper .product-row .left-col ul.topics li a:active {
  color: var(--black);
}
.product-container .product-wrapper .product-row .left-col ul.topics li a:after {
  display: inline-block;
  content: "";
  background-color: var(--black);
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 5px;
  left: 0;
}
.product-container .product-wrapper .product-row .left-col .share-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: #EDEDED;
  padding: 20px;
}
@media screen and (min-width: 992px) {
  .product-container .product-wrapper .product-row .left-col .share-section {
    display: none;
  }
}
.product-container .product-wrapper .product-row .left-col .share-section--text {
  font-size: 16px;
  font-weight: 600;
}
.product-container .product-wrapper .product-row .left-col .share-section--fb {
  width: 8px;
}
.product-container .product-wrapper .product-row .left-col .share-section--fb svg {
  fill: #1B1B1B;
}
.product-container .product-wrapper .product-row .left-col .share-section--linkedin {
  width: 16px;
}
.product-container .product-wrapper .product-row .left-col .share-section--linkedin svg {
  fill: #1B1B1B;
}
.product-container .product-wrapper .product-row .left-col .share-section span {
  display: inline-block;
}
.product-container .product-wrapper .product-row .left-col .share-section span:not(:last-child) {
  margin-right: 15px;
}
.product-container .product-wrapper .product-row .left-col .share-section .fb-share {
  margin-right: 15px;
}
.product-container .product-wrapper .product-row .right-col {
  width: 100%;
  max-width: max(360px, 29.6875vw);
  margin-top: -350px;
}
@media screen and (max-width: 991px) {
  .product-container .product-wrapper .product-row .right-col {
    margin-top: 20px;
    max-width: 570px;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box {
  position: sticky;
  top: 0;
  padding: 1.8229166667vw 1.5625vw 1.5625vw;
  background: var(--white);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.14);
}
@media screen and (max-width: 580px) {
  .product-container .product-wrapper .product-row .right-col .right-box {
    position: unset;
    padding: 30px 10px;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
  background-color: var(--gray-light);
}
@media screen and (max-width: 580px) {
  .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading {
    background-color: var(--white);
    display: block;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading:after {
  display: flex;
  content: "";
  width: 100%;
  height: 8px;
  background-color: var(--gray);
  position: absolute;
  left: 0;
  bottom: 0;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item {
  display: flex;
  align-items: center;
  padding: 22px 12px;
  color: var(--black-light);
  border: 1px solid var(--red);
  border-bottom: 8px solid var(--red);
  opacity: 0.5;
}
@media screen and (max-width: 420px) {
  .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item {
    padding: 15px;
    border-bottom: 4px solid var(--red);
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item:hover, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item:focus, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item:active {
  color: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item.active {
  background-color: var(--white);
  z-index: 1;
  opacity: 1;
}
@media screen and (max-width: 420px) {
  .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--item.active {
    background-color: var(--gray-light);
    border: none;
    border-bottom: 8px solid var(--red);
    display: flex;
    flex-direction: column;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--icon {
  display: inline-flex;
  margin-right: 5px;
}
@media screen and (max-width: 420px) {
  .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--icon {
    margin-bottom: 10px;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--icon svg {
  display: inline-flex;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--icon svg path {
  fill: var(--black-light) !important;
}
@media screen and (max-width: 420px) {
  .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__heading--title {
    font-size: 14px;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--gray-light);
  padding: 4px;
  margin-bottom: 6px;
  color: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__info--price {
  display: flex;
  align-items: center;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__info--price li:not(:last-child) {
  margin-right: 10px;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__info--price li .product-price.old {
  text-decoration: line-through;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__advantages {
  display: none;
  background-color: var(--gray-light);
  padding: 25px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__advantages h3 {
  margin-bottom: 15px;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__advantages ul {
  padding-left: 20px;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item .package__advantages ul li {
  list-style-type: disc;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item.active, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item:hover, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item:focus {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-color: var(--red);
  color: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item.active .package__info--cta, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item:hover .package__info--cta, .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item:focus .package__info--cta {
  opacity: 1;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__content--item.active .package__advantages {
  display: block;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
@media screen and (max-width: 991px) {
  .product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share {
    display: none;
  }
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share--text {
  font-size: 16px;
  font-weight: 600;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share span.tab__share--text {
  margin-right: 15px;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share a span {
  display: inline-flex;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share a span svg {
  display: inline-flex;
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share a span svg .st0 {
  fill: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box .product__tabs .tab__share a.fb-share {
  margin-right: 15px;
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle {
  background: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__heading {
  background-color: transparent;
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__heading--icon svg path {
  fill: var(--white) !important;
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__heading--item {
  color: var(--white);
  width: 100%;
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__heading--item.active {
  background-color: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__content--item .package__info {
  color: var(--white);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__content--item .package__info--cta {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--red);
  color: var(--white);
  border-color: var(--red);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__content--item .package__info--cta:hover {
  background-color: var(--black-light);
  color: var(--white);
  border-color: var(--black-light);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__content--item .package__info--cta:hover, .product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__content--item .package__info--cta:focus, .product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__content--item .package__info--cta:active {
  border-color: var(--red);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__share {
  color: var(--white);
}
.product-container .product-wrapper .product-row .right-col .right-box.product-bundle .product__tabs .tab__share a span svg .st0 {
  fill: var(--white);
}

.related--events {
  padding-top: max(60px, 5.9895833333vw);
}
.related--events h3 {
  border-bottom: 1px solid var(--black-light);
  line-height: 2;
  margin-left: max(30px, 4.9479166667vw);
  margin-right: max(30px, 4.9479166667vw);
  margin-bottom: 50px;
}
@media screen and (max-width: 991px) {
  .related--events h3 {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 580px) {
  .related--events h3 {
    text-align: center;
    font-size: 24px;
    max-width: 490px;
  }
}
.related--events .relatedEvents {
  padding-left: max(30px, 4.9479166667vw);
  padding-right: max(30px, 4.9479166667vw);
}
@media screen and (max-width: 991px) {
  .related--events .relatedEvents {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 580px) {
  .related--events .relatedEvents {
    padding-left: 0;
    padding-right: 0;
    max-width: 490px;
  }
}
.related--events .relatedEvents .related-events-arrows {
  display: flex;
  justify-content: space-between;
  top: 38%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 991px) {
  .related--events .relatedEvents .related-events-arrows {
    position: relative;
    justify-content: center;
    top: unset;
    margin-top: 40px;
  }
}
.related--events .relatedEvents .related-events-arrows .related-events-arrow {
  display: inline-flex;
  border: 1px solid var(--black-light);
  border-radius: 4px;
  padding: max(17px, 1.1458333333vw);
  z-index: 9;
}
.related--events .relatedEvents .related-events-arrows .related-events-arrow svg {
  display: flex;
  height: 15px;
  width: 9px;
}
@media screen and (max-width: 991px) {
  .related--events .relatedEvents .related-events-arrows .related-events-arrow svg {
    height: 12px;
    width: 6px;
  }
}
@media screen and (max-width: 991px) {
  .related--events .relatedEvents .related-events-arrows .related-events-arrow.prev {
    margin-right: 24px;
  }
}
.related--events .relatedEvents .related-events-arrows .related-events-arrow.swiper-button-disabled {
  opacity: 0.5;
}

.related--products {
  margin-top: max(60px, 3.90625vw);
}
@media screen and (max-width: 580px) {
  .related--products {
    max-width: 490px;
  }
}
.related--products h3 {
  margin-bottom: 50px;
  border-bottom: 1px solid var(--black-light);
  line-height: 2;
}
@media screen and (max-width: 580px) {
  .related--products h3 {
    text-align: center;
    line-height: 29.23px;
    font-size: 24px;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
}
.related--products--wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .related--products--wrapper {
    justify-content: space-between;
  }
}
@media screen and (max-width: 580px) {
  .related--products--wrapper {
    flex-wrap: nowrap;
  }
}
.related--products--wrapper .product {
  flex-basis: calc(50% - 15px);
  min-width: unset;
  max-width: 100%;
  margin-bottom: 60px;
  margin-right: 30px;
}
@media screen and (max-width: 1280px) {
  .related--products--wrapper .product {
    flex-basis: calc(50% - 8px);
    margin-right: 16px;
  }
}
@media screen and (max-width: 580px) {
  .related--products--wrapper .product {
    flex-basis: 100%;
    margin-right: unset;
    margin-bottom: 40px;
  }
}
.related--products--wrapper .product:nth-child(2n+2) {
  margin-right: 0;
}
.related--products--wrapper .product__tags {
  flex-wrap: wrap;
}
.related--products .related-products-arrows {
  display: none;
}
@media screen and (max-width: 580px) {
  .related--products .related-products-arrows {
    display: flex;
    justify-content: center;
  }
}
.related--products .related-products-arrows .related-products-arrow {
  display: inline-flex;
  border: 1px solid var(--black-light);
  border-radius: 4px;
  padding: max(17px, 1.1458333333vw);
}
.related--products .related-products-arrows .related-products-arrow svg {
  display: flex;
  height: 12px;
  width: 6px;
}
.related--products .related-products-arrows .related-products-arrow.prev {
  margin-right: 24px;
}
.related--products .related-products-arrows .related-products-arrow.swiper-button-disabled {
  opacity: 0.5;
}

.notification {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 720px;
  padding: 5px 10px 5px 5px;
  background-color: var(--black-light);
  color: var(--white);
  border-radius: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 580px) {
  .notification {
    align-items: flex-start;
  }
}
.notification span.notification-icon {
  width: 27px;
  height: 27px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--red);
  border-radius: 50%;
}
.notification span.notification-icon svg {
  display: inline-flex;
  width: 3px;
  height: 14px;
}
.notification .notification-text {
  width: 100%;
  font-size: 13px;
  padding-left: 10px;
}
.notification .notification-text a {
  font-weight: 600;
}
.notification .notification-text a:hover, .notification .notification-text a:focus, .notification .notification-text a:active {
  color: var(--white);
}

.product__info {
  background-color: var(--gray-light);
  padding: 50px max(10px, 5.9895833333vw) max(40px, 2.6041666667vw);
}
@media screen and (min-width: 992px) {
  .product__info {
    min-height: 342px;
  }
}
@media screen and (max-width: 580px) {
  .product__info {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.product__info p:not(:last-child) {
  margin-bottom: 0;
}
.product__info-wrapper {
  width: 100%;
  max-width: 1465px;
  margin: 0 auto;
}
.product__info-wrapper-inner {
  width: 100%;
  max-width: 825px;
  margin-right: 75px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1480px) {
  .product__info-wrapper-inner {
    max-width: 55.7432432432vw;
  }
}
@media screen and (max-width: 1366px) {
  .product__info-wrapper-inner {
    max-width: 52.7086383602vw;
  }
}
@media screen and (max-width: 1280px) {
  .product__info-wrapper-inner h2 {
    font-size: 32px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 991px) {
  .product__info-wrapper-inner {
    max-width: 100%;
    margin-right: unset;
  }
}
@media screen and (max-width: 580px) {
  .product__info-wrapper-inner h2 {
    text-align: center;
  }
  .product__info-wrapper-inner .product__tags {
    display: flex;
    justify-content: center;
    margin: 15px 0 30px;
    font-size: 14px;
  }
  .product__info-wrapper-inner .commercial_message {
    text-align: center;
  }
}
.product__info-wrapper > * {
  order: 4;
}
@media screen and (max-width: 991px) {
  .product__info-wrapper > *.breadcrumb {
    display: none;
  }
  .product__info-wrapper > *.product-title {
    order: 1;
  }
  .product__info-wrapper > *.product__tags {
    order: 2;
  }
}
.product__info-wrapper .commercial_message {
  margin: 10px 0;
}

.desktop {
  display: block;
}
@media screen and (max-width: 991px) {
  .desktop {
    display: none;
  }
}

.tablet {
  display: none;
}
@media screen and (max-width: 991px) {
  .tablet {
    display: block;
  }
}

.desktop-custom {
  display: block;
}
@media screen and (max-width: 1580px) {
  .desktop-custom {
    display: none;
  }
}

.tablet-custom {
  display: none;
}
@media screen and (max-width: 1580px) {
  .tablet-custom {
    display: block;
  }
}

/*add to cart message*/
@keyframes note {
  0% {
    margin-top: 25px;
    opacity: 0;
  }
  10% {
    margin-top: 0;
    opacity: 1;
  }
  90% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 25px;
    opacity: 0;
  }
}
.contact-container .contact-title {
  text-align: center;
  margin: max(35px, 7.03125vw) auto max(55px, 4.6875vw);
}
@media screen and (max-width: 767px) {
  .contact-container .contact-title {
    font-family: var(--font-default), sans-serif;
    font-size: max(32px, 1.6666666667vw);
    font-weight: 700;
    font-style: normal;
    line-height: 38.98px;
  }
}
.contact-container .contact-box-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 4.1666666667vw;
}
@media screen and (max-width: 991px) {
  .contact-container .contact-box-container {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .contact-container .contact-box-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
}
.contact-container .contact-box-container .contact-box {
  border: 1px solid var(--black);
  width: 100%;
  max-width: 357px;
  padding: max(30px, 2.6041666667vw) max(30px, 2.0833333333vw) max(30px, 2.6041666667vw);
}
@media screen and (max-width: 767px) {
  .contact-container .contact-box-container .contact-box {
    padding: 30px;
  }
  .contact-container .contact-box-container .contact-box:first-child {
    margin-bottom: 20px;
  }
}
.contact-container .contact-box-container .contact-box .line {
  background-color: #CF413D;
  height: 6px;
  width: 100px;
  margin-bottom: max(20px, 1.3020833333vw);
}
.contact-container .contact-box-container .contact-box-title {
  margin-bottom: max(20px, 1.3020833333vw);
}
.contact-container .contact-box-container .contact-box-details {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: normal;
  font-style: normal;
  line-height: max(21px, 1.25vw);
}
.contact-container .contact-box-container .contact-box-details-mail, .contact-container .contact-box-container .contact-box-details-phone {
  display: flex;
}
.contact-container .contact-box-container .contact-box-details-mail span:first-child, .contact-container .contact-box-container .contact-box-details-phone span:first-child {
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .contact-container .contact-box-container .contact-box-details-mail span:first-child, .contact-container .contact-box-container .contact-box-details-phone span:first-child {
    margin-bottom: 10px;
  }
}
.contact-container .contact-box-container .contact-box-details .icon {
  display: flex;
  width: 15px;
}
.contact-container .contact-box-container .contact-box-details-phone .contact-telephones a:hover, .contact-container .contact-box-container .contact-box-details-phone .contact-telephones a:visited, .contact-container .contact-box-container .contact-box-details-phone .contact-telephones a:focus {
  color: unset;
}
.contact-container .contact-box-container .contact-box-details p {
  margin: unset;
}
.contact-container .contact-form-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .contact-container .contact-form-container {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container {
    padding-top: 40px;
    background-color: #F4F5F6;
  }
}
.contact-container .contact-form-container form input {
  margin-bottom: 20px;
  padding: 20px 30px;
  border-radius: 0;
  border: 1px solid #1B1B1B;
  width: 100%;
  font-size: max(14px, 0.8333333333vw);
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container form input {
    font-size: 14px;
  }
}
.contact-container .contact-form-container form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: normal;
}
.contact-container .contact-form-container form input::-moz-placeholder { /* Firefox 19+ */
  font-style: normal;
}
.contact-container .contact-form-container form input:-ms-input-placeholder { /* IE 10+ */
  font-style: normal;
}
.contact-container .contact-form-container form input:-moz-placeholder { /* Firefox 18- */
  font-style: normal;
}
.contact-container .contact-form-container form input[type=submit] {
  margin-bottom: max(55px, 5.46875vw);
  display: flex;
  justify-content: center;
  background-color: var(--black);
  color: var(--white);
}
.contact-container .contact-form-container form input[type=submit]:hover {
  background-color: var(--white);
  color: var(--black);
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container form input[type=submit] {
    font-size: 18px;
  }
}
.contact-container .contact-form-container form textarea {
  width: 100%;
  height: 165px;
  border: 1px solid var(--black);
  padding: max(18.5px, 0.9635416667vw) max(20px, 1.4583333333vw);
  margin-bottom: 25px;
}
.contact-container .contact-form-container form textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: normal;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container form textarea::-webkit-input-placeholder {
    font-size: 14px;
  }
}
.contact-container .contact-form-container form textarea::-moz-placeholder { /* Firefox 19+ */
  font-style: normal;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container form textarea::-moz-placeholder {
    font-size: 14px;
  }
}
.contact-container .contact-form-container form textarea:-ms-input-placeholder { /* IE 10+ */
  font-style: normal;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container form textarea:-ms-input-placeholder {
    font-size: 14px;
  }
}
.contact-container .contact-form-container form textarea:-moz-placeholder { /* Firefox 18- */
  font-style: normal;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .contact-container .contact-form-container form textarea:-moz-placeholder {
    font-size: 14px;
  }
}
.contact-container .map-section {
  background-color: #F4F5F6;
}
.contact-container .map-section .map-container {
  width: 100%;
  max-width: 986px;
  margin: 0 auto;
  padding: max(0px, 4.84375vw) 0;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}
@media screen and (max-width: 767px) {
  .contact-container .map-section .map-container {
    padding: 0;
  }
}
.contact-container .map-section .map-container iframe {
  width: 100%;
  height: max(433px, 27.8125vw);
}

.category-list__search {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: max(20px, 1.0416666667vw);
  margin-bottom: max(20px, 1.0416666667vw);
  padding-right: 0;
  padding-left: 0;
}
.category-list__search-input {
  margin-bottom: 1em;
  flex: 0 1 100%;
  order: 3;
}
@media screen and (min-width: 767px) {
  .category-list__search-input {
    flex: 0 1 33.333%;
  }
}
.category-list__search-input:first-child {
  order: 1;
}
.category-list__search-input--last {
  flex: 0 1 100%;
  order: 2;
}
@media screen and (min-width: 767px) {
  .category-list__search-input--last {
    order: 3;
  }
}
.category-list .filter-with-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.category-list .filter-with-label label {
  padding-right: 1em;
}
.category-list #button-search {
  background-color: var(--black);
}
.category-list #button-search:hover {
  background-color: var(--white);
}

#information-information #content {
  padding-top: max(30px, 7.03125vw);
}
#information-information #content h1 {
  text-align: center;
  margin-bottom: max(15px, 1.3020833333vw);
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 1.5625vw);
  font-weight: 700;
  font-style: normal;
  line-height: max(36.54px, 1.903125vw);
}
@media screen and (max-width: 767px) {
  #information-information #content h1 {
    font-size: 32px;
    line-height: 38.98px;
  }
}
#information-information #content h4 {
  text-align: center;
  margin-bottom: max(45px, 5.2083333333vw);
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 1.0416666667vw);
  font-weight: 600;
  font-style: normal;
  line-height: max(21.92px, 1.26875vw);
}
@media screen and (max-width: 767px) {
  #information-information #content h4 {
    font-size: 18px;
    line-height: 21.92px;
  }
}
#information-information #content .content-section {
  background-color: #f4f5f6;
  padding: 0 25px;
}
#information-information #content .content-section .content-container {
  width: 100%;
  max-width: 945px;
  margin: 0 auto;
  padding: max(50px, 6.5104166667vw) 0 max(90px, 13.8020833333vw);
  text-align: justify;
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 1.0416666667vw);
  font-weight: normal;
  font-style: normal;
  line-height: max(30px, 1.7708333333vw);
}
@media screen and (max-width: 767px) {
  #information-information #content .content-section .content-container {
    font-size: 14px;
    line-height: 21px;
  }
}
#information-information #content .content-section .content-container .unsub-description {
  text-align: center;
  margin: 0 0 60px;
}
#information-information #content .content-section .content-container .unsubscribe-form {
  max-width: 520px;
  margin: 0 auto;
}
#information-information #content .content-section .content-container .unsubscribe-form .cta__unsubscribe {
  color: var(--black-light);
  background-color: var(--red);
  display: block;
  margin: 40px auto 0;
}
#information-information #content .content-section .content-container .unsubscribe-form .cta__unsubscribe:hover {
  background-color: var(--red);
  color: var(--black-light);
  border-color: var(--gray);
  box-shadow: 0 0 30px -10px var(--red);
}
#information-information #content a {
  color: #ff0035;
}

.homepage .marketing-bar {
  background-color: var(--gray-lighter);
}
.homepage .marketing-bar__wrap {
  padding: 35px var(--spacing-sides);
}
@media (max-width: 580px) {
  .homepage .marketing-bar__wrap {
    padding-right: unset;
  }
}
.homepage .marketing-bar__content {
  display: flex;
  flex-direction: column;
}
.homepage .product-block.theme--gray {
  background-color: var(--gray-lighter);
}
.homepage .product-block.theme--gray .product-block__wrap {
  padding-bottom: max(55px, 4.0625vw);
}
.homepage .product-block.spacing--small .product-block__wrap {
  padding-bottom: max(55px, 4.0625vw);
}
.homepage .product-block__wrap {
  padding-top: max(25px, 3.28125vw);
  padding-bottom: max(55px, 6.875vw);
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}
.homepage .product-block__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: max(32px, 3.125vw);
  border-bottom: 1px solid var(--black-light);
}
@media screen and (max-width: 767px) {
  .homepage .product-block__header {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.homepage .product-block__header--title {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .homepage .product-block__header--title {
    padding-top: unset;
    padding-bottom: 7px;
  }
}
@media screen and (max-width: 767px) {
  .homepage .product-block__header--cta {
    padding-bottom: 15px;
  }
}
.homepage .product-block__content {
  display: flex;
  flex-direction: column;
}

.search-bar__content {
  height: 100%;
  min-height: 400px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--search-bg);
  background-position: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .search-bar__content {
    background-position: 85%;
  }
}
@media screen and (max-width: 420px) {
  .search-bar__content {
    background-image: var(--search-bg-mob);
    background-position: top center;
  }
}
.search-bar__content .form {
  display: flex;
  width: 100%;
}
.search-bar__content .form-wrap {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: var(--white);
  box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 580px) {
  .search-bar__content .form-wrap {
    width: max(332px, 74.4827586207vw);
    padding: unset;
    margin-top: 126px;
  }
}
.search-bar__content .form input {
  width: 100%;
  min-width: max(240px, 18.4895833333vw);
  margin-right: 8px;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media screen and (max-width: 580px) {
  .search-bar__content .form input {
    min-width: 100%;
    margin-right: unset;
    padding: 22px;
  }
}
.search-bar__content .form input::placeholder {
  color: var(--gray);
}
.search-bar__content .form button {
  margin-right: 23px;
}
@media screen and (max-width: 580px) {
  .search-bar__content .form button {
    display: none;
  }
}
.search-bar__content .filters-industries {
  cursor: pointer;
  display: flex;
}
@media screen and (max-width: 580px) {
  .search-bar__content .filters-industries {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 12px;
    width: 100%;
    border: 1px solid var(--red);
    padding: 22px;
    background-color: var(--red);
  }
}
.search-bar__content .filters-industries--icon {
  display: inline-flex;
  margin-right: 10px;
}
.search-bar__content .filters-industries--icon svg path {
  fill: var(--black);
}
@media screen and (max-width: 580px) {
  .search-bar__content .filters-industries--icon svg path {
    fill: var(--white);
  }
}
.search-bar__content .filters-industries--title {
  display: inline-flex;
  align-items: center;
}
@media screen and (max-width: 580px) {
  .search-bar__content .filters-industries--title {
    width: 100%;
    justify-content: space-between;
    color: var(--white);
  }
}
.search-bar__content .filters-industries--title:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.18684 7.33106L9.41642 3.05178C9.46858 2.99944 9.50998 2.93716 9.53823 2.86855C9.56649 2.79993 9.58103 2.72634 9.58103 2.65201C9.58103 2.57767 9.56649 2.50408 9.53823 2.43546C9.50998 2.36685 9.46858 2.30457 9.41642 2.25223C9.31002 2.14495 9.16593 2.08472 9.01572 2.08472C8.86551 2.08472 8.72142 2.14495 8.61502 2.25223L4.78614 6.1261L0.957259 2.25223C0.905523 2.19946 0.843971 2.15757 0.776153 2.12898C0.708336 2.10039 0.635595 2.08568 0.562128 2.08568C0.48866 2.08568 0.415919 2.10039 0.348102 2.12898C0.280284 2.15757 0.218732 2.19946 0.166996 2.25223C0.113528 2.30251 0.0710517 2.36355 0.0422978 2.43143C0.0135439 2.49931 -0.000850922 2.57253 3.88723e-05 2.64637C0.000407337 2.79855 0.0604554 2.94436 0.166996 3.05178L4.45223 7.33106C4.55566 7.43614 4.69525 7.49667 4.84179 7.49998C4.97322 7.48777 5.09581 7.42775 5.18684 7.33106V7.33106Z' fill='%231B1B1B'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  margin-left: 14px;
  margin-right: 15px;
}
@media screen and (max-width: 580px) {
  .search-bar__content .filters-industries--title:after {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.18678 7.33106L9.41635 3.05178C9.46852 2.99944 9.50992 2.93716 9.53817 2.86855C9.56643 2.79993 9.58097 2.72634 9.58097 2.65201C9.58097 2.57767 9.56643 2.50408 9.53817 2.43546C9.50992 2.36685 9.46852 2.30457 9.41635 2.25223C9.30996 2.14495 9.16587 2.08472 9.01566 2.08472C8.86545 2.08472 8.72136 2.14495 8.61496 2.25223L4.78608 6.1261L0.957198 2.25223C0.905462 2.19946 0.84391 2.15757 0.776092 2.12898C0.708275 2.10039 0.635534 2.08568 0.562067 2.08568C0.488599 2.08568 0.415858 2.10039 0.348041 2.12898C0.280223 2.15757 0.218671 2.19946 0.166935 2.25223C0.113467 2.30251 0.0709907 2.36355 0.0422368 2.43143C0.0134829 2.49931 -0.000911957 2.57253 -2.21629e-05 2.64637C0.000346302 2.79855 0.0603943 2.94436 0.166935 3.05178L4.45217 7.33106C4.5556 7.43614 4.69519 7.49667 4.84173 7.49998C4.97316 7.48777 5.09575 7.42775 5.18678 7.33106V7.33106Z' fill='white'/%3E%3C/svg%3E");
  }
}

@media screen and (max-width: 580px) {
  .product-profile {
    width: 100%;
  }
  .product-profile:first-child {
    margin-top: 30px;
  }
}
.product-profile__block {
  display: flex;
  flex-wrap: wrap;
  max-width: 540px;
  justify-content: space-between;
}
@media screen and (max-width: 580px) {
  .product-profile__block {
    max-width: unset;
    width: 100%;
  }
}
.product-profile__wrap {
  max-width: 259px;
  display: flex;
  margin-bottom: 39px;
}
@media screen and (max-width: 580px) {
  .product-profile__wrap {
    padding: 50px 32px;
    border-bottom: 1px solid var(--gray-light);
    margin-bottom: unset;
    max-width: unset;
  }
}
.product-profile__image {
  width: 100%;
  max-width: 58px;
  height: 58px;
  border-radius: 58px;
  overflow: hidden;
}
@media screen and (max-width: 580px) {
  .product-profile__image {
    max-width: 79px;
    height: 79px;
    border-radius: 79px;
  }
}
.product-profile__content {
  max-width: 184px;
  min-width: 184px;
  margin-left: 17px;
}
@media screen and (max-width: 580px) {
  .product-profile__content {
    max-width: 100%;
    min-width: unset;
  }
}
.product-profile__content--title {
  margin-bottom: 7px;
  margin-top: -5px;
}
.product-profile__content--tags {
  color: var(--gray-dark);
}

.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(var(--screen-height) - var(--header-height));
}
.page-404__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 910px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}
.page-404__wrap > * {
  color: var(--gray);
}
.page-404__wrap > *:not(:last-child) {
  margin-bottom: max(15px, 1.5625vw);
}
.page-404__logo-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: inline-block;
}
.page-404__logo-container svg {
  display: inline-block;
}
.page-404__logo-container svg path {
  fill: #000;
}
.page-404__title {
  color: var(--red);
}
.page-404__cta > a {
  color: var(--black-light);
  background-color: var(--red);
  color: var(--black-light);
}
.page-404__cta > a:hover {
  background-color: var(--red);
  color: var(--black-light);
  border-color: var(--gray);
  box-shadow: 0 0 30px -10px var(--red);
}

.success .category-list__header {
  justify-content: center;
}
.success__content {
  margin-top: max(20px, 3.6458333333vw);
  margin-bottom: max(80px, 10.15625vw);
  text-align: center;
}

.hidden-tablet {
  display: none;
}
@media screen and (min-width: 1024px) {
  .hidden-tablet {
    display: block;
  }
}

.hidden-desktop {
  display: block;
}
@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none;
  }
}

.menu-toggle {
  cursor: pointer;
  width: max(36px, 1.875vw);
  height: max(24px, 1.25vw);
  position: relative;
  z-index: 1;
  display: block;
}
@media screen and (min-width: 1024px) {
  .menu-toggle {
    display: none;
  }
}
.menu-toggle .icon-bar {
  display: block;
  position: absolute;
  width: 100%;
  height: max(2px, 0.1041666667vw);
  border-radius: max(1px, 0.0520833333vw);
  background: var(--black);
  transition: all 0.3s ease-in-out;
}
.menu-toggle .icon-bar:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.menu-toggle .icon-bar:nth-child(3) {
  top: 100%;
  transform: translateY(-100%);
}
.menu-toggle.active .icon-bar:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}
.menu-toggle.active .icon-bar:nth-child(2) {
  opacity: 0;
}
.menu-toggle.active .icon-bar:nth-child(3) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.megamenu .sub-menu::before, .megamenu-wrapper::before {
  content: "";
  height: var(--header-height);
  background: linear-gradient(to bottom, var(--gray-light) 0%, var(--gray-light) 70%, rgba(0, 0, 0, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: 2;
}
@media (min-width: 1024px) {
  .megamenu .sub-menu::before, .megamenu-wrapper::before {
    display: none;
  }
}

.megamenu-wrapper {
  display: none;
}
.megamenu-wrapper.so-megamenu-active {
  display: block;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gray-light);
  padding: var(--header-height) max(33px, 9.1666666667vw) max(45px, 12.5vw);
}
@media screen and (min-width: 1024px) {
  .megamenu-wrapper {
    display: block;
  }
  .megamenu-wrapper.so-megamenu-active {
    position: static;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background: initial;
    padding: 0;
  }
}

.megamenu {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (min-width: 1024px) {
  .megamenu {
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
}
.megamenu > li {
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .megamenu > li {
    margin: 0 0.6825em;
  }
}
.megamenu li {
  margin-top: 0.833em;
}
@media screen and (min-width: 1024px) {
  .megamenu li {
    margin-top: 0;
  }
}
.megamenu .sub-menu {
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  left: 100%;
  top: 0;
  height: 100%;
  background: var(--gray-light);
  z-index: 9;
  padding: var(--header-height) max(33px, 9.1666666667vw) max(45px, 12.5vw);
}
@media screen and (min-width: 1024px) {
  .megamenu .sub-menu {
    top: 200px;
    left: 0;
    height: auto;
    padding: 60px 0;
    overflow: initial;
    display: none;
  }
}
.megamenu .sub-menu__back {
  margin-top: 0.833em;
  padding-left: 1em;
  cursor: pointer;
}
.megamenu .sub-menu__back .icon {
  position: absolute;
  width: 0.591em;
  height: 0.363em;
  background-image: url(../../../image/menu-chevron.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
@media screen and (min-width: 1024px) {
  .megamenu .sub-menu__back {
    display: none;
  }
}
.megamenu .sub-menu__arrow {
  content: "";
  width: 0.7275em;
  height: 0.7275em;
  background: var(--gray-light);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.megamenu .sub-menu .content {
  margin: auto;
}
@media screen and (min-width: 1024px) {
  .megamenu .sub-menu .content {
    width: 48%;
  }
}
@media screen and (max-width: 1024px) {
  .megamenu .sub-menu .content .col-sm-4 {
    width: 100%;
  }
}
.megamenu .sub-menu .content .product {
  max-width: 100%;
  min-width: initial;
  margin-top: 0.833em;
}
@media screen and (max-width: 1024px) {
  .megamenu .sub-menu .content .product {
    margin-top: 0.6em;
  }
}
.megamenu > .with-sub-menu > a {
  display: block;
  padding-right: 1.41em;
}
.megamenu__chevron {
  display: block;
  width: 0.591em;
  height: 0.363em;
  background-image: url(../../../image/menu-chevron.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
}
@media screen and (min-width: 1024px) {
  .megamenu__chevron {
    transform: translateY(-50%);
  }
}

.hero-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.hero-slider .slide-info {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: var(--white);
}
@media (max-width: 580px) {
  .hero-slider .slide-info {
    top: unset;
    transform: unset;
    bottom: 10%;
  }
}
.hero-slider .slide-info .super-hero,
.hero-slider .slide-info .hero-btn {
  font-weight: 600;
  text-transform: uppercase;
}
.hero-slider .slide-info .hero-btn {
  position: relative;
}
.hero-slider .slide-info .hero-btn:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 1px;
  background-color: var(--white);
}
.hero-slider .slide-info .hero-title {
  margin: 25px 0 40px;
}
.hero-slider .swiper-slide img {
  margin: 0 auto;
}
.hero-slider .hero-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  max-width: 22px;
}
@media (max-width: 580px) {
  .hero-slider .hero-arrow {
    max-width: 15px;
  }
}
.hero-slider .hero-arrow.hero-button-prev {
  left: 2%;
}
.hero-slider .hero-arrow.hero-button-next {
  right: 2%;
}

.discount-bar {
  text-align: center;
  padding: 10px 15px;
}