.hidden-tablet {
  display: none;
  @media screen and (min-width: $tablet-lg) {
    display: block;
  }
}
.hidden-desktop {
  display: block;
  @media screen and (min-width: $tablet-lg) {
    display: none;
  }
}
.menu-toggle {
  cursor: pointer;
  width: max(36px, vw(36px));
  height: max(24px, vw(24px));
  position: relative;
  z-index: 1;
  display: block;
  @media screen and (min-width: $tablet-lg) {
    display: none;
  }
  .icon-bar {
    display: block;
    position: absolute;
    width: 100%;
    height: max(2px, vw(2px));
    border-radius:max(1px, vw(1px));
    background: $black;
    transition: all 0.3s ease-in-out;
    &:nth-child(2) {
      top: 50%;
      transform: translate(0, -50%);
    }
    &:nth-child(3) {
      top: 100%;
      transform: translateY(-100%);
    }
  }
  &.active {
    .icon-bar {
      &:nth-child(1) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}
%mobile-menu-header-fade {
  &::before {
    content: "";
    height: var(--header-height);
    background: linear-gradient(to bottom, $gray-light 0%, $gray-light 70%, rgba(#000, 0%) 100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: 2;
    @media (min-width: $tablet-lg) {
      display: none;
    }
  }
}
.megamenu-wrapper {
  @extend %mobile-menu-header-fade;
  display: none;
  &.so-megamenu-active {
    display: block;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gray-light);
    padding: var(--header-height) max(33px, vw-rs(360, 33px)) max(45px, vw-rs(360, 45px));
  }
  @media screen and (min-width: $tablet-lg) {
    display: block;
    &.so-megamenu-active {
      position: static;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      background: initial;
      padding: 0;
    }
  }
}
.megamenu {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: $tablet-lg) {
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  > li {
    margin: 0;
    @media screen and (min-width: $tablet-lg) {
      margin: 0 0.6825em;
    }
  }
  li {
    margin-top: 0.833em;
    @media screen and (min-width: $tablet-lg) {
      margin-top: 0;
    }
  }
  .sub-menu {
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    left: 100%;
    top: 0;
    height: 100%;
    background: var(--gray-light);
    z-index: 9;
    padding: var(--header-height) max(33px, vw-rs(360, 33px)) max(45px, vw-rs(360, 45px));
    @extend %mobile-menu-header-fade;
    @media screen and (min-width: $tablet-lg) {
      top: 200px;
      left: 0;
      height: auto;
      padding: 60px 0;
      overflow: initial;
      display: none;
    }
    &__back {
      margin-top: 0.833em;
      padding-left: 1em;
      cursor: pointer;
      .icon {
        position: absolute;
        width: 0.591em;
        height: 0.363em;
        background-image: url(../../../image/menu-chevron.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
      }
      @media screen and (min-width: $tablet-lg) {
        display: none;
      }
    }
    &__arrow {
      content: "";
      width: .7275em;
      height: .7275em;
      background: var(--gray-light);
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
    .content {
      margin: auto;
      @media screen and (min-width: $tablet-lg) {
        width: 48%;
      }
      .col-sm-4 {
        @media screen and (max-width: $tablet-lg) {
          width: 100%;
        }
      }
      .product {
        max-width: 100%;
        min-width: initial;
        margin-top: 0.833em;
        @media screen and (max-width: $tablet-lg) {
          margin-top: 0.6em;
        }
      }
    }
  }
  > .with-sub-menu {
    > a {
      display: block;
      padding-right: 1.41em;
    }
  }
  &__chevron {
    display: block;
    width: 0.591em;
    height: 0.363em;
    background-image: url(../../../image/menu-chevron.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(-90deg);
    @media screen and (min-width: $tablet-lg) {
      transform: translateY(-50%);
    }
  }
}