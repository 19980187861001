%site-container {
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(var(--container-gutter) / 2);
  padding-left: calc(var(--container-gutter) / 2);
  @media (min-width: 1200px) {
    width: calc(87.5% + var(--container-gutter));
  }
}

%site-container-inner {
  margin-right: calc(-1 * var(--container-gutter) / 2);
  margin-left: calc(-1 * var(--container-gutter) / 2);
}

%site-container-children {
  padding-right: calc(var(--container-gutter) / 2);
  padding-left: calc(var(--container-gutter) / 2);
}