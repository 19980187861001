$kTabs: '.kTabs';
$kTabs-trigger-current-color: $black-light;
$kTabs-trigger-line-color: $kTabs-trigger-current-color;

#{$kTabs} {
    &__wrap {
        @include transition;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 15px);
        background: $white;
        display: flex;
        z-index: 2;
        min-width: 956px;
        box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.25);

        @media screen and (max-width: $tablet-lg) {
            min-width: 100%;
            position: fixed;
            z-index: 999;
            width: 100%;
            top: 0;
            left: 0;
            transform: unset;
            height: var(--screen-height);
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @media screen and (max-width: $mobile) {
            padding: unset;
        }

        .close--filters {
            position: absolute;
            top: 0;
            right: 0;
            margin: 20px;
            cursor: pointer;
            z-index: 2;

            &:before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23A6A8AA' d='M12.786.37.586 12.571a.524.524 0 1 0 .74.741l12.2-12.2a.524.524 0 0 0-.74-.742Z'/%3E%3Cpath fill='%23A6A8AA' d='M13.527 12.571 1.326.371a.524.524 0 1 0-.74.74l12.2 12.201a.524.524 0 1 0 .74-.74Z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 14px;
                width: 14px;
                height: 14px;
                display: flex;

                @media screen and (max-width: $mobile) {
                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%231B1B1B' d='M16.439.476.752 16.163a.674.674 0 0 0 .953.953L17.39 1.429a.673.673 0 0 0-.952-.953Z' /%3E%3Cpath fill='%231B1B1B' d='M17.391 16.163 1.705.476a.673.673 0 1 0-.953.953L16.44 17.115a.673.673 0 0 0 .952-.952Z' /%3E%3C/svg%3E");                    
                }
            }
        }

        &.non-visible {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__side {
        &-a {
            margin-left: 37px;
            margin-right: 28px;
            margin-top: 49px;
            margin-bottom: 42px;
            width: 100%;
            max-width: 305px;

            @media screen and (max-width: $tablet-lg) {
                margin: unset;
                padding-left: 30px;
                padding-right: 30px;
                height: 100%;
                overflow-y: auto;
                direction: rtl;
            }

            @media screen and (max-width: $mobile) {
                max-width: 100%;
                padding: unset;
            }
        }

        &-b {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 49px;
            margin-bottom: 42px;
            padding-top: 12px;
            width: 100%;

            @media screen and (max-width: $tablet-lg) {
                margin: unset;
                padding-left: 30px;
                padding-right: 30px;
                height: 100%;
                overflow-y: auto;
            }

            @media screen and (max-width: $mobile) {
                @include transition;
                padding: unset;
                position: absolute;
                z-index: 3;
                left: 100%;
                background: $white;

                &.show-second-level {
                    left: 0;
                }
            }
        }
    }

    &__placeholder {
        display: none;
    }

    &__trigger {
        &--wrap {
            @include transition($time: .5s);
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media screen and (max-width: $tablet-lg) {
                direction: ltr;
            }

            @media screen and (max-width: $mobile) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: unset;
            }
        }

        &--item {
            @include transition;
            @include font($font-default, #{max(14px, vw(16px))}, $weight-normal, normal, 1);
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 11px;

                @media screen and (max-width: $mobile) {
                    margin-bottom: unset;
                }
            }

            @media screen and (max-width: $mobile) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 50%;
                min-height: 180px;
                border-bottom: 1px solid $gray-light;

                &:nth-child(odd) {
                    border-right: 1px solid $gray-light;
                }
            }

            .industry {
                &--name {
                    flex: auto;
                    max-width: 215px;
                    margin-right: 34px;
                    color: $black;

                    @media screen and (max-width: $mobile) {
                        margin-right: unset;
                        max-width: 125px;
                        text-align: center;
                        flex: unset;
                    }

                    &:before {
                        @include transition;
                        content: "";
                        position: absolute;
                        bottom: -13px;
                        left: 0;
                        right: 0;
                        margin-right: auto;
                        width: 0%;
                        height: 1px;
                        background-color: $kTabs-trigger-line-color;
                        opacity: 0;
                    }
                }
            }

            @media screen and (min-width: ($mobile + 1)) {
                &:after {
                    @include transition;
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.437012 1.73743C0.437012 2.0054 0.544499 2.2618 0.734893 2.45063L5.57638 7.23588L0.736018 12.02L0.735588 12.0204C0.641652 12.1126 0.56691 12.2225 0.51582 12.3437C0.464648 12.4651 0.43826 12.5954 0.43826 12.7271C0.43826 12.8588 0.464648 12.9891 0.51582 13.1105C0.566278 13.2302 0.639807 13.3388 0.732111 13.4303C0.822638 13.5255 0.931887 13.601 1.05293 13.6523C1.17527 13.7041 1.30708 13.7301 1.43995 13.7286C1.70807 13.7277 1.96552 13.6217 2.15575 13.4331L7.71897 7.86211C7.90491 7.67864 8.01256 7.43064 8.01845 7.16965L8.0188 7.15403L8.01736 7.13848C7.99545 6.90267 7.88823 6.68324 7.71632 6.52023L2.1562 1.02468L2.15578 1.02426C2.06249 0.931415 1.95173 0.85788 1.82995 0.80773C1.70798 0.757505 1.57726 0.731689 1.44532 0.731689C1.31338 0.731689 1.18266 0.757505 1.06069 0.80773C0.938918 0.857875 0.828169 0.931401 0.734894 1.02423C0.544499 1.21307 0.437012 1.46947 0.437012 1.73743ZM0.707845 1.73743C0.707845 1.74964 0.708151 1.76181 0.708758 1.77394L0.707845 1.73743ZM0.708758 1.77394C0.708151 1.76181 0.707845 1.74964 0.707845 1.73743L0.708758 1.77394Z' fill='%231B1B1B'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    width: 100%;
                    max-width: 9px;
                    height: 14px;
                    display: inline-flex;
                    opacity: .5;
                }

                &:hover,
                &--current {
                    .industry {
                        &--icon {
                            svg {
                                path {
                                    fill: $black;
                                }
                            }

                            i {
                                font-weight: 300;
                            }
                        }

                        &--name {
                            @include font($font-default, #{max(16px, vw(16px))}, $weight-medium, normal, 1);
                            color: $kTabs-trigger-current-color;

                            &:before {
                                opacity: 1;
                                width: 100%;
                            }
                        }
                    }

                    &:after {
                        opacity: 1;
                    }

                }

                &--current {
                    pointer-events: none;
                }
            }
        }
    }

    &__content {
        &--wrap {}

        &--item {
            &:not(:first-child) {
                display: none;
            }
        }
    }
}

body.kTabs-visible {
    @media screen and (max-width: $tablet-lg) {
        overflow-y: hidden;
    }
}

.second-level--handle {
    position: absolute;
    top: 25px;
    left: 40px;
    z-index: 4;
    display: none;

    &.active {
        display: block;
    }


    @media screen and (min-width: ($mobile + 1)) {
        display: none;
    }
}

.close-filters {
    @include transition;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}