// * SCREEN WIDTH CONSTANTS
$desktop: 1920px;
$laptop: 1440px;
$laptop-m: 1366px;
$laptop-sm: 1280px;
$laptop-xs: 1140px;
$tablet-lg: 1024px;
$tablet: 991px;
$tablet-sm: 767px;
$mobile: 580px;
$mobile-sm: 420px;

:root {
    // * PALETTE
    // ? neutral
    --black: #000000;
    --white: #FFFFFF;

    // ? black of theme
    --black-light: #1B1B1B;
	
    // ? red
    --red: #F30337;

		// ? gray
    --gray: #A6A8AA;
    --gray-light: #EDEDED;
    --gray-lighter: #F4F5F6;
    --gray-dark: #6F6F6F;

    // ? red-error - errors default
    --red-error: #ff0000;

    // * FONT
    --font-default: 'Proxima-Regular';
    --font-secondary: 'Proxima-Semi';

    // * LAYOUT
    --spacing-sides: #{max(10px, vw(115px))};
    
    // * RESPONSIVE VARIABLES
    @media (max-width: $laptop) {}

    @media (max-width: $mobile) {      
      --spacing-sides: 10px;
    }

    --container-gutter: #{max(20px, vw(30px))};
}

// * PALETTE ASSIGNMENT
// ? neutral
$black: var(--black);
$white: var(--white);

// ? black of theme
$black-light: var(--black-light);

// ? red
$red: var(--red);

// ? gray
$gray: var(--gray);
$gray-light: var(--gray-light);
$gray-lighter: var(--gray-lighter);
$gray-dark: var(--gray-dark);

// ? red-error - errors default
$red-error: var(--red-error);

// * FONT ASSIGNMENT
$font-default: var(--font-default)+', sans-serif';
$font-secondary: var(--font-secondary)+', sans-serif';

// * LAYOUT ASSIGNMENT
$spacing-sides: var(--spacing-sides);

// * ASSIGNED THROUGH JS VARIABLES
$screen-height: var(--screen-height) !default;
$screen-width: var(--screen-width) !default;
$header-height: var(--header-height) !default;

// ! $_variable to $_variable
// ! from this point down
// * THEME RE-ASSIGNMENTS

// * INTERACTION
$interaction-text-color: $white;
$interaction-background-color: $black-light;

// * BODY DEFAULT COLORS
$body-background-color: $white;
$body-text-color: $black-light;

// * PROJECT DEFAULT ERROR COLOR
$error-color: $red-error;

// * PROJECT INPUT FIELDS
$input-border-color-inactive: $gray-light;
$input-text-color-inactive: $gray;

// * CTA
// * CTA STRACTURE
$cta-padding-y: 12px;
$cta-padding-x: 31px;
$cta-border-radius: 3px;
$cta-border-width: 1px;
$cta-border-style: solid;

// * CTA THEMING
$cta-background-color: $black-light;
$cta-text-color: $white;
$cta-background-color-hover: $white;
$cta-text-color-hover: $black-light;

// ! INPUT
// * INPUT STRACTURE
$input-border-width: 1px;
$input-border-style: solid;
$input-border-radius: 8px;
$input-padding: #{max(18.5px, vw(18.5px))} #{max(20px, vw(28px))}; // left/right via figma is 20px @mob

// * INPUT THEMING
$input-background-color: transparent;
$input-text-color: $black-light;
$input-border-color: $black-light;
$input-border-text-color-hover: $black-light;
$input-error-color: $error-color;

// ! TO CHANGE

$primary_color: null;
$primary_font: null;
$header_1_fs: null;
$header_2_fs: null;
$header_3_fs: null;
$header_4_fs: null;
$hero_title_fs: null;
$banner_title_fs: null;
$text_fs: null;
$text_small_fs: null;
$text_large_fs: null;
$input_fs: null;
$border_color: null;
$border_radius_input: null;
$placeholder_color: null;
$border_color_focus: null;
$modal_title: null;
$side_padding: null;
$btn_fs: null;
$btn_font_color: null;
$btn_background_color: null;
$border_radius_button: null;
$secondary_color: null;
$light-gray: null;
$footer_background_color: null;
$side_padding_footer: null;
$side_padding_header: null;
$side_padding_checkout: null;
$mobile-xs: null;
$promo_bar_background_color: null;