.homepage {
    .marketing-bar {
        background-color: $gray-lighter;

        &__wrap {
            padding: 35px $spacing-sides;

            @media (max-width: $mobile) {
                padding-right: unset;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }

    .product-block {
        &.theme {
            &--gray {
                background-color: $gray-lighter;

                .product-block__wrap {
                    padding-bottom: max(55px, vw(78px));
                }
            }
        }

        &.spacing {
            &--small {
                .product-block__wrap {
                    padding-bottom: max(55px, vw(78px));
                }
            }
        }

        &__wrap {
            padding-top: max(25px, vw(63px));
            padding-bottom: max(55px, vw(132px));
            padding-left: $spacing-sides;
            padding-right: $spacing-sides;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: max(32px, vw(60px));
            border-bottom: 1px solid $black-light;

            @media screen and (max-width: $tablet-sm) {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
            }

            &--title {
                @extend .title__H1;
                padding-top: 20px;
                padding-bottom: 20px;

                @media screen and (max-width: $tablet-sm) {
                    padding-top: unset;
                    padding-bottom: 7px;
                }
            }

            &--cta {
                @media screen and (max-width: $tablet-sm) {
                    padding-bottom: 15px;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }
}

.search-bar {
    &__wrap {}

    &__content {
        height: 100%;
        min-height: 400px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: var(--search-bg);
        background-position: right;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $tablet) {
            background-position: 85%;
        }

        @media screen and (max-width: $mobile-sm) {
            background-image: var(--search-bg-mob);
            background-position: top center;
        }

        .form {
            display: flex;
            width: 100%;

            &-wrap {
                display: flex;
                align-items: center;
                padding: 4px 8px;
                background-color: $white;
                box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.25);
                
                @media screen and (max-width: $mobile) {
                    width: max(332px, vw-rs($mobile, 432px));
                    padding: unset;
                    margin-top: 126px;
                }
            }

            input {
                width: 100%;
                min-width: max(240px, vw(355px));
                margin-right: 8px;
                border: none;
                padding-top: 15px;
                padding-bottom: 15px;

                @media screen and (max-width: $mobile) {
                    min-width: 100%;
                    margin-right: unset;
                    padding: 22px;
                }

                &::placeholder {
                    @extend .text__normal;
                    color: $gray;
                }
            }

            button {
                margin-right: 23px;
                @media screen and (max-width: $mobile) {
                    display: none;
                }
            }
        }

        .filters-industries {
            cursor: pointer;
            display: flex;

            @media screen and (max-width: $mobile) {
                position: absolute;
                left: 0;
                top: 100%;
                margin-top: 12px;
                width: 100%;
                border: 1px solid $red;
                padding: 22px;
                background-color: $red;
            }

            &--icon {
                display: inline-flex;
                margin-right: 10px;

                svg {
                    path {
                        fill: $black;
                    }
                }

                @media screen and (max-width: $mobile) {
                    svg {
                        path {
                            fill: $white
                        }
                    }
                }
            }

            &--title {
                @extend .text__small-titles-semi;
                display: inline-flex;
                align-items: center;

                @media screen and (max-width: $mobile) {
                    width: 100%;
                    justify-content: space-between;
                    color: $white;                    
                }
                
                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.18684 7.33106L9.41642 3.05178C9.46858 2.99944 9.50998 2.93716 9.53823 2.86855C9.56649 2.79993 9.58103 2.72634 9.58103 2.65201C9.58103 2.57767 9.56649 2.50408 9.53823 2.43546C9.50998 2.36685 9.46858 2.30457 9.41642 2.25223C9.31002 2.14495 9.16593 2.08472 9.01572 2.08472C8.86551 2.08472 8.72142 2.14495 8.61502 2.25223L4.78614 6.1261L0.957259 2.25223C0.905523 2.19946 0.843971 2.15757 0.776153 2.12898C0.708336 2.10039 0.635595 2.08568 0.562128 2.08568C0.48866 2.08568 0.415919 2.10039 0.348102 2.12898C0.280284 2.15757 0.218732 2.19946 0.166996 2.25223C0.113528 2.30251 0.0710517 2.36355 0.0422978 2.43143C0.0135439 2.49931 -0.000850922 2.57253 3.88723e-05 2.64637C0.000407337 2.79855 0.0604554 2.94436 0.166996 3.05178L4.45223 7.33106C4.55566 7.43614 4.69525 7.49667 4.84179 7.49998C4.97322 7.48777 5.09581 7.42775 5.18684 7.33106V7.33106Z' fill='%231B1B1B'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: 10px;
                    width: 10px;
                    height: 10px;
                    display: flex;
                    margin-left: 14px;
                    margin-right: 15px;
                    @media screen and (max-width: $mobile) {
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.18678 7.33106L9.41635 3.05178C9.46852 2.99944 9.50992 2.93716 9.53817 2.86855C9.56643 2.79993 9.58097 2.72634 9.58097 2.65201C9.58097 2.57767 9.56643 2.50408 9.53817 2.43546C9.50992 2.36685 9.46852 2.30457 9.41635 2.25223C9.30996 2.14495 9.16587 2.08472 9.01566 2.08472C8.86545 2.08472 8.72136 2.14495 8.61496 2.25223L4.78608 6.1261L0.957198 2.25223C0.905462 2.19946 0.84391 2.15757 0.776092 2.12898C0.708275 2.10039 0.635534 2.08568 0.562067 2.08568C0.488599 2.08568 0.415858 2.10039 0.348041 2.12898C0.280223 2.15757 0.218671 2.19946 0.166935 2.25223C0.113467 2.30251 0.0709907 2.36355 0.0422368 2.43143C0.0134829 2.49931 -0.000911957 2.57253 -2.21629e-05 2.64637C0.000346302 2.79855 0.0603943 2.94436 0.166935 3.05178L4.45217 7.33106C4.5556 7.43614 4.69519 7.49667 4.84173 7.49998C4.97316 7.48777 5.09575 7.42775 5.18678 7.33106V7.33106Z' fill='white'/%3E%3C/svg%3E");
                    }
                }

            }
        }
    }
}

.product-profile {
    $profile-width: 259px;
    // $profile-height: 259px;

    @media screen and (max-width: $mobile) {
        width: 100%;

        &:first-child {
            margin-top: 30px;
        }
    }

    &__block {
        display: flex;
        flex-wrap: wrap;
        max-width: 540px;
        justify-content: space-between;

        @media screen and (max-width: $mobile) {
            max-width: unset;
            width: 100%;
        }
    }

    &__wrap {
        max-width: $profile-width;
        display: flex;
        margin-bottom: 39px;

        @media screen and (max-width: $mobile) {
            padding: 50px 32px;
            border-bottom: 1px solid $gray-light;
            margin-bottom: unset;
            max-width: unset;
        }
    }

    &__image {
        width: 100%;
        max-width: 58px;
        height: 58px;
        border-radius: 58px;
        overflow: hidden;

        @media screen and (max-width: $mobile) {
            max-width: 79px;
            height: 79px;
            border-radius: 79px;
        }
    }

    &__content {
        max-width: 184px;
        min-width: 184px;
        margin-left: 17px;

        @media screen and (max-width: $mobile) {
            max-width: 100%;
            min-width: unset;
        }

        &--title {
            @extend .text__small-titles-semi;
            margin-bottom: 7px;
            margin-top: -5px;
        }

        &--tags {
            @extend .text__small;
            color: $gray-dark;
        }
    }
}