@use "sass:math";
$border: max(1px, math.div(1rem, 16)) solid $black-light;

//.checkout-popup {
//    position: fixed;
//    z-index: 99;
//    height: 90%;
//    width: 80%;
//    background: #fff;
//    padding: 6em 2em 2em;
//    top: 50%;
//    left: 50%;
//    transform: translate3d(-50%, -50%, 0);
//    box-shadow: 0 0 1em #000;
//    overflow: hidden;
//    &__inner {
//        position: static;
//        height: 100%;
//    }
//    &__content {
//        height: 100%;
//    }
//    &__close {
//        width: 3em;
//        height: 3em;
//        position: absolute;
//        right: 2em;
//        top: 2em;
//        cursor: pointer;
//        &::before,
//        &::after {
//            content: '';
//            width: 100%;
//            height: math.div(1em, 16);
//            background: $black-light;
//            display: block;
//            position: absolute;
//            top: 50%;
//            left: 50%;
//        }
//        &::before {
//            transform: translate(-50%, -50%) rotate(-45deg);
//        }
//        &::after {
//            transform: translate(-50%, -50%) rotate(45deg);
//        }
//    }
//}
#checkout-page {
    margin-top: max(20px, 3.6458333333vw);
    margin-bottom: max(80px, 10.15625vw);
    .js-has-error {
        color: $red;
        font-size: .75em;
    }
}
.checkout {
    height: 100%;
    &__roadmap {
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width: 540px) {
            flex-wrap: nowrap;
            height: 2em;
        }
        overflow: hidden;
        font-size: 1.25em;
        margin-bottom: 1em;
        line-height: 2;
        background-color: #ddd;
        counter-reset: checkout-steps;
        li {
            position: relative;
            flex-grow: 1;
            height: 100%;
            padding: 0 3em;
            color: #666;
            white-space: nowrap;
            width: 100%;
            counter-increment: checkout-steps;
            &[data-complete] {
                background: green;
                color: white;
                cursor: not-allowed;
                &::after {
                    @media screen and (min-width: 540px) {
                        background: green;
                    }
                    cursor: not-allowed;
                }
                &[data-editable] {
                    cursor: pointer;
                }
            }

            &::after {
                content: counter(checkout-steps);
                position: absolute;
                z-index: 1;
                width: 1.5em;
                height: 1.5em;
                right: auto;
                left: 0.5em;
                border-radius: 50%;
                background: #fff;
                text-align: center;
                top: 0.25em;
                line-height: 1.5;
                color: #666;

                @media screen and (min-width: 540px) {
                    content: "";
                    top: auto;
                    right: -1em;
                    left: auto;
                    border-radius: 0;
                    width: 2em;
                    height: 2em;
                    background: transparent;
                    transform: rotate(45deg);
                    box-shadow: .25em -.25em white;
                }
            }
        }
    }
    &__steps {
        height: calc(100% - 3 * 1.25em);
        margin-right: calc(-1 * var(--container-gutter) / 2);
        margin-left: calc(-1 * var(--container-gutter) / 2);
    }
    &__step {
        height: 100%;
        overflow: auto;
        padding-right: calc(var(--container-gutter) / 2);
        padding-left: calc(var(--container-gutter) / 2);
    }
    .tabs {
        &__header {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            border-bottom: $border;
            border-left: $border;
            margin-bottom: 1em;
            font-size: 1.25em;
            font-weight: 700;
        }
    }
    .tab {
        &__header {
            padding: .75em 1.25em;
            border-top: $border;
            border-right: $border;
            cursor: pointer;
            &.active {
                color: #fff;
                background: $black-light;
            }
        }
        &__content {
            display: none;
            &.active {
                display: block;
            }
        }
    }

    .recipients {
        margin-bottom: 2em;
        .remove-row {
            position: absolute;
            right: -1em;
            top: -1em;
            width: 2em;
            height: 2em;
            border-radius: 50%;
            border: 1px solid #000;
            background: #fff;
            opacity: 0;
            pointer-events: none;
            transition: all .3s ease-in-out;
            &::before,
            &::after {
                content: "";
                width: 60%;
                height: max(1px, vw(1px));
                background: $black-light;
                position: absolute;
                top: 50%;
                left: 50%;
            }
            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:hover {
                background: $black-light;
                &::before,
                &::after {
                    background: #fff;
                }
            }
        }

        .repeater-field {
            &__rows {
                margin-bottom: 1em;
            }
            &__row {
                &:hover {
                  .remove-row {
                      opacity: 1;
                      pointer-events: auto;
                  }
                }
                &:not(:first-child) {
                    .repeater-field__row-header {
                        border-top: 0 none
                    }
                }
            }
            &__row-header {
                font-size: 1em;
                padding: 1em 1.5em;
                border: 1px solid #000;
                cursor: pointer;
            }
            &__row-content {
                font-size: 1em;
                padding: 1em 1.5em;
                border: 1px solid #000;
                border-top: 0 none;
            }
            &__row:nth-child(even) {
                .repeater-field__row-header {
                    background: #ededed;
                }
            }
        }
    }
    &__product-quantity-lbl {
        flex: 1;
    }
    &__product-quantity {
        display: flex;
        flex-wrap: wrap;
        margin: 1em 0;
        background: var(--gray-lighter);
        padding: 1em;
    }
    &__product-quantity-input-group {
        .quantity {
            display: flex;
            &__button {
                border: 1px solid #000;
                cursor: pointer;
                @include font($font-default, #{max(14px, vw(16px))}, $weight-normal, normal, #{max(21px, vw(24px))});
                color: $black-light;
                padding: 10px max(10px, vw(18px));
                user-select: none;
                &--down {
                    border-right: 0 none;
                }
                &--up {
                    border-left: 0 none;
                }
            }
            &__input {
                text-align: center;
                body & {
                    padding: 10px max(10px, vw(18px));
                    width: calc(3em + 20px);
                }
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;;
                    margin: 0;
                }
            }
        }
    }

    .cart-payment {
        &__row {
            display: flex;
            flex-wrap: wrap;
            margin-right: calc(-1 * var(--container-gutter) / 2);
            margin-left: calc(-1 * var(--container-gutter) / 2);
        }
        &__col {
            padding-right: calc(var(--container-gutter) / 2);
            padding-left: calc(var(--container-gutter) / 2);
            width: 100%;
            @media screen and (min-width: 992px){
                width: 50%;
            }
        }
        &__header {
            border-bottom: $border;
            padding-bottom: 0.25em;
            margin-bottom: 1em;
        }
    }

    .cart-product {
        display: flex;
        width: 100%;
        margin-bottom: 1em;
        &__img-cnt {
            width: max(100px, vw(100px));
        }
        &__info {
            flex: 1;
            padding-left: 1em;
            display: flex;
            @include font($font-default, #{max(18px, vw(18px))}, $weight-bold, normal, #{max(22px, vw(22px))});
        }
        &__name {
            width: 70%;
        }
        &__qnt,
        &__price {
            text-align: right;
            flex: 1;
        }
        &__price {
            padding-left: 1em;
        }
    }

    .cart-product + .cart__price{
        margin-top: 2em;
    }
    .cart {
        &__price {
            display: flex;
            padding: 1em 0;
            border-top: 1px solid $black-light;
            &--total {
                font-weight: 700;
            }
        }
        &__price-lbl {
            flex: 1;
            padding-right: 1em;
        }
    }
    .shipping_details {
        width: 50%;
        &__row {
            display: flex;
            &--header {
                border-bottom: 1px solid $black-light;
                font-weight: 700;
            }
        }
        &__lbl {
            padding-right: 0.5em;
            font-weight: 700;
        }
    }
    .has-error {
        .input__default,
        .select2-selection {
            border-color: $red
        }
    }
}

.loading {
    display:none;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255,255,255,0.6);
    z-index: 99;
}
.redeem {
    margin-bottom: 0;
    &__row {
        display: flex;
        align-items: flex-start;
    }
    &__input {
        flex: 1;
        width: 100%;
    }
    &__buttons {
        margin-left: 1em;
    }
}