// ALL ACCOUNT PAGES
$secondary_font: $font_default !default;
// account palette
$color_a: $black-light;
$color_b: $black-light;

label.disabled{
    opacity: 0.4 !important;
}
.has-page-message {

    &,
    &-error,
    &-success {
        margin: auto;
        max-width: 600px;
        text-align: center;
        border: 1px solid;
        padding: 15px;
        margin-bottom: 20px;
        font-size: 80%;
        max-width: 570px;
    }

    &-wrap {
        margin-left: 15px;
        margin-right: 15px;
    }

    &-error {
        color: $red;
    }

    &-success {
        color: $black-light;
    }
}

.account {

    //account typography @extendable
    &-titles {
        @extend .text__big-bold;
    }

    &-texts {
        @extend .text__small-titles-semi;
    }

    &-table {

        &--head {
            @include font($font-default, #{max(14px, vw(14px))}, $weight-medium, normal, 1);
        }

        &--body {
            @include font($font-default, #{max(14px, vw(14px))}, $weight-normal, normal, 1);
        }
    }

    //account typography @extendable end

    &-page-height {
        min-height: calc(var(--screen-height) - var(--header-height));
        padding-bottom: max(30px, vw(60px));
    }

    &-max {
        &--small {
            max-width: 600px;
        }

        &--big {
            max-width: 1000px;
        }
    }

    &-title {
        @extend .account-titles;
        margin-bottom: max(20px, vw(40px));
        color: $color_a;
        text-transform: uppercase;
    }

    &-text {
        @extend .account-texts;
        margin-bottom: max(20px, vw(40px));
        color: $color_b;
    }

    &-cta {
        margin-top: auto;

        &--forgot {
            text-align: right;
            font-size: 80%;

            a {
                background: transparent;
                border: none;
                color: inherit;
                text-decoration: underline;
                padding: 0;
                position: absolute;
                right: 0;

                &:hover {
                    background-color: unset;
                    color: unset;
                    border-color: unset;
                }
            }
        }
    }

    &-page-heading {
        margin-bottom: max(20px, vw(40px));
        color: $color_a;
        display: flex;
        flex-direction: column;

        h2 {
            @include font($font-default, #{max(22px, vw(24px))}, $weight-normal, normal, 1);
            text-align: center;
            color: inherit;
            margin-bottom: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &>span {

            @include font($font-default, #{max(14px, vw(16px))}, $weight-normal, normal, 1);
            margin-bottom: 0;
            display: inline;
            text-align: center;
            max-width: 375px;
            margin: auto;

            &.expand-width {
                max-width: unset;
            }
        }
    }

    &-block {
        margin-bottom: max(20px, vw(40px));

        &-title {
            @extend .account-titles;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        ul {
            // text-transform: uppercase;
        }
    }

    &-content {
        .js-has-error {
            font-size: 12px;
            color: $red;
            margin-left: 5px;
        }

        .js-custom-field {
            display: none;
        }

        .if-no-socials {
            margin-bottom: max(20px, vw(40px));
        }

        // reset bootstrap class
        @media screen and (max-width: 767px) {
            .table-responsive {
                border: unset;
            }
        }

        .input-group {
            button {
                background-color: transparent !important;
                border: unset !important;

                &:active {
                    box-shadow: unset !important;
                }
            }
        }

        // reset bootstrap class end

        a {
            &:hover {
                color: inherit;
                text-decoration: underline;
            }
        }

        p {
            a {
                text-decoration: underline;
            }
        }

        select {
            border-color: $color_a;
            color: $color_a;
        }

        input {

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                box-shadow: unset !important;
            }
        }

        .inline-option,
        ._newsletter {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 0;

            &>* {
                text-align: center;
            }

            &--not-centered {
                align-items: initial;

                &>* {
                    text-align: left;
                }
            }

            label {
                left: 0;
                top: 0;

                &.radio-inline {
                    @media screen and (min-width: ($tablet + 1)) {
                        font-size: 75%;
                    }

                    padding: 5px;
                }

            }
        }

        #d_social_login {
            font-size: 12px;
        }

        .order-pag {
            font-size: 14px;
        }

        thead {
            tr {
                td {
                    color: $color_b;
                }
            }
        }

        tfoot {
            font-size: 14px;

            tr {
                &:last-of-type {
                    text-transform: uppercase;
                }
            }
        }

        fieldset {
            border: 1px solid $black-light;
            padding: max(20px, vw(28px));
            margin-bottom: 19px;

            legend {
                @extend .title__H3;
                display: inline-block;
                width: auto;
                padding: 0 19px;
                margin-bottom: unset;
                border: unset;
            }
        }
    }

    &-dual-block {
        display: flex;
        justify-content: space-between;
        padding-bottom: max(20px, vw(90px));
        padding-top: max(20px, vw(90px));
        border-bottom: 1px solid $color_b;

        @media screen and (max-width:$tablet) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: 0;
            padding-bottom: 42px;
        }

        &--item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
            max-width: 512px;

            @media screen and (max-width:$tablet) {
                width: 100%;
                text-align: center;

                &:first-child {
                    margin-top: 25px;
                    margin-bottom: 64px;
                }
            }

            @media screen and (min-width: ($tablet + 1)) {
                &:first-child {
                    margin-right: 58px;
                }

                &:last-child {
                    margin-left: 58px;
                }

            }

            p {
                @media screen and (min-width: ($tablet + 1)) {
                    margin-bottom: 0;
                }

                @media screen and (max-width: $tablet) {

                    text-align: center !important;
                }
            }

        }
    }

    &-single-block {

        &--logo {
            margin-left: auto;
            margin-right: auto;
            margin-top: 90px;
            margin-bottom: 50px;
        }
    }

    &-form {
        &--field {
            &:not(.account-cta) {
                margin-bottom: 19px;
            }

            &>div {
                &>label {
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }

            &.align-center {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &-table-themed {
        border: 1px solid $color_a;
        border-radius: 100px;
        padding: max(20px, vw(40px));
        margin-bottom: max(20px, vw(40px));

        table {

            >tbody>tr>td,
            >tbody>tr>th,
            >tfoot>tr>td,
            >tfoot>tr>th,
            >thead>tr>td,
            >thead>tr>th {
                border-bottom: unset !important;
            }
        }
    }

    &-table {
        width: 100%;
        margin-bottom: max(20px, vw(40px));

        .nowrap {
            white-space: nowrap;
        }

        >tbody>tr>td,
        >tbody>tr>th,
        >tfoot>tr>td,
        >tfoot>tr>th,
        >thead>tr>td,
        >thead>tr>th {
            border: none;
            border-bottom: 1px solid $gray-light;
            padding: 20px 15px;
        }

        td.text-left a {
            color: #000;
            text-decoration: none;
        }

        td.text-right>a {
            text-decoration: none;
        }

        .add-to-cart-icon svg,
        .add-to-cart-icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }

        .remove-item-icon svg .st0 {
            fill: none;
            stroke: #000000;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
        }

        .remove-item-icon svg,
        .remove-item-icon {
            display: inline-block;
            width: 15px;
            height: 15px;
        }

        thead>tr>td {
            @extend .account-table--head;
            color: #000;
        }

        tbody>tr>td {
            @extend .account-table--body;
            color: #000;
        }

        .table-hover>tbody>tr {
            opacity: 0.7;
            background-color: transparent;
        }

        .table-hover>tbody>tr:hover {
            opacity: 1;
        }

        td.text-right>a.edit-address-btn,
        td.text-right>a.remove-address-btn,
        td.text-right>a.btn-view-item {
            font-size: 1.1rem;
            line-height: initial;
            font-weight: 600;
            letter-spacing: 0.025em;
            color: #000;
            border: none;
            border-radius: 0;
            background-image: none;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
}

// SOCIAL LOGIN
#d_social_login {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: max(20px, vw(90px)) auto max(20px, vw(30px)) auto;

    .d-social-login {

        &__label {
            height: auto;
            margin: auto;
        }

        &__button {
            display: block;
            width: 100%;
            color: $white;
            text-decoration: none;
            border-radius: unset;
            margin-top: unset;

            &:not(:last-child) {
                margin-bottom: 19px;
            }

            &__icon {
                max-width: 100%;
                text-align: left;
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;
                padding-left: 45px;

                img {
                    max-width: 30px;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

            }

            &--facebook {
                background: linear-gradient(150deg, #4864b4 0%, $black-light 30%);

                img {
                    max-width: 10px;
                }
            }

            &--google {
                background: linear-gradient(150deg, #dd4b39 0%, $black-light 30%);

                img {
                    max-width: 25px;
                }
            }
        }
    }
}


.grid-placing {
    &--2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 19px;
    }

    &--3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 19px;
    }

    &--2,
    &--3 {
        @media screen and (max-width: $tablet-sm) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.subscription {
    &-listing {
        flex: auto;

        &__wrap {
            overflow: auto;

            &::-webkit-scrollbar {
                // ? width of the entire scrollbar
                width: 12px;
            }

            &::-webkit-scrollbar-track {
                // ? color of the tracking area
                background: $white;
            }

            &::-webkit-scrollbar-thumb {
                // ? color of the scroll thumb
                background-color: $red;
                // ? roundness of the scroll thumb
                border-radius: 20px;
                // ? creates padding around scroll thumb
                border-style: solid;
                border-color: $white;
                border-width: 5px;
            }
        }

        &__table {
            display: inline-flex;
            flex-direction: column;
            min-width: 400px;
            min-width: 100%;

            &--heading {
                display: flex;
                border-bottom: 1px solid $black-light;
            }

            &--heading,
            &--item {
                display: inline-flex;
                line-height: 1;
                font-size: 80%;
                width: 100%;

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 20%;
                    min-width: max(85px, vw(135px));
                    padding: 15px 5px;
                    text-align: center;

                    &:not(:last-child) {
                        margin-right: 5px;
                    }

                    &:first-child {
                        justify-content: flex-start;
                    }

                    &.column {
                        &--small {
                            min-width: max(50px, vw(85px));
                        }

                        &--large {
                            min-width: max(125px, vw(175px));
                        }
                    }
                }
            }

            &--item {
                background-color: $gray-light;
                border-radius: 5px;
                margin-top: 15px;
            }
        }
    }

    &-edit {

        &__details {
            padding-left: max(15px, vw(20px)) !important;
            padding-right: max(15px, vw(20px)) !important;

            .subscription-edit__block--column {                
                width: 48%;
            }
        }

        &__block {
            display: flex;
            justify-content: space-between;

            &--column {
                display: flex;
                flex-direction: column;
                line-height: 1;
                font-size: 80%;

                &-heading {
                    display: inline-flex;
                    align-self: flex-start;
                    padding: 5px;
                    font-size: 80%;
                    background-color: $gray-light;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                span {
                    &:not(:last-child) {
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        border-bottom: 1px solid $black-light;
                    }
                }
            }
        }

        &__addresses {
            padding: unset;

            .subscription-edit {
                &__block {
                    justify-content: space-between;

                    @media screen and (max-width: $tablet) {
                        flex-direction: column;
                        justify-content: unset;
                    }

                    &--column {
                        flex: auto;

                        @media screen and (min-width: ($tablet + 1)) {
                            &:not(:first-child) {
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }

            address {
                background-color: $gray-light;
                padding: 15px;
                border-radius: 5px;
                border-top-left-radius: unset;
            }
        }

        &__recipients {
            &--listing {
                &-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    background-color: var(--gray-light);
                    border-radius: 5px;
                    padding: 15px;
                    font-size: 80%;
                    line-height: 1;
                    margin-bottom: 15px;
                }

                &-add {
                    @extend .cta--account;
                    max-width: unset;
                }
            }
        }
    }

    &__actions {
        position: absolute;
        top: -10px;
        left: -10px;
        display: flex;
        background-color: $gray-light;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 2px 2px 10px -5px #000000;

        &>* {
            cursor: pointer;

            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
}
.cta--duplicate{
    color: darkblue;
}
.account-content {
    .fieldset__title-only {
        padding: unset;
        border: unset;
        border-top: 1px solid $black-light;

        legend {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.recipients {
    margin-bottom: 2em;
    .js-has-error {
        margin-top: .5em;
        line-height: 1.2;
    }
    .remove-row {
        @include transition;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='%231B1B1B' d='M352 128c0 70.7-57.3 128-128 128S96 198.7 96 128 153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4c98.5 0 178.3 79.8 178.3 178.3 0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM472 200h144c13.3 0 24 10.7 24 24s-10.7 24-24 24H472c-13.3 0-24-10.7-24-24s10.7-24 24-24z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px 16px;

        position: absolute;
        top: 0;
        transform: translateY(75%);
        right: -17px;
        width: 35px;
        height: 30px;
        background-color: $gray-light;
        padding: 5px;
        border-radius: 5px;
        box-shadow: -2px 2px 10px -5px #000000;
        border: unset;
        opacity: 0;
        pointer-events: none;
    }

    .repeater-field {
        &__rows {
            margin-bottom: 1em;
        }

        &__row {
            &:hover {
                .remove-row {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            &:not(:first-child) {
                .repeater-field__row-header {
                    border-top: 0 none
                }
            }
        }

        &__row-header {
            font-size: 1em;
            padding: 1em 1.5em;
            border: 1px solid #000;
            cursor: pointer;
        }

        &__row-content {
            font-size: 1em;
            padding: 1em 1.5em;
            border: 1px solid #000;
            border-top: 0 none;
        }

        &__row:nth-child(even) {
            .repeater-field__row-header {
                background: #ededed;
            }
        }
    }
}

.cta--cancelation {
    color: $red
}
.controlled_by_same_addresses_for_all--false {
    display: none !important;
}

.loading-overlay {
    display: none;
    background: rgba( 26, 26, 26, 0.7 );
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
}

.loading-overlay-image-container {
    display: none;
    position: fixed;
    z-index: 7;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
}

.loading-overlay-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

@media screen and (max-width: 767px) {
    #modalUpdateAddressInfo .modal-dialog{
        width: unset;
    }
}

@media screen and (min-width: 1200px) {
    #modalUpdateAddressInfo .modal-dialog{
        width: 1000px;
    }
}