/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
  .product-wrapper {
    padding: 50px max(10px, vw(115px)) max(40px, vw(240px)) ;
    @media screen and (max-width: $laptop-xs) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: $mobile) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .product-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1465px;
      margin: 0 auto;

      @media screen and (max-width: $tablet) {
        flex-direction: column-reverse;
        align-items: center;
      }

      .left-col {
        width: 100%;
        max-width: 825px;
        margin-right: vw(75px);

        @media screen and (max-width: $laptop-xs) {
          margin-right: 35px;
        }

        @media screen and (max-width: $tablet) {
          margin-right: 0;
          max-width: 100%;
          margin-top: 40px;
        }

        @media screen and (max-width: $mobile) {
          margin-top: 15px;
        }

        .product-description {
          border: 1px solid #D9D9D9;
          padding: 35px 55px 50px;
          text-align: justify;
          background-color: $white;
          @media screen and (max-width: $mobile) {
            padding: 15px;
            border: unset;
          }
          h3 {
            margin-bottom: 23px;
          }

          .description {
            margin-bottom: 35px;
            div {
              * {
                font-family: $font-default!important;
                font-size: #{max(14px, vw(20px))}!important;
                //font-weight: 400!important;
                line-height: #{max(21px, vw(34px))}!important;
                list-style-type: disc;
              }
            }

          }

          .industry {
            margin-bottom: 25px;
          }
        }

        ul.topics {
          display: flex;
          flex-wrap: wrap;

          li {
            &:not(:last-child) {
              &:after {
                display: inline-block;
                content: ',';
                margin: 0 10px 0 1px;
              }
            }

            a {
              display: inline-block;
              position: relative;

              &:hover, &:focus, &:active {
                color: $black;
              }

              &:after {
                display: inline-block;
                content: '';
                background-color: $black;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 5px;
                left: 0;
              }
            }
          }
        }

        .share-section{
          display: flex;
          justify-content: center;
          margin-top: 20px;
          background-color: #EDEDED;
          padding: 20px;
          @media screen and (min-width: $tablet + 1){
            display: none;
          }
          &--text{
            font-size: 16px;
            font-weight: 600;
          }
          &--fb{
            width: 8px;
            svg{
              fill: #1B1B1B;
            }
          }
          &--linkedin{
            width: 16px;
            svg{
              fill: #1B1B1B;
            }
          }
          span{
            display: inline-block;
            &:not(:last-child){
              margin-right: 15px;
            }
          }
          .fb-share {
            margin-right: 15px;
          }
        }
      }

      .right-col {
        width: 100%;
        max-width: max(360px, vw(570px));
        margin-top: -350px;

        @media screen and (max-width: $tablet) {
          margin-top: 20px;
          max-width: 570px;
        }

        .right-box {
          position: sticky;
          top: 0;
          padding: vw(35px) vw(30px) vw(30px);
          background: $white;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.14);

          @media screen and (max-width: $mobile) {
            position: unset;
            padding: 30px 10px;
          }

          .product__tabs {
            .tab__heading {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin: 20px 0;
              background-color: $gray-light;
              @media screen and (max-width: $mobile) {
                background-color: $white;
                display: block;
              }
              &:after {
                display: flex;
                content: '';
                width: 100%;
                height: 8px;
                background-color: $gray;
                position: absolute;
                left: 0;
                bottom: 0;
              }

              &--item {
                display: flex;
                align-items: center;
                padding: 22px 12px;
                color: $black-light;
                border: 1px solid $red;
                border-bottom: 8px solid $red;
                opacity: 0.5;
                @media screen and (max-width: $mobile-sm) {
                  padding: 15px;
                  border-bottom: 4px solid $red;
                }
                &:hover, &:focus, &:active {
                  color: $black-light;
                }
                &.active {
                  background-color: $white;
                  z-index: 1;
                  opacity: 1;
                  @media screen and (max-width: $mobile-sm) {
                    background-color: $gray-light;
                    border: none;
                    border-bottom: 8px solid $red;
                    display: flex;
                    flex-direction: column;
                  }
                }
              }

              &--icon {
                display: inline-flex;
                margin-right: 5px;
                @media screen and (max-width: $mobile-sm) {
                  margin-bottom: 10px;
                }
                svg {
                  display: inline-flex;
                  path {
                    fill: $black-light!important;
                  }
                }
              }

              &--title{
                @media screen and (max-width: $mobile-sm) {
                  font-size: 14px;
                }
              }
            }
            .tab__content {
              &--item {
                display: flex;
                flex-direction: column;
                border: 2px solid $gray-light;
                padding: 4px;
                margin-bottom: 6px;
                color: $black-light;
                .package {
                  &__info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px;
                    &--price {
                      display: flex;
                      align-items: center;
                      li {
                        &:not(:last-child) {
                          margin-right: 10px;
                        }
                        .product-price {
                          &.old {
                            text-decoration: line-through;
                          }
                        }
                      }
                    }
                    //&--cta {
                    //  opacity: 0.5;
                    //}
                    div {
                      &:not(:last-child) {
                        //margin-right: 10px;
                      }
                    }
                  }

                  &__advantages {
                    display: none;
                    background-color: $gray-light;
                    padding: 25px 20px;
                    @include transition(all);
                    h3 {
                      margin-bottom: 15px;
                    }
                    p {
                      @extend .text__normal;
                    }
                    ul {
                     padding-left: 20px;
                      li {
                        list-style-type: disc;
                        @extend .text__normal;
                      }
                    }
                  }
                }

                &.active, &:hover, &:focus {
                  @include transition(all);
                  border-color: $red;
                  color: $black-light;
                  .package__info--cta {
                    opacity: 1;
                  }
                }

                &.active {
                  .package__advantages {
                    display: block;
                  }
                }

              }
            }
            .tab__share{
              display: flex;
              justify-content: center;
              margin-top: 50px;
              @media screen and (max-width: $tablet){
                display: none;
              }
              &--text{
                font-size: 16px;
                font-weight: 600;
              }
              span {
                &.tab__share--text{
                  margin-right: 15px;
                }
              }
              a {
                span {
                  display: inline-flex;
                  svg {
                    display: inline-flex;
                    .st0 {
                      fill: $black-light;
                    }
                  }
                }
                &.fb-share {
                  margin-right: 15px;
                }
              }
            }
          }

          &.product-bundle {
            background: $black-light;
            .product__tabs {
              .tab {
                &__heading {
                  background-color: transparent;
                  &--icon {
                    svg {
                      path {
                        fill: $white!important;
                      }
                    }
                  }
                  &--item {
                    color: $white;
                    width: 100%;
                    &.active {
                      background-color: $black-light;
                    }
                  }
                }
                &__content--item {
                  .package__info {
                    color: $white;
                    &--cta {
                      @include button($red, $white, $black-light, $white);
                      &:hover, &:focus, &:active {
                        border-color: $red;
                      }
                    }
                  }
                }

                &__share{
                  color: $white;
                  a {
                    span {
                      svg {
                        .st0 {
                          fill: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

.related--events {
  padding-top: max(60px,vw(115px));
  h3 {
    //display: flex;
    border-bottom: 1px solid $black-light;
    line-height: 2;
    margin-left: max(30px,vw(95px));
    margin-right: max(30px,vw(95px));
    margin-bottom: 50px;
    @media screen and (max-width: $tablet){
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: $mobile){
      text-align: center;
      font-size: 24px;
      max-width: 490px;
    }
  }
  .relatedEvents {
    padding-left: max(30px,vw(95px));
    padding-right: max(30px,vw(95px));
    @media screen and (max-width: $tablet) {
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (max-width: $mobile) {
      padding-left: 0;
      padding-right: 0;
      max-width: 490px;
    }
    .related-events-arrows {
      display: flex;
      justify-content: space-between;
      top: 38%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 1;
      @media screen and (max-width: $tablet) {
        position: relative;
        justify-content: center;
        top: unset;
        margin-top: 40px;
      }
      .related-events-arrow {
        display: inline-flex;
        border: 1px solid $black-light;
        border-radius: 4px;
        padding: max(17px, vw(22px));
        z-index: 9;
        svg {
          display: flex;
          height: 15px;
          width: 9px;
          @media screen and (max-width: $tablet) {
            height: 12px;
            width: 6px;
          }
        }

        &.prev {
          @media screen and (max-width: $tablet) {
            margin-right: 24px;
          }
        }

        &.swiper-button-disabled {
          opacity: 0.5;
        }
      }
    }

  }
}

.related--products {
  margin-top: max(60px, vw(75px));
  @media screen and (max-width: $mobile) {
    max-width: 490px;
  }
  h3 {
    margin-bottom: 50px;
    border-bottom: 1px solid $black-light;
    line-height: 2;
    @media screen and (max-width: $mobile) {
      text-align: center;
      line-height: 29.23px;
      font-size: 24px;
      padding-bottom: 15px;
      margin-bottom: 30px;
    }
  }
  &--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: $tablet-sm) {
     justify-content: space-between;
    }
    @media screen and (max-width: $mobile) {
      flex-wrap: nowrap;
    }
    .product {
      flex-basis: calc(50% - 15px);
      min-width: unset;
      max-width: 100%;
      margin-bottom: 60px;
      margin-right: 30px;
      @media screen and (max-width: $laptop-sm) {
        flex-basis: calc(50% - 8px);
        margin-right: 16px;
      }
      @media screen and (max-width: $mobile) {
        flex-basis: 100%;
        margin-right: unset;
        margin-bottom: 40px;
      }
      &:nth-child(2n+2) {
        margin-right: 0;
      }
      &__tags {
        flex-wrap: wrap;
      }
    }
  }
  .related-products-arrows{
    display: none;
    @media screen and (max-width: $mobile){
      display: flex;
      justify-content: center;
    }
    .related-products-arrow{
      display: inline-flex;
      border: 1px solid $black-light;
      border-radius: 4px;
      padding: max(17px, vw(22px));
      svg {
        display: flex;
        height: 12px;
        width: 6px;
      }
      &.prev{
        margin-right: 24px;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
  }
}

.notification {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 720px;
  padding: 5px 10px 5px 5px;
  background-color: $black-light;
  color: $white;
  border-radius: 20px;
  margin-bottom: 15px;
  @media screen and (max-width: $mobile){
    align-items: flex-start;
  }

  span.notification-icon {
    width: 27px;
    height: 27px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $red;
    border-radius: 50%;

    svg {
      display: inline-flex;
      width: 3px;
      height: 14px;
    }
  }

  .notification-text {
    width: 100%;
    font-size: 13px;
    padding-left: 10px;
    a {
      font-weight: 600;

      &:hover, &:focus, &:active {
        color: $white;
      }
    }
  }
}

.product__info {
  background-color: $gray-light;
  padding: 50px max(10px, vw(115px)) max(40px, vw(50px));
  @media screen and (min-width: ($tablet + 1)) {
    min-height: 342px;
  }
  @media screen and (max-width: $mobile) {
    padding-left: 10px;
    padding-right: 10px;
  }
  p{
    &:not(:last-child){
      margin-bottom: 0;
    }
  }
  &-wrapper{
    width: 100%;
    max-width: 1465px;
    margin: 0 auto;
    &-inner{
      width: 100%;
      max-width: 825px;
      margin-right: 75px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1480px){
        max-width: vw-rs(1480px, 825px);
      }
      @media screen and (max-width: 1366px){
        max-width: vw-rs(1366px, 720px);
      }
      @media screen and (max-width: $laptop-sm) {
        h2 {
          font-size: 32px;
          line-height: 1.2;
        }
      }
      @media screen and (max-width: $tablet) {
        max-width: 100%;
        margin-right: unset;
      }
      @media screen and (max-width: $mobile) {
        h2 {
          text-align: center;
        }
        .product__tags {
          display: flex;
          justify-content: center;
          margin: 15px 0 30px;
          font-size: 14px;
        }
        .commercial_message {
          text-align: center;
        }
      }
    }
      &>*{
        order: 4;
        @media screen and (max-width: $tablet) {
          &.breadcrumb{
            display: none;
          }
          &.product-title{
            order: 1;
          }
          &.product__tags{
            order: 2;
          }
        }
      }
      .commercial_message {
        margin: 10px 0;
      }
  }
}

.desktop {
  display: block;
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.tablet {
  display: none;
  @media screen and (max-width: $tablet) {
    display: block;
  }
}

.desktop-custom {
  display: block;
  @media screen and (max-width: 1580px) {
    display: none;
  }
}

.tablet-custom {
  display: none;
  @media screen and (max-width: 1580px) {
    display: block;
  }
}

/*add to cart message*/
@keyframes note {
  0% {margin-top:25px;opacity:0;}
  10% {margin-top:0;opacity:1;}
  90% {margin-top:0;opacity:1;}
  100% {margin-top:25px;opacity:0;}
}
//.popup-alert {
//  position: fixed;
//  top: 50%;
//  left: 50%;
//  -webkit-transform: translate(-50%, -50%);
//  -moz-transform: translate(-50%, -50%);
//  -o-transform: translate(-50%, -50%);
//  transform: translate(-50%, -50%);
//  padding: 50px;
//  margin-bottom: 0;
//  background-color: #fff;
//  color: #000;
//  border: none;
//  border-radius: 0;
//  z-index: 9999;
//  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
//  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
//  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
//  animation: note 6500ms forwards;
//}
//.popup-alert a,
//.popup-alert a:hover {
//  color: #000;
//  font-weight: 500;
//}

