$weight-normal: normal;
$weight-medium: 600;
$weight-bold: 700;

.title {
    // Ag: H1
    &__H1 {
        @include font($font-default, #{max(32px, vw(65px))}, $weight-bold, normal, #{max(38.98px, vw(79.17px))});
    }
    // Ag: H2
    &__H2 {
        @include font($font-default, #{max(24px, vw(50px))}, $weight-bold, normal, #{max(29.23px, vw(60.9px))});
    }
    // Ag: H3
    &__H3 {
        @include font($font-default, #{max(21px, vw(30px))}, $weight-bold, normal, #{max(25.58px, vw(36.54px))});
    }
}

.text {
    // Ag: big-bold
    &__big-bold {
        @include font($font-default, #{max(18px, vw(22px))}, $weight-bold, normal, #{max(21.92px, vw(26.8px))});
    }
    // Ag: big-semi
    &__big-semi {
        @include font($font-default, #{max(18px, vw(18px))}, $weight-bold, normal, #{max(21.92px, vw(21.92px))});
    }
    // Ag: paragraph
    &__paragraph {
        @include font($font-default, #{max(14px, vw(20px))}, $weight-normal, normal, #{max(21px, vw(34px))});
    }
    // Ag: small-titles-semi
    &__small-titles-semi {
        @include font($font-default, #{max(16px, vw(16px))}, $weight-medium, normal, #{max(19.49px, vw(19.49px))});
    }
    // Ag: normal
    &__normal {
        @include font($font-default, #{max(14px, vw(16px))}, $weight-normal, normal, #{max(21px, vw(24px))});
    }
    // Ag: small
    &--breadcrumbs,
    &__small {
        @include font($font-default, #{max(13px, vw(14px))}, $weight-normal, normal, #{max(15.83px, vw(17.05px))});
    }

    // ! Ag: Non Existent | Not in styleguide
    &__view-all {
		@include font($font-secondary, #{max(14px, vw(20px))}, $weight-medium, normal, #{max(22px, vw(24.36px))});
    }
}

.details {
    &__copyright {
        @include font($font-default, #{max(11px, vw(11px))}, $weight-normal, normal, 1);

        &--medium {
            @include font($font-weight: $weight-medium);
        }

        &--bold {
            @include font($font-weight: $weight-bold);
        }
    }
}

// ! ASSIGN UBOVE @EXTEND BELOW

body {
    @extend .text__paragraph;
}

$_heading:( // * edit key:value accordingly
    h1: "title__H1",
    h2: "title__H2",
    h3: "title__H3",
);

@each $heading-type,
$heading-value in $_heading {
    #{$heading-type} {
        @extend .#{$heading-value};
    }
}

.document-header {
    nav {
        &>ul {
            li {
                a {
                    span {
                        @extend .text__big-bold;
                    }
                }
            }
        }
    }
}

.document-footer {
    &__title {
        @extend .text__big-semi;
    }

    &__content {
        @extend .text__big-semi;
    }
}