.contact-container{
  .contact-title{
    text-align: center;
    margin: max(35px, vw(135px)) auto max(55px, vw(90px));
    @extend .title__H3;
    @media screen and (max-width: $tablet-sm){
      @include font($font-default, #{max(32px, vw(32px))}, $weight-bold, normal, 38.98px);
    }
  }
  .contact-box-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0 auto vw(80px);
    @media screen and (max-width: $tablet){
      padding: 0 10px;
    }
    @media screen and (max-width: $tablet-sm){
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
    .contact-box{
      border: 1px solid $black;
      width: 100%;
      max-width: 357px;
      padding: max(30px, vw(50px)) max(30px, vw(40px)) max(30px, vw(50px));
      @media screen and (max-width: $tablet-sm){
        padding: 30px;
        &:first-child{
          margin-bottom:20px;
        }
      }
      .line{
        background-color: #CF413D;
        height: 6px;
        width: 100px;
        margin-bottom: max(20px, vw(25px));
      }
      &-title{
        @extend .text__big-bold;
        margin-bottom: max(20px, vw(25px));
      }
      &-details{
        @include font($font-default, #{max(16px, vw(16px))}, $weight-normal, normal, #{max(21px, vw(24px))});
        &-mail,
        &-phone{
          display: flex;
          span{
            &:first-child{
              margin-right: 10px;
              @media screen and (max-width: $tablet-sm){
                margin-bottom: 10px;
              }
            }
          }
        }
        .icon{
          display: flex;
          width: 15px;
        }
        &-phone{
          .contact-telephones{
            a{
              &:hover,
              &:visited,
              &:focus{
                color: unset;
              }
            }
          }
        }
        p{
          margin: unset;
        }
      }
    }
  }
  .contact-form-container{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    @media screen and (max-width: $tablet){
      padding: 0 10px;
    }
    @media screen and (max-width: $tablet-sm){
      padding-top: 40px;
      background-color: #F4F5F6;
    }
    form{
      input{
        margin-bottom: 20px;
        padding: 20px 30px;
        border-radius: 0;
        border: 1px solid #1B1B1B;
        width: 100%;
        font-size: max(14px, vw(16px));
        @media screen and (max-width: $tablet-sm){
          font-size: 14px;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-style: normal;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-style: normal;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-style: normal;
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-style: normal;
        }
        &[type=submit]{
          margin-bottom: max(55px, vw(105px));
          display: flex;
          justify-content: center;
          background-color: $black;
          color: $white;
          &:hover{
            background-color: $white;
            color: $black;
          }
          @media screen and (max-width: $tablet-sm){
            font-size: 18px;
          }
        }
      }
      textarea{
        width: 100%;
        height: 165px;
        border: 1px solid $black;
        padding:  max(18.5px, vw(18.5px)) max(20px, vw(28px));
        margin-bottom: 25px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-style: normal;
          font-size: 16px;
          @media screen and (max-width: $tablet-sm){
            font-size: 14px;
          }
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-style: normal;
          font-size: 16px;
          @media screen and (max-width: $tablet-sm){
            font-size: 14px;
          }
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-style: normal;
          font-size: 16px;
          @media screen and (max-width: $tablet-sm){
            font-size: 14px;
          }
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-style: normal;
          font-size: 16px;
          @media screen and (max-width: $tablet-sm){
            font-size: 14px;
          }
        }
      }
    }
  }
  .map-section{
    background-color: #F4F5F6;
    .map-container{
      width: 100%;
      max-width: 986px;
      margin: 0 auto;
      padding: max(0px, vw(93px)) 0;
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
      @media screen and (max-width: $tablet-sm){
        padding: 0;
      }
      iframe{
        width: 100%;
        height: max(433px, vw(534px));
      }
    }
  }
}