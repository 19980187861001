// ! TRANSITION TIMINGS
@mixin transition($property : all,
    $time: .3s,
    $easing: ease-in-out,) {
    -webkit-transition: $property $time $easing;
    -moz-transition: $property $time $easing;
    -o-transition: $property $time $easing;
    transition: $property $time $easing;
}

// ! VIEWPORT CALCULATIONS
/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number)=='number'and not unitless($number) {
        $strip: ($number * 0 + 1);
        @return calc($number / $strip);
    }

    @return $number;
}

// Get vieport width from given pixels
@function vw($target) {
    $vw-context: (1920 * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vw);
}

@function vw-rs($screen, $target) {
    $vw-context: (strip-unit($screen) * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vw);
}

@function vh-rs($screen, $target) {
    $vw-context: (strip-unit($screen) * 0.01) * 1px;
    @return calc(($target / $vw-context) * 1vh);
}

// ! FONT RELATED ASSIGNMENTS
// * $font-family, $font-size, $font-weight, $font-style, $line-height, $letter-spacing
// * usage:
// * @include font($some-font, 24px, 500, normal, 30px, 2.56em);
// ? use interpolation for expressions e.g #{max(17px, vw(17px))}
// * To ignore order use declaration of any attribute
// * @include font($font-family:val, $font-size:val, $font-weight:val, $font-style:val, $line-height:val, $letter-spacing:val);
@mixin font($font-family: null,
    $font-size: null,
    $font-weight: null,
    $font-style: null,
    $line-height: null,
    $letter-spacing: null) {

    // * FONT FAMILY
    @if type-of($font-family) !=null {
        font-family: $font-family;
    }

    // * FONT SIZE
    @if type-of($font-size) !=null {
        font-size: $font-size;
    }

    // * FONT WEIGHT
    @if type-of($font-weight) !=null {
        font-weight: $font-weight;
    }

    // * FONT STYLE
    @if type-of($font-style) !=null {
        font-style: $font-style;
    }

    // * LINE HEIGHT
    @if type-of($line-height) !=null {
        line-height: $line-height;
    }

    // * LETTER SPACING
    @if type-of($letter-spacing) !=null {
        letter-spacing: $letter-spacing;
    }
}

// ! BUTTON
// ! BUTTON STRACTURE
// * $padding-y, $padding-x, $border-radius, $border-width, $border-style
@mixin buttonsStracture( //

    $padding-y: null,
    $padding-x: null,
    $border-radius: null,
    $border-width: null,
    $border-style: null,
    //

) {

    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1;

    @if type-of($padding-y) !=null {
        padding-top: $padding-y;
        padding-bottom: $padding-y;
    }

    @if type-of($padding-x) !=null {
        padding-left: $padding-x;
        padding-right: $padding-x;

    }

    @if type-of($border-radius) !=null {
        border-radius: $border-radius;
    }

    @if type-of($border-width) !=null {
        border-width: $border-width;
    }

    @if type-of($border-style) !=null {
        border-style: $border-style;
    }
}

// ! BUTTON STYLE
@mixin button($background,
    $color,
    $hover-background:null,
    $hover-color:null) {

    @include transition;
    background-color: $background;
    color: $color;

    @if $background==transparent {
        border-color: $color;
    }

    @else {
        border-color: $background;
    }

    @if type-of($hover-background) !=null and type-of($hover-color) !=null {
        &:hover {
            background-color: $hover-background;
            color: $hover-color;

            @if $hover-background==transparent {
                border-color: $hover-color;
            }

            @else {
                border-color: $hover-background;
            }
        }
    }
}

// ! INPUT
// ! INPUT STRACTURE
@mixin inputStracture( //

    $border-width: null,
    $border-style: null,
    $border-radius: null,
    $padding: null //

) {

    border-width: $border-width;
    border-style: $border-style;
    border-radius: $border-radius;
    padding: $padding;
    display: flex;
    width: 100%;
    white-space: nowrap;
    margin: 0;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

// ! INPUT STYLE
@mixin inputStyle( //

    $background-color,
    $text-color,
    $border-color,
    $hover-color: null,
    $error-color: null //

) {

    @include transition;
    background-color: $background-color;
    color: $text-color;
    border-color: $border-color;

    &::placeholder {
        @include transition;
        color: $text-color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        @include transition;
        color: $text-color;
    }

    &::-ms-input-placeholder {
        @include transition;
        color: $text-color;
    }

    @if type-of($hover-color) !=null {

        &:hover,
        &:active {
            color: $hover-color;
            border-color: $hover-color;

            &::placeholder {
                color: $hover-color;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $hover-color;
            }

            &::-ms-input-placeholder {
                color: $hover-color;
            }
        }

        &:focus {
            &::placeholder {
                color: transparent;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: transparent;
            }

            &::-ms-input-placeholder {
                color: transparent;
            }
        }
    }

    @if type-of($error-color) !=null {

        &.wpcf7-not-valid,
        &.has-error {
            color: $error-color;
            border-color: $error-color;

            &::placeholder {
                color: $error-color;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $error-color;
            }

            &::-ms-input-placeholder {
                color: $error-color;
            }
        }
    }
}

// ! SHORT TEXT
// Show n lines of text (...)
@mixin shortText($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$lines}; /* number of lines to show */
    line-clamp: #{$lines};
    -webkit-box-orient: vertical;
    visibility: visible;
}

// ! PREFIX PROPERTIES
/// Mixin to prefix several properties at once
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {

    @each $property,
    $value in $declarations {
        @each $prefix in $prefixes {
            #{'-' + $prefix + '-' + $property}: $value;
        }

        // Output standard non-prefixed declaration
        #{$property}: $value;
    }
}