#information-information {
  #content {
    padding-top: max(30px, vw(135px));
    h1 {
      text-align: center;
      margin-bottom: max(15px, vw(25px));
      @include font(
        $font-default,
        #{max(30px, vw(30px))},
        $weight-bold,
        normal,
        #{max(36.54px, vw(36.54px))}
      );
      @media screen and (max-width: $tablet-sm) {
        font-size: 32px;
        line-height: 38.98px;
      }
    }
    h4 {
      text-align: center;
      margin-bottom: max(45px, vw(100px));
      @include font(
        $font-default,
        #{max(18px, vw(20px))},
        $weight-medium,
        normal,
        #{max(21.92px, vw(24.36px))}
      );
      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
        line-height: 21.92px;
      }
    }
    .content-section {
      background-color: #f4f5f6;
      padding: 0 25px;
      .content-container {
        width: 100%;
        max-width: 945px;
        margin: 0 auto;
        padding: max(50px, vw(125px)) 0 max(90px, vw(265px));
        text-align: justify;
        @include font(
          $font-default,
          #{max(18px, vw(20px))},
          $weight-normal,
          normal,
          #{max(30px, vw(34px))}
        );
        @media screen and (max-width: $tablet-sm) {
          font-size: 14px;
          line-height: 21px;
        }
        .unsub-description {
          text-align: center;
          margin: 0 0 60px;
        }
        .unsubscribe-form {
          max-width: 520px;
          margin: 0 auto;
          .cta__unsubscribe {
            color: $black-light;
            background-color: $red;
            display: block;
            margin: 40px auto 0;

            &:hover {
              background-color: $red;
              color: $black-light;
              border-color: $gray;
              box-shadow: 0 0 30px -10px $red;
            }
          }
        }
      }
    }
    a {
      color: #ff0035;
    }
  }
}
