// * JS-GSAP FADE ANIMATIONS
.js-fade__in-delayed,
.js-fade__in {
  opacity: 0;
}

.js-fade__children-delayed,
.js-fade__children {
  > * {
    opacity: 0;
  }
}

// * SECTIONS
.section {
  &__default {
    padding-left: $spacing-sides;
    padding-right: $spacing-sides;
  }
}

.breadcrumbs-wrap {
  padding-top: max(30px, vw(50px));
  padding-left: max(15px, vw(60px));
  padding-right: max(15px, vw(60px));
  padding-bottom: max(10px, vw(30px));

  @media screen and (max-width: $tablet-sm) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
  border: none;
  background-color: transparent;

  li {
    padding: 0;

    a {
      display: inline-block;
      color: $gray;

      &::after {
        display: inline-block;
        content: "";
        background-image: url("../../../assets/icons/arrow-right-gray.svg");
        width: 8px;
        height: 8px;
        background-repeat: no-repeat;
        margin: 0 7px 0 10px;
      }
    }

    &:last-child {
      a {
        color: $black;

        &::after {
          display: none;
        }
      }
    }

    & + li {
      &:before {
        display: none;
      }
    }
  }
}

.info {
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: max(220px, vw(395px));
    max-width: max(220px, vw(395px));

    &:not(:last-child) {
      margin-right: max(10px, vw(30px));
    }

    &--image {
      margin-bottom: 17px;
    }

    &--content {
      @extend .text__small-titles-semi;
      text-align: center;
      max-width: 213px;
    }
  }
}

.product {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: max(340px, vw(395px));
  max-width: max(340px, vw(395px));

  &__image {
    margin-bottom: 17px;
  }

  &__heading {
    display: inline-flex;
    @extend .title__H3;
    &:hover,
    &:focus,
    &:active {
      color: $black-light;
    }
  }

  &__tags {
    @extend .text__small-titles-semi;
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > * {
      padding: 6px;
      margin-right: 10px;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $black-light;
      }
      a {
        &:hover,
        &:focus,
        &:visited {
          color: $black-light;
        }
      }
    }
  }

  &__content {
    @extend .text__normal;
    margin-bottom: 10px;
    min-height: max(63px, vw(72px));
    height: calc(2 * 1em * 1.5);
    overflow: hidden;
  }

  &__cta {
    @extend .text__small-titles-semi;
  }
}

.event {
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 395px;

  &__image {
    display: inline-flex;
  }

  &__heading {
    @extend .title__H3;
    &:hover,
    &:focus,
    &:active {
      color: $black-light;
    }
  }

  &__date {
    @extend .text__small-titles-semi;
    margin: 15px 0 25px;

    &:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $black-light;
    }
  }
}

.banner-block {
  &__wrap {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg, #d7d7d7 0%, #b9a1a1 99.2%);
    max-width: vw(1250px);
    margin: auto;
    border-radius: 8px;
    margin-bottom: vw(121px);

    @media screen and (max-width: $tablet) {
      flex-direction: column;
      align-items: center;
      max-width: 360px;
    }

    @media screen and (max-width: $mobile) {
      max-width: 100%;
      border-radius: unset;
      margin-bottom: unset;
    }
  }

  &__content {
    padding-top: vw(52px);
    padding-bottom: vw(51px);
    padding-left: max(33px, vw(90px));

    @media screen and (max-width: $tablet) {
      order: 2;
      padding: unset;
      padding-bottom: 64px;
    }

    @media screen and (max-width: $mobile-sm) {
      padding-left: 33px;
      padding-right: 33px;
      align-self: flex-start;
    }

    &--title-pre {
      @extend .text__small;
    }

    &--title {
      @extend .title__H2;
      margin-bottom: 12px;
    }

    &--list {
      margin-bottom: 28px;

      li {
        @extend .text__normal;
        margin-left: 22px;
        margin-bottom: 4px;

        &:before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.19369 7.62838L4.41361 7.41436L10.1486 1.88277C10.3366 1.66708 10.6037 1.53124 10.893 1.50412C11.0874 1.48618 11.2828 1.52716 11.4524 1.62147C11.622 1.71578 11.7575 1.85879 11.8403 2.03093C11.9443 2.17882 12 2.35385 12 2.53306C12 2.71227 11.9443 2.88729 11.8403 3.03518C11.7819 3.13381 11.7076 3.22273 11.6204 3.29859L5.07339 9.57102C4.97827 9.70348 4.8517 9.81164 4.70444 9.88632C4.55719 9.961 4.39358 10 4.22752 10C4.06147 10 3.89786 9.961 3.7506 9.88632C3.60334 9.81164 3.47678 9.70348 3.38166 9.57102C2.36662 8.5997 1.35158 7.62838 0.353452 6.64059C0.179679 6.49982 0.0611967 6.30497 0.0180406 6.08898C-0.0251154 5.873 0.00970355 5.64913 0.116609 5.45525C0.18771 5.30384 0.297608 5.17275 0.435756 5.07456C0.573905 4.97637 0.735666 4.91439 0.905517 4.89456C1.07537 4.87472 1.24761 4.8977 1.4057 4.96129C1.5638 5.02488 1.70245 5.12695 1.80834 5.2577L3.99068 7.43082C4.06706 7.48761 4.13533 7.55405 4.19369 7.62838V7.62838Z' fill='%231B1B1B'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          display: inline-flex;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 0;
          left: -22px;
          transform: translateY(50%);
        }
      }
    }

    &--cta {
      @media screen and (max-width: $mobile) {
        .cta__default {
          @include button(
            $black-light !important,
            $white !important,
            $white !important,
            $black-light !important
          );
        }
      }
    }
  }

  &__image {
    max-width: max(360px, vw(689px));
    margin-top: 8px;
    margin-bottom: 4px;
    // margin-right: 38px;
    display: flex;
    align-items: center;

    @media screen and (max-width: $tablet) {
      order: 1;
      margin-top: 70px;
      margin-bottom: 60px;
    }
  }
}

.icon {
  &--marketing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    font-size: 35px;

    &:before {
      content: "";
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $white;
      border-radius: 100px;
    }
  }
}

.industry {
  &--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 34px;
    height: 34px;
    margin-right: 13px;

    @media screen and (max-width: $mobile) {
      margin-right: unset;
      margin-bottom: 8px;
    }

    &:before {
      content: "";
      width: 34px;
      height: 34px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $gray-light;
      border-radius: 34px;
    }

    svg {
      path {
        @include transition;
      }
    }
  }
}
.modal .modal-title {
  position: static;
}