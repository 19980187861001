// * CALL TO ACTIONS
.cta {
    &__default {
        @include buttonsStracture($cta-padding-y, $cta-padding-x, $cta-border-radius, $cta-border-width, $cta-border-style);
        @include button($cta-background-color, $cta-text-color, $cta-background-color-hover, $cta-text-color-hover);
        @extend .text__small-titles-semi;

        &.theme {
            &--white {
                @include button($white, $black-light, $black-light, $white);
            }

            &--red {
                @include button($red, $white, $black-light, $white);
            }

            &--white-border {
                @include button($white, $black-light, $black-light, $white);
                border: 1px solid $black-light;
            }
        }
        &-lg {
            padding-bottom: 20px;
            padding-top: 20px;
            line-height: max(21px, vw(24px));
        }
    }

    &__price {
        @include buttonsStracture(12.5px, 15px, unset, 2px, $cta-border-style);
        @include button($cta-background-color, $cta-text-color, $hover-color: $cta-text-color);

        &:before,
        &:after {
            @include transition;
            @include buttonsStracture(12.5px, 15px, unset, 2px, $cta-border-style);
            @include button($cta-background-color, $cta-text-color);
            border-left-color: #3D3D3D;
            position: absolute;
            top: -2px;
            right: 0;
            height: calc(100% + 4px);
            transform: translateX(100%);
        }

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L8 8L0 16V0Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 8px;
            background-position: center;
        }

        &:after {
            content: attr(data-content);
            z-index: 1;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }

        &[data-content]:not([data-content=""]) {
            &:hover {
                &:before {
                    background-image: unset;
                }

                &:after {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    &__view-all {
        @extend .text__view-all;

        &:after {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='10' viewBox='0 0 30 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 4.76898L25.231 0L25.2487 3.76172H0V5.64257H25.2575L25.2756 9.49341L30 4.76898Z' fill='%231B1B1B'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-flex;
            width: max(20px, vw(30px));
            height: max(6.33px, vw(10px));
            margin-left: max(5px, vw(21px));
        }
    }

    &--account {
        @include buttonsStracture(#{max(24px, vw(25.5px))}, #{max(24px, vw(25.5px))}, 0, 1px, solid);
        @include button($black-light, $cta-text-color !important, transparent, $black-light !important);
        display: flex;
        width: 100%;
        max-width: 570px;
        margin: auto;
        text-decoration: none !important;

        &-small {
            @include buttonsStracture(#{max(12px, vw(12px))}, #{max(12px, vw(12px))}, 0, 1px, solid);
            @include button($white, $black, transparent, $white);
            width: 100%;
        }
    }
}