.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(var(--screen-height) - var(--header-height));

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 910px;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;

    & > * {
      color: $gray;
      &:not(:last-child) {
        margin-bottom: max(15px, vw(30px));
      }
    }
  }

  &__logo-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: inline-block;
    svg {
      display: inline-block;
      path {
        fill: #000;
      }
    }
  }

  &__title {
    @extend .title__H1;
    color: $red;
  }

  &__subtitle,
  &__content,
  &__prompt {
    @extend .text__big-bold;
  }

  &__cta {
    & > a {
      color: $black-light;
      background-color: $red;
      color: $black-light;

      &:hover {
        background-color: $red;
        color: $black-light;
        border-color: $gray;
        box-shadow: 0 0 30px -10px $red;
      }
    }
  }
}
