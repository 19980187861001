// * INTERACTION
$interaction-text-color: #ffffff !default;
$interaction-background-color: #000000 !default;

// * BODY DEFAULT COLORS
$body-background-color: #ffffff !default;
$body-text-color: #000000 !default;

// * RESET
*,
*::before,
*::after {
    position: relative;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

*:visited,
*:focus,
*:active {
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

::-moz-selection {
    color: $interaction-text-color;
    background: $interaction-background-color;
}

::selection {
    color: $interaction-text-color;
    background: $interaction-background-color;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

html,
body {
    height: auto;
    width: 100%;
}

body {
    background-color: $body-background-color;
    color: $body-text-color;
    margin: 0;
}

main {
    display: flex;
    flex-direction: column;
}

@for $index from 1 through 6 {
    h#{$index} {
        margin: unset;
    }
}

p {
    margin: unset;
    margin-bottom: 15px;
}

a {
    color: inherit;
    outline: none;
    text-decoration: none;
    cursor: pointer;

    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    &:hover {
        color: inherit;
    }
}

b,
strong {
    font-weight: bold;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}

img,
picture {
    border: none;
    height: auto;
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
}

svg {
    display: inline-block;
}
// * RESET END