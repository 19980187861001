// select2
$s2-radius: 0;
$s2-hover-selected-bg: $gray;
$s2-hover-selected-color: $black-light;
$s2-opened-top-bg: $white;
$s2-border-color: $black-light;
$s2-opened-top-border-color: $gray-light;
$s2-opened-bottom-border-color: $gray-light;
$s2-opened-top-radius: 0;
$s2-opened-bottom-radius: 0;
$s2-opened-bottom-padding: 0;
$s2-font-line-height: #{max(21px, vw(24px))};

.select2-container {
    display: block;

    &--default {

        .select2 {

            &-selection {

                &--single {
                    @include inputStracture(1px, solid, $s2-radius, 20px max(20px, vw(28px)));
                    @extend .text__normal;
                    line-height: $s2-font-line-height;
                    border-color: $s2-border-color;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    height: unset;
                    background-color: transparent;
                    color: $black;

                    .select2-selection__arrow {
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 20px;
                        width: 11px;
                        height: 100%;

                        b {
                            margin-top: unset;
                            // border-color: $gray-dark transparent transparent transparent;
                            // border-width: 6px 5px 0 5px;
                            transform: translate(-50%, -50%);
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m10.4.4-5 5.9-5-6'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            border: unset;
                            width: 11px;
                            height: 11px;
                            transform: rotate(-90deg) translatex(5px) scale(.9);
                            opacity: .5;
                        }
                    }
                }

                &__rendered {
                    color: $gray-dark !important;
                    padding: 0 !important;
                    line-height: inherit !important;
                }
            }

            &-results__option {
                @extend .text__big-semi;
                $shared-padding: 15px 18px;
                $shared-margin: 0;
                margin-left: $shared-margin;
                margin-right: $shared-margin;
                padding: $shared-padding;

                &:hover,
                &--selected {
                    background-color: $s2-hover-selected-bg !important;
                    color: $s2-hover-selected-color !important;
                    margin-left: $shared-margin;
                    margin-right: $shared-margin;
                    border-radius: $s2-radius;
                    padding: $shared-padding;
                }

                &--highlighted {
                    &.select2-results__option--selectable {
                        background-color: $s2-hover-selected-bg !important;
                        color: $s2-hover-selected-color !important;
                        margin-left: $shared-margin;
                        margin-right: $shared-margin;
                        border-radius: $s2-radius;
                        padding: $shared-padding;
                    }
                }
            }
        }

        &.select2-container--open {
            .select2 {
                &-selection {
                    &--single {
                        border-radius: $s2-opened-top-radius;
                        background-color: $s2-opened-top-bg !important;
                        border: 1px solid $s2-opened-top-border-color;

                        .select2-selection__arrow b {
                            // border-color: transparent transparent $black transparent !important;
                            // border-width: 0 5px 6px 5px !important;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m.4 6.2 5-5.9 5 6'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            border: unset;
                            width: 11px;
                            height: 11px;
                            transform: rotate(-180deg) translatey(7px) scale(.9);
                        }

                        .select2-selection__rendered {
                            color: $gray-dark !important;
                        }
                    }
                }
            }

            .select2 {
                &-dropdown {
                    background-color: $gray-light;
                    &--below {
                        border-color: $s2-opened-bottom-border-color;
                        border-bottom-left-radius: $s2-opened-bottom-radius;
                        border-bottom-right-radius: $s2-opened-bottom-radius;
                        padding: $s2-opened-bottom-padding;
                    }

                    &--above {
                        border-color: $s2-opened-bottom-border-color;
                        border-top-left-radius: $s2-opened-top-radius;
                        border-top-right-radius: $s2-opened-top-radius;
                        padding: $s2-opened-bottom-padding;
                    }
                }
            }
        }
    }
}

.js-select2-custom {
    &.select2-container {
        width: 150px !important;

        &--default {

            .select2 {

                &-selection {

                    &--single {
                        @extend .text--breadcrumbs;
                        padding: 0;
                        border: unset;
                    }
                }
            }
        }
    }

    &-dropdown {
        width: 200px;
        border: none;
        box-shadow: 10px 10px 15px -10px #000;
        margin-left: -20px;

        .select2 {

            &-results__option {
                @extend .text--breadcrumbs;
                margin: 10px 0;
                padding: 0;
                margin-left: 28px !important;

                &:hover,
                &--selected {
                    background-color: transparent !important;
                    text-decoration: underline;
                    color: $black !important;
                    margin-left: unset;
                    margin-right: unset;
                    border-radius: unset;
                    padding: unset;
                }

                &--highlighted {
                    &.select2-results__option--selectable {
                        background-color: transparent !important;
                        text-decoration: underline;
                        color: $black !important;
                        margin-left: unset;
                        margin-right: unset;
                        border-radius: unset;
                        padding: unset;
                    }
                }
            }
        }
    }
}

.select2-results__options {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 6px;
        position: absolute;
    }

    &::-webkit-scrollbar-track {
        @include prefix((box-shadow: inset 0 0 6px transparent), webkit moz);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        @include prefix((box-shadow: inset 0 0 6px $red), webkit moz);
        background-color: $red;
        border-radius: 4px;
    }
}