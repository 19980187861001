@use "sass:math";
/*========================================================*/
/* Header style_m */
/*========================================================*/

%reset-header-svg {
  height: auto;
  display: block;
}
.header {
  z-index: 10;
  border-bottom: 1px solid var(--gray);
  padding: max(13px, vw(24px)) 0;
  background: #fff;
  .menu-is-open & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
//   @media (min-width: $tablet-lg) {
//     padding: 24px 0;
//   }
  .site-container {
    &__inner {
      display: flex;
      align-items: center;
      @media screen and (min-width: $tablet-lg) {
        align-items: center;
      }
    }
  }
  &__logo,
  &__menu--secondary {
    z-index: 4;
    @media (min-width: $tablet-lg) {
      width: 33.333%;
    }
    @media (min-width: $laptop) {
      width: 25%;
    }
  }
  &__menu--main {
    z-index: 3;
    order: 3;
    @media screen and (min-width: $tablet-lg) {
      order: unset;
      flex: 1;
    }
  }
  &__logo {
    a {
      float: left;
    }
    svg {
      width: max(169px, vw(265px));
	  max-height: max(39px, vw(61px));
      @extend %reset-header-svg;
    }
  }
  &__menu {
    color: $black-light;
    a {
      text-decoration: none;
    }
    > ul {
      display: flex;
      align-items: center;
    }
    &--main {
      font-size: max(18px, vw-rs(360, 18px));
      line-height: 1.23;
      @media screen and (min-width: $tablet-lg) {
        font-size: max(18px, vw(22px));
      }
    }
    &--secondary {
      margin-left: auto;
      font-size: max(16px, vw(16px));
      line-height: 1.4375;
      > ul {
        justify-content: flex-end;
        > li {
          &:not(:last-child) {
            margin-right: 1.375em;
          }
        }
      }
    }
  }

  .header-account-link {
    a {
      float: left;
    }
    svg {
      width: 24px;
      @media screen and (min-width: $tablet-lg) {
        width: max(22px, vw(22px));
      }
      @extend %reset-header-svg;
    }
  }
  a {
    // todo
    &:hover {
      color: inherit;
    }
  }
}

.page_checkout .upcoming-subscriptions,
.page_order_subscriptions .upcoming-subscriptions {
  display: none;
}