.category-list {
  &__search {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: max(20px, vw(20px));
    margin-bottom: max(20px, vw(20px));
    padding-right: 0;
    padding-left: 0;
  }
  &__search-input {
    @extend %site-container-children;
    margin-bottom: 1em;
    flex: 0 1 100%;
    @media screen and (min-width: $tablet-sm) {
      flex: 0 1 33.333%;
    }
    order: 3;
    &:first-child {
      order: 1;
    }
    &--last {
      flex: 0 1 100%;
      order: 2;
      @media screen and (min-width: $tablet-sm) {
        order: 3;
      }
    }
  }
  .filter-with-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    label {
      padding-right: 1em;
    }
  }
  #button-search{
    background-color: $black;
    &:hover{
      background-color: $white;
    }
  }
}