/*========================================================*/
/* Homepage style_m */
/*========================================================*/

/*home categories*/
.home-categories-container {
    margin: 75px auto;
}
.categories-wrapper.flex-row {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
}
.categories-wrapper .category-item {
    position: relative;
}
.categories-wrapper .category-item a {
    color: #000;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    padding-bottom: 70px;
}
.categories-wrapper .category-item:first-child {
    margin-right: 15px;
}
.categories-wrapper .category-item:last-child {
    margin-left: 15px;
}
.categories-wrapper .category-item .category-info {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 30px 25px 25px;
    background-color: rgba(255, 255, 255, 1);
    width: calc(100% - 8.333vw);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.categories-wrapper .category-item a:hover .category-info {
    bottom: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.categories-wrapper .category-item .title {
    justify-content: space-between;
    align-items: center;
}
.categories-wrapper .category-item .title h3 {
    max-width: calc(100% - 120px);
}
.categories-wrapper .category-item .title .category-btn {
    font-size: 1.4rem;
    font-weight: 600;
}
.categories-wrapper .category-item .title .category-btn:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #000;
    position: relative;
    bottom: -3px;
}
/*home carousel tabs*/
.carousel-container {
    margin: 100px auto;
    padding: 0 30px;
}
.carousel-container .nav-tabs {
    text-align: center;
    border-bottom: none;
    margin-bottom: 30px;
}
.carousel-container .nav-tabs > li {
    float: unset;
    display: inline-block;
}
.carousel-container .nav-tabs > li a {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #000;
    border: none;
    padding: 10px 25px;
    margin: 0;
}
.carousel-container .nav-tabs > li.active a {
    font-size: 2.4rem;
    line-height: 2;
    font-weight: 600;
    color: #000;
    border: none;
}
.carousel-container .nav-tabs > li a:focus,
.carousel-container .nav-tabs > li a:hover {
    background-color: transparent;
}
.carousel-container .nav-tabs > li.active a:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #000;
    position: relative;
    bottom: -3px;
}

/*carousel slick dots*/
.carousel-container .tab-content .tab-pane {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
}
.carousel-container .tab-content .tab-pane.active {
    height: auto;
}
.carousel-container .tab-content .tab-pane .carousel-wrapper {
    position: relative;
    padding-bottom: 55px;
}
.carousel-container .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.carousel-container .slick-dots li {
    display: inline-block;
    margin: 0 10px;
}
.carousel-container .slick-dots li button {
    font-size: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
    background-color: #c8c8c9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
}
.carousel-container .slick-dots li.slick-active button {
    background-color: #000;
}

/*carousel slick arrows */
.carousel-arrows {
    display: none;
}
.show-slider-nav + .carousel-arrows {
    display: block;
}
.carousel-container .carousel-arrows {
    position: absolute;
    left: 50%;
    bottom: 15px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 190px;
    width: 100%;
}
.carousel-container .carousel-arrows .arrow,
.carousel-container .carousel-arrows .arrow svg {
    display: block;
    width: 30px;
    height: 9px;
    cursor: pointer;
}
.carousel-container .carousel-arrows .arrow {
    position: absolute;
}
.carousel-container .carousel-arrows .arrow.next {
    right: 0;
}

/*home more categories*/
.more-category-wrapper.flex-row {
    justify-content: center;
}
.home-more-categories {
    padding: 0 15px;
    padding-bottom: 40px;
}
.home-more-categories h2 {
    margin-bottom: 50px;
}
.home-more-categories .category-item:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.more-category-wrapper .category-item {
    position: relative;
    margin: 0 15px;
}
.more-category-wrapper .category-info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 25px 30px;
    width: 100%;
    z-index: 1;
}
.more-category-btn {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    padding: 15px 25px;
    position: relative;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.category-item a:hover .more-category-btn {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.more-category-wrapper .category-item img {
    align-self: center;
}

@media (max-width: $laptop-m) {
    /*homepage*/
    .more-category-wrapper .category-info {
        padding: 20px;
    }
    .more-category-wrapper .category-info h3 {
        font-size: 2.5rem;
    }
    .more-category-btn {
        padding: 10px 20px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 2rem;
    }
}

@media (max-width: $tablet-lg) {
    /*homepage*/
    .home-categories-container {
        margin: 25px auto 75px;
    }
    .categories-wrapper .category-item .category-info {
        width: calc(100% - 3.333vw);
        padding: 20px;
    }
    .more-category-wrapper.flex-row {
        flex-wrap: wrap;
    }
    .more-category-wrapper .category-item {
        margin-bottom: 30px;
        width: calc(50% - 30px);
    }
    .more-category-wrapper .category-item a {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: $tablet) {
    /*homepage*/
    .home-categories-container {
        margin-bottom: 55px;
    }
    .categories-wrapper .category-item .title h3 {
        font-size: 1.5rem;
        max-width: 100%;
    }
}

@media (max-width: $tablet-sm) {
    /*homepage*/
    .categories-wrapper.flex-row {
        flex-direction: column;
        padding: 0 15px;
    }
    .home-categories-container {
        margin-bottom: 35px;
    }
    .categories-wrapper .category-item:first-child,
    .categories-wrapper .category-item:last-child {
        margin: 0 auto;
    }
    .categories-wrapper .category-item + .category-item {
        margin-top: 15px;
    }
    .categories-wrapper .category-item .category-info {
        width: calc(100% - 15vw);
        padding: 10px;
        text-align: center;
    }
    .categories-wrapper .category-item .title h3 {
        margin: 5px auto;
    }
    .categories-wrapper .category-item .title {
        flex-direction: column;
    }
    .home-more-categories {
        padding-bottom: 0;
    }
    .home-more-categories h2 {
        font-size: 2rem;
    }
    .more-category-wrapper .category-item {
        width: calc(50% - 30px);
        margin: 0 auto 15px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 1.6rem;
        padding: 15px;
    }
}

@media (max-width: $mobile) {
    /*homepage*/
    .carousel-container {
        margin-top: 20px;
        padding: 0 15px;
    }
    .carousel-container .nav-tabs {
        margin-bottom: 20px;
    }
    .carousel-container .nav-tabs > li a {
        font-size: 1.5rem;
        padding: 10px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 1.6rem;
    }
}
