// ! DEDICATED TYPOGRAPHY

.js-show {
    opacity: 1 !important;
    @include transition;
}

.default_top_slider--height {
    height: calc(100vh - var(--header-height));
}

.default__slider {

    &__wrap {
        @extend .default_top_slider--height;
        display: flex;
        // opacity: 0;
    }

    &__slider {
        width: 100%;
    }

    &__sliderwrap {}

    &__item {

        &--image {
            @extend .default_top_slider--height;


            img {
                @extend .default_top_slider--height;
                object-fit: cover;
            }
        }

        &__display {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &--title,
            &--content {
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                padding-left: $spacing-sides;
                padding-right: $spacing-sides;
                max-width: calc(575px + (var(--spacing-sides) * 2));
                text-align: center;
            }

            &--title {
                // @extend .default-slider-title;
                color: #ff0000;
                margin-bottom: max(30px, vw(38px));
            }

            &--content {
                // @extend .default-slider-content;
                color: $white;
            }

            &--icon {
                width: 100%;
                max-width: max(273px, vw(535px));
                margin-bottom: max(30px, vw(38px));

                &-logo {
                    width: 100%;
                    max-width: max(178px, vw(278px));
                    margin-bottom: 70px;
                    margin-top: -170px;
                }
            }
        }
    }

    &__pagination {
        z-index: 1;
        position: absolute;
        width: unset !important;
        left: unset !important;
        bottom: unset !important;
        right: max(15px, vw(125px));
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $tablet-sm) {
            display: none;
        }

        .swiper-pagination-bullet {
            display: inline-flex;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background-color: unset;
            opacity: .5;
            cursor: pointer;
            border: 1px solid $black-light;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            &-active {
                opacity: 1;
                background-color: $black-light;
            }
        }
    }

    &__abstract {
        position: absolute;
        z-index: 2222;
        width: max(52px, vw(80px));
        bottom: 7px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.tiles-marketing,
.tiles-magazines,
.tiles-newsletters,
.tiles-bundles,
.tiles {

    &__slider {
        width: 100%;

        &wrap {
            display: flex;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-right: max(10px, vw(30px));
        }
    }

    &__pagination {
        z-index: 1;
        position: relative;
        width: 100%;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: max(41px, vw(82px));

        .swiper-pagination-bullet {
            display: inline-flex;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background-color: unset;
            opacity: .5;
            cursor: pointer;
            border: 1px solid $black-light;

            @media screen and (max-width:$tablet-sm) {
                width: 14px;
                height: 14px;
                border-color: #D9D9D9;
                background-color: #D9D9D9;
            }

            &:not(:last-child) {
                margin-right: 12px;

                @media screen and (max-width:$tablet-sm) {
                    margin-right: 6px;
                }
            }

            &-active {
                opacity: 1;
                background-color: $black-light;

                @media screen and (max-width:$tablet-sm) {
                    background-color: $gray-dark;
                }
            }
        }
    }
}

.tiles-magazines,
.tiles-newsletters,
.tiles-bundles {
    &__pagination {
        @media screen and (max-width: $mobile) {
            display: none;
        }
    }
}

.tile-navigation {
    display: flex;
    align-items: center;
    margin: auto;
    z-index: 1;
    padding-top: max(41px, vw(82px));

    .swiper-button-disabled {
        opacity: .5;
    }

    @media screen and (min-width: ($mobile + 1)) {
        display: none;
    }

    &>* {
        cursor: pointer;
        pointer-events: all;
        padding: 15px 20px;
        background-repeat: no-repeat;
        background-size: contain;
        width: 46px;
        height: 46px;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &:first-child {
            background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M25.756 28.696c.159-.187.248-.44.248-.704 0-.264-.09-.517-.248-.704L22.09 23l3.659-4.279c.16-.19.25-.446.25-.713 0-.267-.09-.523-.25-.713a.81.81 0 0 0-.61-.292.81.81 0 0 0-.61.292l-4.276 4.992c-.162.19-.253.449-.253.718 0 .269.09.527.253.718l4.276 5.001c.168.183.39.282.618.276a.814.814 0 0 0 .61-.304Z' fill='%231B1B1B'/%3E%3C/g%3E%3Crect x='.5' y='.5' width='45' height='45' rx='3.5' stroke='%231B1B1B'/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(90 4.5 21.5)' d='M0 0h12v6H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }

        &:last-child {
            background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M20.244 17.304c-.159.187-.248.44-.248.704 0 .264.09.517.248.704L23.91 23l-3.659 4.279c-.16.19-.25.446-.25.713 0 .267.09.523.25.713a.81.81 0 0 0 .61.292.81.81 0 0 0 .61-.292l4.276-4.992c.162-.19.253-.449.253-.718 0-.269-.09-.527-.253-.718l-4.276-5.001a.811.811 0 0 0-.618-.276.814.814 0 0 0-.61.304Z' fill='%231B1B1B'/%3E%3C/g%3E%3Crect x='45.5' y='45.5' width='45' height='45' rx='3.5' transform='rotate(-180 45.5 45.5)' stroke='%231B1B1B'/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(-90 24.5 4.5)' d='M0 0h12v6H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
    }
}

.swiper-pagination-lock {
    display: none;
}