.footer-container {
  background-color: $black;
  color: $white;
  padding-left: 15px;
  padding-right: 15px;
  .logo-container{
    display: flex;
    width: 100%;
    max-width: 200px;
    padding: max(50px, vw(114px)) 0 max(65px, vw(107px));
    margin: 0 auto;
    svg {
      padding: 5px;
    }
  }
  .newsletter-container{
    border: 1px solid #fff;
    width: 100%;
    max-width: 780px;
    margin: 0 auto max(65px, vw(100px));
    @media screen and (max-width: $tablet){
     border: none;
      margin-bottom: 65px;
    }
    h3{
      text-align: center;
      margin-top: max(0px, vw(40px));
      @media screen and (max-width: $tablet){
        margin-top: 0;
      }
    }
    .newsletter-input-container{
      display: flex;
      width: 100%;
      max-width: 650px;
      justify-content: center;
      margin: 40px auto;
      @media screen and (max-width: $tablet-sm) {
        display: block;
        margin: 30px auto 65px;
      }
      input{
        background-color: #fff;
        color: #D0D0D0;
        padding: 20px max(15px, vw(40px));
        width: 460px;
        border-radius: 0;
        border: 2px solid #fff;
        font-size: max(14px, vw(20px));
        line-height: 1;
        @media screen and (max-width: $tablet-sm) {
          width: 100%;
          margin-bottom: 10px;
          padding: 20px 15px;
          font-size: 14px;
        }
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #D0D0D0;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #D0D0D0;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #D0D0D0;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #D0D0D0;
      }
      button{
        width: 185px;
        border: 2px solid $white;
        background: $black;
        color: $white;
        font-size: 20px;
        @include transition();
        &:hover{
          background-color: $white;
          color: $black;
          border-color: $black;
        }
        @media screen and (max-width: $tablet-sm) {
          width: 100%;
          padding: 20px 0;
          font-size: 18px;
        }
      }
    }
  }
  .footer-cols-container{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto 90px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $tablet-sm){
      flex-direction: column;
      padding: 0px 5px;
      text-align: center;
    }
    .footer-col-1{
      max-width: vw(307px);
      .footer-col-title{
        &:last-of-type{
          margin-top: 40px;
          //@media screen and (max-width: $tablet-sm){
          //  margin-top: 40px;
          //}
        }
      }
    }
    .footer-col{
      @media screen and (max-width: $tablet-sm){
        max-width: unset;
        margin: 0 0 40px 0;
      }
      .footer-col-title{
        color: #A6A8AA;
        text-transform: uppercase;
        @include font($font-default, #{max(18px, vw(18px))}, $weight-bold, normal, 21.92px);
        margin-bottom: 40px;
        @media screen and (max-width: $tablet-sm){
          margin-bottom: 25px;
        }
      }
      p{
        @include font($font-default, #{max(18px, vw(18px))}, $weight-bold, normal, 21.92px);
        @media screen and (max-width: $tablet-sm){
          font-weight: $weight-medium;
          margin-bottom: 5px;
        }
        .grey-letter-wrapper{
          @media screen and (max-width: 1505px){
            display: block;
          }
          .grey-letter{
            color: #A6A8AA;
            text-transform: uppercase;
          }

        }
        a{
          display: inline-block;
          &:hover,
          &:visited,
          &:focus{
            color: unset;
          }
        }
      }
    }
  }
  .footer-copyrights{
    padding: 0 max(0px, vw(305px)) max(40px, vw(55px));
    color: #A6A8AA;
    font-size: 16px;
    @media screen and (max-width: $tablet-sm){
      padding: 0 0 40px;
    }
    p{
      @media screen and (max-width: $tablet-sm){
        text-align: center;
      }
    }
  }

  .footer-social-icons{
    display: inline-block;
    &:not(:last-child){
      margin-right: 10px;
    }
  }
}

#subscribe_result{
  text-align: center;
  margin: 0 auto 20px;
}

#subscribe_result span{
  text-align: center;
  padding: 5px;
}