/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
@import 'select2/dist/css/select2.css';

.category-list {
    &__header {
        margin-top: max(14px, vw(48px));
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media screen and (min-width: $tablet-sm) {
            &::after {
                content: "";
                display: block;
                width: 100%;
                margin-top: max(17px, vw(20px));
                border-bottom: 1px solid $black-light;
            }
        }
    }
    &__title {
        width: 100%;
        text-align: center;
        &::after {
            content: "";
            display: block;
            width: 100%;
            margin: max(17px, vw(20px)) 0;
            border-bottom: 1px solid $black-light;
        }
        @media screen and (min-width: $tablet-sm) {
            text-align: left;
            width: auto;
            &::after {
                display: none;
            }
        }
    }
    &__filters-header {
        margin-left: auto;
        width: 100%;
        @media screen and (min-width: $tablet-sm) {
            width: auto;
        }
    }
    &__products {
        display: flex;
        flex-wrap: wrap;
        margin-top: max(20px, vw(70px));
        margin-bottom: max(80px, vw(195px));
        padding-right: 0;
        padding-left: 0;

    }
    &__product {
        .product {
            min-width: auto;
            max-width: none;
        }
        margin-top: max(50px, vw(60px));
        @extend %site-container-children;
        width: 100%;
        &:first-child {
            margin-top: 0;
        }

        @media screen and (min-width: $mobile){
            width: 50%;
            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @media screen and (min-width: $tablet-lg) {
            width: 33.333%;
            &:nth-child(3) {
                margin-top: 0;
            }
        }

        @media screen and (min-width: $laptop) {
            width: 25%;
            &:nth-child(4) {
                margin-top: 0;
            }
        }
    }
    &__filters-header {
        // START: hide unnecessary nodes
        .close-filters,
        .bf-btn-show,
        .box-heading,
        .bf-attr-header {
            display: none !important;
        }
        // END: hide unnecessary nodes

        // START: reset Brainy filters styles
        .bf-form {
            display: flex;
            .bf-attr-block {
                width: 100%;
            }
            .bf-buttonclear-box {
                //display: none;
                width: 1.5em;
                height: 1.5em;
                flex: 0 0 1.5em;
                margin: -0.75em 0 0 -0.75em;
                padding: 0;
                overflow: hidden;
                border: 0.075em solid $gray-dark;
                border-radius: 50%;
                background: #fff;
                &::before,
                &::after {
                    content: "";
                    display: block;
                    width: 60%;
                    height: 0.075em;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background: $gray-dark;
                    pointer-events: none;
                }
                &::before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &::after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                .bf-buttonclear {
                    cursor: pointer;
                    background: transparent;
                    text-decoration: none;
                    min-height: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 0;
                    opacity: 0;
                }
            }
        }
        .bf-check-position {
            border: 0 none;
            border-radius: 0;
            margin-bottom: 0;
        }
        .bf-attr-block-cont {
            padding: 0;
        }
        .bf-cell {
            border-bottom: 0 none !important;
            display: block;
            padding: 0;
            height: auto;
        }
        // END: reset Brainy filters styles
        select {
            display: none;
        }
    }
}
.category-filters.select2-container {

    width: 100%;
    @media screen and (min-width: $tablet-sm ) {
        width: auto;
    }
    .select2-selection {
        min-width: 100%;
        @media screen and (min-width: $tablet-sm) {
            min-width: max(188px, vw(188px));
        }
        border: 1px solid var(--gray-light);
        border-radius: 2px;
        height: auto;
        padding: 20px calc(max(20px, vw(20px)) + 33px) 20px max(20px, vw(20px));
        &__rendered {
            color: $black-light;
            @include font($font-default, #{max(16px, vw(16px))}, $weight-medium, normal, #{max(19.49px, vw(19.49px))});
        }
        &__placeholder {
            color: $black-light;
        }
    }
    &--open {
        right: 0;
        left: unset !important;
    }
    .select2-dropdown {
        left: unset;
        right: 0;
        min-width: max(320px, vw(320px)) !important;
    }
}