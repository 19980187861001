// * INPUTS
.input {
  &__default {
    @include inputStracture(1px, solid, 0, 20px max(20px, vw(28px)));
    @include inputStyle(
      transparent,
      $gray,
      $black-light,
      $gray-dark,
      $error-color: $error-color
    );
    @extend .text__normal;

    &--bottom-line {
      border-top: unset;
      border-left: unset;
      border-right: unset;
      border-radius: unset;

      &:hover {
        border-color: inherit;
      }
    }

    &::-webkit-input-placeholder {
      font-style: italic;
    }

    &.checkbox-input {
      display: inline-flex;
    }

    &.checkbox-input,
    &.checkbox-wrap {
      cursor: pointer;
      -moz-appearance: none;
      appearance: none;
      -webkit-appearance: none;
      margin: 0 5px -3px 0;
      width: 17px;
      height: 17px !important;
      border: 1px solid $black-light;
      position: relative;
      padding: 4px;
      border-radius: 0;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: scale(0) translate(-50%, -50%);
        background-color: $white;
        transform-origin: bottom left;
        -webkit-clip-path: polygon(
          14% 44%,
          0 65%,
          50% 100%,
          100% 16%,
          80% 0%,
          43% 62%
        );
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked {
        background-color: $red;
        border-color: $red;

        &:before {
          transform: scale(1) translate(-50%, -50%);
        }
      }

      &.error-checkbox {
        border-color: var(--red);

        &:checked {
          border-color: var(--gray-dark);
        }
      }

      &:disabled {
        border-color: $gray-dark;
        cursor: not-allowed;
      }
    }
  }
}

input {
  &:not([type="radio"]):not([type="submit"]) {
    @extend .input__default;
  }
}

/* RADIO STRACTURE */
$radio-size: 18px;
$radio-inner-size: 12px;
$radio-border-color: $black-light;
$radio-border-width: 1px;
$radio-border-radius: 50%;
$radio-background: transparent;
$radio-check-color: $red;

/* RADIO */
.radio-input {
  &:not(#guest):not(#register) {
    position: relative !important;
    display: inline-grid;
    place-content: center;
    cursor: pointer;
    width: $radio-size;
    height: $radio-size;
    border: $radio-border-width solid $radio-border-color;
    -webkit-appearance: none;
    appearance: none;
    background-color: $radio-background;
    margin: 0;
    margin-right: 9px;
    border-radius: $radio-border-radius;

    &:before {
      content: "";
      width: $radio-inner-size;
      height: $radio-inner-size;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $radio-check-color;
    }

    &:checked {
      &:before {
        transform: scale(1);
      }
    }

    & + span {
      @extend .text__small;
    }
  }
}

.checkbox-label {
  display: inline-flex;
  align-content: flex-start;
  align-items: flex-start;

  span {
    flex: 1;
    font-size: 1rem;

    a {
      font-weight: bold;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }
}

.eye-pass {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-36px, -50%);
  cursor: pointer;
  color: $red;
}

.required-label {
  label {
    &:after {
      content: " *";
      color: $red;
    }
  }
}

.account-form--field,
.field__wrap--input {
  &.required {
    @extend .required-label;
  }
}

.required {
  .account-form--field {
    @extend .required-label;
  }
}

input[type="radio"] {
  @extend .radio-input;
}

input[type="checkbox"] {
  @extend .checkbox-input;

  & + span,
  & + label {
    @include font(
      $font-default,
      #{max(14px, vw(14px))},
      $weight-normal,
      italic,
      1
    );
    text-transform: none;
    display: inline;
    line-height: 1.5;
    .terms-link-checkout {
      color: #ff0035;
    }
  }
}
